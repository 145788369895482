// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const ro = {
  demo: {
    title: `Romana`,
    introduction: `Aici se seteaza in limba romana. Aici se seteaza in limba romana. Aici se seteaza in limba romana. Aici se seteaza in limba romana. Aici se seteaza in limba romana. Aici se seteaza in limba romana. Aici se seteaza in limba romana. Aici se seteaza in limba romana. Aici se seteaza in limba romana. Aici se seteaza in limba romana. Aici se seteaza in limba romana. Aici se seteaza in limba romana. Aici se seteaza in limba romana. Aici se seteaza in limba romana. Aici se seteaza in limba romana. Aici se seteaza in limba romana. `,
  },
  docs: {
    hi: `Salut!`,
    description: `Ai nevoie de ajutor? \n Arunca o privire aici!`,
    documentation: `Documentatie`,
  },
  app: `app`,
  address: 'Adresa',
  user: `utilizator`,
  list: `lista`,
  edit: `editeaza`,
  shop: `magazin`,
  blog: `blog`,
  post: `post`,
  mail: `mail`,
  chat: `chat`,
  cards: `cards`,
  posts: `postari`,
  create: `Creeaza`,
  kanban: `kanban`,
  general: `general`,
  banking: `banca`,
  booking: `rezerva`,
  profile: `profil`,
  account: `cont`,
  product: `produs`,
  invoice: `factura`,
  details: `detalii`,
  checkout: `priveste`,
  calendar: `calendar`,
  analytics: `analize`,
  ecommerce: `e-commerce`,
  management: `management`,
  menu_level: `menu level`,
  menu_level_2a: `menu level 2a`,
  menu_level_2b: `menu level 2b`,
  menu_level_3a: `menu level 3a`,
  menu_level_3b: `menu level 3b`,
  menu_level_4a: `menu level 4a`,
  menu_level_4b: `menu level 4b`,
  item_disabled: `item dezactivat`,
  item_label: `item label`,
  item_caption: `item caption`,
  item_external_link: `item external link`,
  description: `descriere`,
  other_cases: `alte cazuri`,
  item_by_roles: `Item pe roluri`,
  only_admin_can_see_this_item: `Doar adminul poate vedea acest item`,
  history: 'Istoric',
  save: 'Salveaza',
  number: 'Numar',

  // !------------------------------- TICKETS --------------------------- !//

  // ?--------- TICKET FORM ---------- ?//
  newTicketForm: {
    createTicket: 'Creare ticket',
    title: 'Titlu',
    description: 'Descriere',
    attachments: 'Atasamente',
    department: 'Departament',
    remarks: 'Observatii',
    status: {
      opened: 'Deschis',
      pending: 'In asteptare',
      closed: 'Inchis',
      new: 'Nou'
    },
    category: 'Categorie',
    tags: 'Taguri',
    priority: {
      name: 'Prioritate',
      low: 'Mica',
      normal: 'Normala',
      urgent: 'Urgenta',
    },
    contactSource: {
      name: 'Sursa contact',
      telephone: 'Telefonic centrala',
      mobilePhone: 'Telefon mobil',
    },
    client: {
      phoneNumber: 'Numar telefon',
      email: 'Email',
      recommendSource: {
        name: 'Sursa recomandare',
        press: 'Presa',
        streetPanotage: 'Panotaj stradal',
        online: 'Google',
        partner: 'Partener',
        source: 'Alta sursa',
      },
      requestType: 'Tip solicitare',
      connectionRequest: {
        name: 'Cerere racordare',
        true: 'Da',
        false: 'Nu',
        noCase: 'Nu este cazul',
      },
    },
    responseDate: 'Data raspuns',
  },

  // ?--------------ADDRESS FORM--------------? //
  addressForm: {
    firstName: 'Prenume',
    lastName: 'Nume',
    legalQuality: {
      name: 'Calitate',
      naturalPerson: 'Persoana fizica',
      legalPerson: 'Persoana juridica',
    },
    personType: {
      name: 'Tip',
      beneficiary: 'Beneficiar',
      authorized: 'Imputernicit'
    },
    address:{
      street: 'Strada',
      number: 'Numar',
      building: 'Bloc',
      stair: 'Scara',
      floor: 'Etaj',
      apartment: 'Apartament',
      city: 'Localitate',
      county: 'Sector/Judet'
    }
  },
};

export default ro;
