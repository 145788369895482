import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  TextField,
  Button,
  Box,
  IconButton,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  Input,
  Typography,
  FormControlLabel,
  Checkbox,
  FormGroup,
} from '@mui/material';
import axios from 'axios';
import Cookies from 'js-cookie';
import { useDispatch, useSelector } from 'react-redux';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { changeSettings, changeUnusable } from '../../../../../redux/slices/virtualPhone';

import { getAuthenticatedUserFromCookies } from '../../../../../utils/cookies';
import { BASE_URL } from '../../../../../config-global';

const SettingsView = () => {
  let phoneSettings;

  if (Cookies.get('virtual_phone_settings') !== undefined) {
    phoneSettings = JSON.parse(Cookies.get('virtual_phone_settings'));
  }

  const dispatch = useDispatch();
  const [host, setHost] = useState('');
  const [extension, setExtension] = useState('');
  const [password, setPassword] = useState('');
  const [isPasswordVisible, setPasswordVisible] = useState(false);

  // const [iphoneLook, setIphoneLook] = useState(phoneSettings.iphoneLook || false);
  const { callState } = useSelector((state) => state.virtualPhone);

  const handleSaveSettings = async () => {
    const cookies = getAuthenticatedUserFromCookies();
    const token = cookies.accessToken;

    axios
      .post(
        `${BASE_URL}/callcenter/virtualphone/settings`,
        {
          callcenterHost: host,
          agentExtension: extension,
          agentExtensionPassword: password,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((response) => {
        Cookies.set(
          'authenticatedUser',
          JSON.stringify({
            ...cookies,
            agentData: {
              callcenterHost: host,
              extension,
              agentExtensionPassword: password,
            },
          })
        );
        dispatch(changeUnusable(false));
      })
      .catch((e) => console.log(e));
  };

  const handleIphoneLook = (e) => {
    dispatch(changeSettings({ iphoneLook: e.target.checked }));
    Cookies.set('virtual_phone_settings', JSON.stringify({ iphoneLook: e.target.checked }));
  };

  useEffect(() => {
    const cookies = getAuthenticatedUserFromCookies();
    if (cookies.agentData !== null && cookies.agentData !== undefined) {
      setHost(cookies.agentData.callcenterHost);
      setExtension(cookies.agentData.extension);
      setPassword(cookies.agentData.agentExtensionPassword);
    }
  }, []);

  return (
    <Box sx={{ p: '20px', display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
      <Typography sx={{ fontWeight: 'bold' }}>Setări conexiune</Typography>
      <TextField
        label="Websocket"
        variant="standard"
        value={host}
        onChange={(e) => setHost(e.target.value)}
        fullWidth
        margin="normal"
        required
      />

      <TextField
        label="Extensie"
        variant="standard"
        value={extension}
        onChange={(e) => setExtension(e.target.value)}
        fullWidth
        margin="normal"
        required
      />

      <FormControl sx={{ width: '100%' }} variant="standard">
        <InputLabel htmlFor="standard-adornment-password">Parola</InputLabel>
        <Input
          id="standard-adornment-password"
          type={isPasswordVisible ? 'text' : 'password'}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setPasswordVisible((state) => !state)}
                // onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {isPasswordVisible ? <VisibilityOffIcon /> : <VisibilityIcon />}
              </IconButton>
            </InputAdornment>
          }
          label="Password"
          variant="standard"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </FormControl>
      {/* <TextField
          label="Parolă"
          variant="standard"
          type={isPasswordVisible ? 'text' : 'password'}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          fullWidth
          margin="normal"
          required
        />
        <IconButton>{isPasswordVisible ? <VisibilityIcon /> : <VisibilityOffIcon />}</IconButton> */}
      {/* <Typography sx={{ fontWeight: 'bold', mt: 4 }}>Setări telefon</Typography>

      <FormControlLabel
        control={
          <Checkbox
            sx={{
              color: '#2b93f2',
              '&.Mui-checked': {
                color: '#2b93f2',
              },
            }}
            checked={iphoneLook}
            onChange={handleIphoneLook}
          />
        }
        label="Aspect iPhone"
      /> */}

      <Button
        disabled={
          host.length <= 5 ||
          extension.length <= 3 ||
          password.length <= 5 ||
          callState === 'incall'
        }
        variant="contained"
        sx={{ mt: 10, bgcolor: '#7aeee2', color: 'black' }}
        onClick={handleSaveSettings}
      >
        Salvează
      </Button>
    </Box>
  );
};
SettingsView.propTypes = {};

export default SettingsView;
