import * as React from 'react';

function CreateIllustration() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
      <defs>
        <clipPath id="a">
          <path
            d="M214.76 198.9c-10.38 0-20.07-2.29-27.27-6.45-6.37-3.68-9.87-8.27-9.87-12.94S161.94 61.34 161.94 61.34l37.59-30.91 74.65 27.1s-21.72 117.32-21.72 122-3.93 9.51-10.29 13.18c-7.17 4.14-17.03 6.19-27.41 6.19Z"
            style={{
              fill: 'none',
            }}
          />
        </clipPath>
      </defs>
      <path
        d="M90.88 433.26c87.88 45.91 230.36 45.91 318.25 0s87.87-120.34 0-166.26-230.37-45.9-318.26 0S3 387.35 90.88 433.26Z"
        style={{
          fill: '#f5f5f5',
        }}
      />
      <ellipse
        cx={419.15}
        cy={344.97}
        rx={40.39}
        ry={19.25}
        style={{
          fill: '#e0e0e0',
        }}
      />
      <ellipse
        cx={94.24}
        cy={366.16}
        rx={40.39}
        ry={19.25}
        style={{
          fill: '#e0e0e0',
        }}
      />
      <path
        d="m244.47 442-102.26-59a2.65 2.65 0 0 1-1.2-2.07v-1.55a2.65 2.65 0 0 1 1.2-2.07l124.68-72a2.65 2.65 0 0 1 2.39 0l102.26 59a2.64 2.64 0 0 1 1.19 2.07v1.56a2.64 2.64 0 0 1-1.19 2.07L246.86 442a2.65 2.65 0 0 1-2.39 0Z"
        style={{
          fill: '#ebebeb',
        }}
      />
      <path
        d="m371.54 365.67-124.69 72a2.59 2.59 0 0 1-2.39 0l-102.25-59a.73.73 0 0 1 0-1.38l124.68-72a2.65 2.65 0 0 1 2.39 0l102.26 59a.73.73 0 0 1 0 1.38Z"
        style={{
          fill: '#fafafa',
        }}
      />
      <path
        d="m371.54 365.67-124.69 72a2.59 2.59 0 0 1-2.39 0l-102.25-59a.73.73 0 0 1 0-1.38l124.68-72a2.65 2.65 0 0 1 2.39 0l102.26 59a.73.73 0 0 1 0 1.38Z"
        style={{
          fill: '#fafafa',
        }}
      />
      <path
        d="M245.66 438.35v3.91a2.44 2.44 0 0 1-1.19-.29l-102.26-59a2.63 2.63 0 0 1-1.2-2.07v-1.55a2.68 2.68 0 0 1 1-1.92c-.43.38-.36.9.22 1.23l102.26 59a1.44 1.44 0 0 0 .31.13Z"
        style={{
          fill: '#e0e0e0',
        }}
      />
      <path
        d="M184 372.65a.48.48 0 0 1 0 .15l-.11.15-.57.23-13.3.68a1.44 1.44 0 0 0-1 .68c-.21.36-.1.65.25.67l12.54.64.29.23a.47.47 0 0 1-.06.15.72.72 0 0 1-.57.34l-12.65-.65c-.71 0-.94-.64-.52-1.38a2.94 2.94 0 0 1 2.12-1.39l13.41-.69c.11 0 .17.07.17.19ZM301.18 364.59a.42.42 0 0 1-.08.22.74.74 0 0 1-.58.31s-3.32-.39-9.39-.91c-.18 0-.23-.19-.1-.39a.72.72 0 0 1 .57-.32c6.08.52 9.38.91 9.41.91a.18.18 0 0 1 .17.18ZM290.14 383.76a.22.22 0 0 1 0 .13.74.74 0 0 1-.54.4s-3.79.4-10.47.94c-.21 0-.3-.13-.2-.32a.74.74 0 0 1 .55-.39c6.67-.54 10.41-.94 10.45-.94s.21.06.21.18Z"
        style={{
          fill: '#455a64',
        }}
      />
      <path
        d="M276 362.53a.41.41 0 0 1-.06.2.77.77 0 0 1-.58.33c-5.55-.34-11.36-.63-17.27-.88-26.95-1.09-35.92-.1-44.1 1.21-8.88 1.42-31.47 9.64-33.4 11.13.22 1.48 13.37 9.66 20.62 11.05 6.69 1.29 14.53 2.26 42.79 1.08 6.18-.26 12.34-.57 18.32-.93.2 0 .28.14.18.33a.74.74 0 0 1-.55.38c-6 .36-12.15.67-18.33.93-28.34 1.19-36.21.21-42.94-1.08-6.5-1.26-21.89-9.93-20.84-11.76s26.47-10.56 34.43-11.84c8.23-1.31 17.24-2.31 44.26-1.21 5.91.24 11.73.54 17.29.88a.16.16 0 0 1 .18.18Z"
        style={{
          fill: '#455a64',
        }}
      />
      <path
        d="M225.45 368.51c-.67-1.55-2.27-2.41-4.48-2.41a14.4 14.4 0 0 0-7.34 2.46 19.59 19.59 0 0 0-3.38 2.71 16.31 16.31 0 0 0-2.48 3.2 7.93 7.93 0 0 0-1.24 4.86c.31 2.2 2.11 3.51 4.8 3.5a14.31 14.31 0 0 0 7.34-2.46 18 18 0 0 0 5.86-5.91 8.65 8.65 0 0 0 1.28-4 4.69 4.69 0 0 0-.36-1.95Zm-1.64 5.95a16.36 16.36 0 0 1-5.36 5.41 13.19 13.19 0 0 1-6.7 2.25c-2.48 0-4.12-1.2-4.39-3.2a3.94 3.94 0 0 1-.05-.77 8 8 0 0 1 1.18-3.68 16.52 16.52 0 0 1 5.35-5.41 13.14 13.14 0 0 1 6.71-2.24c2 0 3.49.78 4.11 2.2s.34 3.37-.85 5.44Z"
        style={{
          fill: '#455a64',
        }}
      />
      <path
        d="M224.66 369c-.62-1.42-2.07-2.2-4.11-2.2a13.14 13.14 0 0 0-6.71 2.24 16.52 16.52 0 0 0-5.35 5.41 8 8 0 0 0-1.18 3.68 3.94 3.94 0 0 0 .05.77c.27 2 1.91 3.2 4.39 3.2a13.19 13.19 0 0 0 6.7-2.25 16.36 16.36 0 0 0 5.36-5.41c1.19-2.05 1.48-3.98.85-5.44Zm-2.66 5.46c-1.87 3.23-6 5.87-9.25 5.87-3.21 0-4.32-2.64-2.47-5.86s6-5.86 9.24-5.87c2.22 0 3.44 1.24 3.39 3a6.21 6.21 0 0 1-.91 2.86Z"
        style={{
          fill: '#00ab55',
        }}
      />
      <path
        d="M201.46 366.16a.35.35 0 0 0-.27 0c-6.34 2-20.53 6.9-21.38 8.37s7.71 6.33 11.75 8.32a.42.42 0 0 0 .29 0 .9.9 0 0 0 .31-.21c4.94-5.42 8.1-10.89 9.37-16.25v-.06a.15.15 0 0 0-.07-.17Z"
        style={{
          fill: '#455a64',
        }}
      />
      <path
        d="M200.63 367c-1.26 5-4.18 10-8.7 15.08-6.13-3.05-11.24-6.59-11.37-7.52 1.2-.97 10.44-4.56 20.07-7.56Z"
        style={{
          fill: '#00ab55',
        }}
      />
      <path
        d="M301.3 365.55a12.4 12.4 0 0 1-1.11 4.24.74.74 0 0 1-.54.41c-.21 0-.32-.1-.24-.29 1.32-3.18 1.08-4.49 1-4.8-.52-.06-2.71-.31-6.39-.64a20.23 20.23 0 0 1-2.18 6.47.76.76 0 0 1-.55.39c-.21 0-.29-.13-.2-.32 1.74-3.59 2.11-5.69 2.14-6.78a.56.56 0 0 1 .23-.37.64.64 0 0 1 .42-.16c4.62.41 7.11.71 7.14.71a.2.2 0 0 1 .15.09 2.32 2.32 0 0 1 .13 1.05ZM295 378.71a.5.5 0 0 1-.1.23c-2.92 4-5 5.21-5.1 5.26a.63.63 0 0 1-.25.09s-2.86.31-8 .74c-.14 0-.23 0-.24-.17a.49.49 0 0 1 .2-.36 34.44 34.44 0 0 0 5.65-6.76.71.71 0 0 1 .58-.33c.18 0 .23.19.1.39a38.28 38.28 0 0 1-5.26 6.46c4.06-.35 6.54-.61 7.13-.67a20.51 20.51 0 0 0 4.52-4.77.71.71 0 0 1 .58-.29.16.16 0 0 1 .19.18ZM307.72 392.62l-25.51 1.72-2.54-9.7a.15.15 0 0 0-.13-.11c-4.86-1.32-10.71-.91-17.38 1.21a.9.9 0 0 0-.37.25.38.38 0 0 0-.09.33l5.82 15.32a.17.17 0 0 0 .18.11l10 .17h.13l29.65-8.58a.75.75 0 0 0 .47-.41.54.54 0 0 0 0-.1c.05-.14-.08-.22-.23-.21ZM278 401.2l-9.76-.16-5.63-14.83c6.2-1.88 11.66-2.22 16.23-1l2.55 9.74c0 .08.11.13.23.12l22.91-1.54Z"
        style={{
          fill: '#455a64',
        }}
      />
      <path
        d="M304.57 393.52 278 401.2l-9.76-.16-5.63-14.83c6.2-1.88 11.66-2.22 16.23-1l2.55 9.74c0 .08.11.13.23.12Z"
        style={{
          fill: '#00ab55',
        }}
      />
      <path
        d="m329.35 355.31-19.81-8.51h-.11l-10.19.2a.58.58 0 0 0-.31.11l-23.49 15.36a.72.72 0 0 0-.28.33.2.2 0 0 0 .08.26c4.24 2.1 9.62 2.49 16 1.16a.85.85 0 0 0 .26-.12l13.72-9.72 23.59 1.62a.72.72 0 0 0 .54-.29.4.4 0 0 0 .1-.24.16.16 0 0 0-.1-.16Zm-23.84-1.67a.58.58 0 0 0-.37.13l-13.79 9.76c-6 1.22-11 .9-15.08-1L299 347.69l10-.19 17.72 7.61Z"
        style={{
          fill: '#455a64',
        }}
      />
      <path
        d="m326.69 355.11-21.18-1.47a.58.58 0 0 0-.37.13l-13.79 9.76c-6 1.22-11 .9-15.08-1L299 347.69l10-.19Z"
        style={{
          fill: '#00ab55',
        }}
      />
      <path
        d="M328.39 373.86 303.53 369h-.11l-37.05 5a.8.8 0 0 0-.51.35c-.11.18-.06.33.1.36l31.31 5h.12l30.6-5.14a.76.76 0 0 0 .5-.36.41.41 0 0 0 .05-.17.17.17 0 0 0-.15-.18ZM297.73 379l-29-4.63 34.36-4.61 23.06 4.48Z"
        style={{
          fill: '#455a64',
        }}
      />
      <path
        d="m326.11 374.23-28.38 4.76-29.04-4.63 34.36-4.61 23.06 4.48z"
        style={{
          fill: '#00ab55',
        }}
      />
      <path
        d="m296.22 395.93-2.87.84-2.9.85-3.24.94a8.09 8.09 0 0 0-5.21 4.79c-.56 2 .8 3.17 3.11 2.68l3.06-.65h.09l2.83-.6 2.78-.59c1.51-.38 2.68-2.74 3.22-4.64a8.89 8.89 0 0 0 .35-2.06c.05-1.07-.27-1.8-1.22-1.56Zm.08 3.8c-.53 1.9-1.53 3.58-2.2 3.75l-2 .42-.95.2-2 .42-.91.2-2.95.62c-1.62.34-2.67-.33-2.64-1.56a2.61 2.61 0 0 1 .11-.64 6.57 6.57 0 0 1 4.25-3.9l3-.89.93-.27 2-.59 1-.29 2-.59c.77-.16.9 1.24.36 3.12Z"
        style={{
          fill: '#455a64',
        }}
      />
      <path
        d="M294 397.2a.81.81 0 0 0-.45-.44.42.42 0 0 0-.22 0l-2.9.85a.78.78 0 0 0-.42.31c-.1.14-.1.28 0 .34a.27.27 0 0 1 .07.08c.34.41.3 1.57-.1 3a8.56 8.56 0 0 1-1.7 3.39l-.13.13a.51.51 0 0 0-.2.39.19.19 0 0 0 .21.14h.09l2.83-.6a.65.65 0 0 0 .22-.11 3.66 3.66 0 0 0 .78-.75 10.2 10.2 0 0 0 1.71-3.54 8.16 8.16 0 0 0 .35-2.05 2.59 2.59 0 0 0-.14-1.14Zm-1 3.36a8.09 8.09 0 0 1-1.86 3.54l-2 .42a10.7 10.7 0 0 0 1.59-3.39 5.91 5.91 0 0 0 .23-3.05l2-.59c.49.28.49 1.51.04 3.07Z"
        style={{
          fill: '#455a64',
        }}
      />
      <path
        d="M293 400.56a8.09 8.09 0 0 1-1.86 3.54l-2 .42a10.7 10.7 0 0 0 1.59-3.39 5.91 5.91 0 0 0 .23-3.05l2-.59c.49.28.49 1.51.04 3.07Z"
        style={{
          fill: '#00ab55',
        }}
      />
      <path
        d="m328.25 344.47-2.12-.58-2.13-.59-2.39-.66c-1.74-.48-4.47.71-6.2 2.7s-1.87 4.09-.3 4.78l2.15.93 1.93.84 1.91.83c.35.12 1.05 0 2.39-1a21.34 21.34 0 0 0 2.66-2.6 17.41 17.41 0 0 0 2.05-2.82 3.1 3.1 0 0 0 .43-1.24.53.53 0 0 0-.38-.59Zm-.79 1.85a15.9 15.9 0 0 1-1.89 2.6 19.3 19.3 0 0 1-2.45 2.39c-.68.54-1.2.8-1.42.73l-1.34-.58-.66-.29-1.34-.58-.61-.27-2-.88c-1.28-.56-1.18-2.27.24-3.9s3.64-2.6 5.07-2.2l2.25.62.67.18 1.51.41.72.2 1.49.41c.19.08.11.5-.24 1.16Z"
        style={{
          fill: '#455a64',
        }}
      />
      <path
        d="M326.25 344a.15.15 0 0 0-.12-.11l-2.13-.59a.66.66 0 0 0-.69.52s0 .08 0 .13a10.58 10.58 0 0 1-2.21 3.37 14.31 14.31 0 0 1-3.33 3l-.17.08a.77.77 0 0 0-.39.34c-.07.14-.05.26.06.31l1.93.84a.33.33 0 0 0 .22 0 3.68 3.68 0 0 0 1-.44 18 18 0 0 0 3.45-3.17 13 13 0 0 0 2.37-3.54 1.76 1.76 0 0 0 .1-.51.61.61 0 0 0-.09-.23Zm-3 4.09a12.44 12.44 0 0 1-3.54 3.08l-1.34-.58a19.14 19.14 0 0 0 3.28-3.06 14 14 0 0 0 2.31-3.39l1.51.41c.03.53-.9 2-2.23 3.54Z"
        style={{
          fill: '#455a64',
        }}
      />
      <path
        d="M325.46 344.55c0 .53-.89 2-2.22 3.54a12.44 12.44 0 0 1-3.54 3.08l-1.34-.58a19.14 19.14 0 0 0 3.28-3.06 14 14 0 0 0 2.31-3.39Z"
        style={{
          fill: '#00ab55',
        }}
      />
      <path
        d="M219.52 368.6c-3.23 0-7.38 2.64-9.24 5.87s-.74 5.86 2.47 5.86c3.24 0 7.38-2.64 9.25-5.87a6.21 6.21 0 0 0 .89-2.81c.11-1.81-1.15-3.05-3.37-3.05Zm1.78 5.86c-1.64 2.84-5.27 5.15-8.12 5.15s-3.82-2.3-2.18-5.14 5.27-5.14 8.11-5.15 3.82 2.3 2.19 5.14Z"
        style={{
          fill: '#455a64',
        }}
      />
      <path
        d="M398.88 181c-4.17 5.55-12.59 15.5-16.77 20.75a15.28 15.28 0 0 0-3.39 9.69c0 2.61.75 4.79 3.3 3.68 1.85-.81 3-1.76 5.72-4.42 2.42-2.39 16.42-20.09 16.42-20.09s1.15-9.92 4.77-11-1.3-4.08-1.3-4.08c-1.53.17-4.54-.13-8.75 5.47Z"
        style={{
          fill: '#9a4a4d',
        }}
      />
      <path
        d="m347.66 313.71-26.14 29.84a1.21 1.21 0 0 1-2.11-.88l2.6-39.81Z"
        style={{
          fill: '#ffa8a7',
        }}
      />
      <path
        d="M319.41 342.67a1.21 1.21 0 0 0 2.11.88l5.1-5.83a6.67 6.67 0 0 1-6.28-3l-.38-.57Z"
        style={{
          fill: '#263238',
        }}
      />
      <path
        d="m386 148.21-1.4 3.4-1.71 4.09L322 302.85a17.73 17.73 0 0 0 8.76 9.56c6 3.2 13.19 3.78 16.87 1.29L408 167.63l3.24-7.83c-2.34 4.95-27.59-6.59-25.24-11.59Z"
        style={{
          fill: '#00ab55',
        }}
      />
      <path
        d="m380.59 161.11-3.12 7.59c-1 2.17 3.12 5.51 8.33 8.22 6.9 3.63 15.74 6.15 17 3.28l2.31-5.59.79-2c-1.3 2.87-10.14.36-17.05-3.27-5-2.61-9-5.81-8.38-8a.51.51 0 0 1 .12-.23Z"
        style={{
          fill: '#ebebeb',
        }}
      />
      <path
        d="M379.83 163c-2.28 5 23.07 16.53 25.35 11.5l3.15-7.59c-2.28 5-27.63-6.46-25.35-11.49Z"
        style={{
          fill: '#00ab55',
        }}
      />
      <path
        d="m386 148.21-3.11 7.49c-2.3 4.92 22.11 16.3 25.11 11.93a1.38 1.38 0 0 0 .16-.27l3.08-7.56c-2.34 4.95-27.59-6.59-25.24-11.59Z"
        style={{
          fill: '#ebebeb',
        }}
      />
      <path
        d="m408 167.63 3.24-7.83c-1.08 2.24-6.82 1.1-12.58-1.27l-63.05 155.84-14.09 29.18 26.14-29.84Z"
        style={{
          opacity: 0.1,
        }}
      />
      <path
        d="M399.14 116.45 386 148.21c-1 2.14 3 5.47 8.2 8.19 6.89 3.65 15.75 6.24 17.11 3.4L424 129.22c3.54-6.88-8.67-15.54-17.44-16-3.32-.22-6.1.66-7.42 3.23Z"
        style={{
          fill: '#ffa8a7',
        }}
      />
      <path
        d="m411.28 126.93-12.58 31.59c5.13 2.11 10.22 3.24 12 1.88a3.05 3.05 0 0 0 .36-.35c.06-.09.13-.16.18-.25l13.05-31.49c-1.62 3.78-13.01-1.38-13.01-1.38Z"
        style={{
          fill: '#f28f8f',
        }}
      />
      <path
        d="m450.69 336.25-7.21 2.15-3.12-10.51 7.37-2.52 2.96 10.88z"
        style={{
          fill: '#b16668',
        }}
      />
      <path
        d="m442.42 334.84-.31-1c-.6.5-.78 6.88-2.47 10.56-1.84 4-3.79 6.88-4.61 8.69-1.1 2.42 3.18 3.7 7.91 1.37 2.34-1.16 6.1-4.18 6.7-6.12s.39-4.45.91-5.9 2.14-3.35 2.21-4.86a9.21 9.21 0 0 0-1.54-3.67c-.71-1.17-1.49-2.47-1.89-2.22 0 .16.23.79.23.79a7.37 7.37 0 0 1-3 2.94c-1.13.58-3.76.67-4.14-.58Z"
        style={{
          fill: '#263238',
        }}
      />
      <path
        d="M432.82 216.4c4.35 5.58 5.13 11.34 4.18 27.65s-1.24 30-1 30.87 5.36 7.47 7 13.44 6.13 39.79 6.13 39.79a10.05 10.05 0 0 1-8.2 3.72s-18.15-49.42-19.37-53.1-9-42.32-9-42.32l1.18-21.82Z"
        style={{
          fill: '#37474f',
        }}
      />
      <path
        d="M413.9 168.35h6a14.24 14.24 0 0 0 14.24-14.23 17.25 17.25 0 0 0-17.25-17.25 17.25 17.25 0 0 0-17.25 17.25 14.23 14.23 0 0 0 14.26 14.23Z"
        style={{
          fill: '#263238',
        }}
      />
      <path
        d="M397.28 322.37h7.52v10.96h-7.52z"
        style={{
          fill: '#b16668',
        }}
        transform="rotate(.76 398.721 326.062)"
      />
      <path
        d="M399.6 235.16c.67-7.53 2.28-20.58 2.28-20.58l19.26-1.13c3.12 4.86 1.76 13 .91 20.26l-11 43.12c.22 2.07 2.12 6.45 0 17.55-2.37 12.4-5.89 30.17-5.89 30.17s-5.49 2.76-8.2-.25c0 0-1.45-40.3-.43-51 1.09-11.64 2.62-32.98 3.07-38.14Z"
        style={{
          fill: '#37474f',
        }}
      />
      <path
        d="M397.26 329.57v-1.08c-.72.3-2.8 6.34-5.5 9.35-2.95 3.29-5.67 5.44-7 6.92-1.76 2 1.94 4.48 7.14 3.66 2.59-.4 7.08-2.18 8.22-3.84s1.7-4.14 2.63-5.36 3-2.57 3.55-4a9.15 9.15 0 0 0-.38-4c-.33-1.33-.69-2.8-1.14-2.68v.82a7.46 7.46 0 0 1-3.76 1.91c-1.22.25-3.77-.39-3.76-1.7Z"
        style={{
          fill: '#263238',
        }}
      />
      <path
        d="M404.17 199.49c-.48 4.4-3.32 23.51-3.32 23.51 14.52 7.74 32.53 2.61 35.28-.58-2.49-8.22-5.18-12.9-7.23-15.08l3.1-12.08s-7-7.46-.77-19.21l-7.7-.63-11.3-.33c-2.7.15-4.63.41-4.63.41a70.6 70.6 0 0 0-5.29 10.75c-1.12 2.92-2.31 7.19 1.86 13.24Z"
        style={{
          fill: '#f0f0f0',
        }}
      />
      <path
        d="M405.82 144.38c-1.82 1.08-4.07 5.66-3.85 15.27.19 8.14 2.83 10.15 4.15 10.73s3.88.23 6.37-.19v4.89s-3.44 4-3.22 6.23 5 2.77 8.17.58a26 26 0 0 0 5.92-6.46l-.06-11.23s1.47 1.42 3.95-.75c2-1.79 2.73-4.8 1.14-6.42a3.57 3.57 0 0 0-5.68 1.13s-4 .14-9.23-2.76a14.82 14.82 0 0 1-7.66-11.02Z"
        style={{
          fill: '#b16668',
        }}
      />
      <path
        d="M411.06 157.6a1 1 0 0 0 1 1.11 1.06 1.06 0 1 0-1-1.11Z"
        style={{
          fill: '#263238',
        }}
      />
      <path
        d="m410.79 164.5-2.38.76a1.24 1.24 0 0 0 1.55.86 1.33 1.33 0 0 0 .83-1.62Z"
        style={{
          fill: '#9a4a4d',
        }}
      />
      <path
        d="m402.67 154.46 2.07-1.44a1.21 1.21 0 0 0-1.72-.35 1.33 1.33 0 0 0-.35 1.79ZM403.14 156.86a1 1 0 1 0 1.08-1 1.05 1.05 0 0 0-1.08 1Z"
        style={{
          fill: '#263238',
        }}
      />
      <path
        d="m407.97 156.28-.16 6.16-3.24-1 3.4-5.16zM412.49 170.19c2.62-.34 8-1.89 8.84-4a5.52 5.52 0 0 1-1.89 2.72c-1.61 1.38-6.95 2.93-6.95 2.93Z"
        style={{
          fill: '#9a4a4d',
        }}
      />
      <path
        d="m434 156.8 3.67-2.69a2.21 2.21 0 0 0-3.14-.57 2.4 2.4 0 0 0-.53 3.26ZM416 253.46l-3.15-15.83a33.92 33.92 0 0 1-7.86-4 23.86 23.86 0 0 0 5.5 5.07Z"
        style={{
          fill: '#263238',
        }}
      />
      <path
        d="M432 168.42c-3.34-1.69-2.88-4.85-1.19-8.2s3.95-5.6 7.3-3.92a6.79 6.79 0 0 1-6.11 12.12Z"
        style={{
          fill: '#263238',
        }}
      />
      <path
        d="M378.12 218.44c1.68.44 3.31.3 5.63 1.1a10.39 10.39 0 0 0 4.9.83c2.18-.14 7.87-2.83 12.92-5.9A57.67 57.67 0 0 0 416 201.1c4.57-6.47 9.63-16.68 12.05-20.58s3.25-4.47 3.25-4.47c3.16.13 6.3 3 4.2 10.74-1.74 6.38-6.41 14.65-12.16 21.61a61.54 61.54 0 0 1-15.35 13c-5.34 3.26-10 4.88-16 6.87-1.62.54-2.68 1.34-5.64 2.74a13.9 13.9 0 0 1-3.8 1.39 17.52 17.52 0 0 1-5 0 11.82 11.82 0 0 1-5.76-2.47 7.49 7.49 0 0 1-2.13-2.71 5 5 0 0 1-.26-3.41 1.36 1.36 0 0 1 .34-.61 1.62 1.62 0 0 1 1.21-.28 71.42 71.42 0 0 0 7.29.35c2-.14 3.39-.52 3.1-.8s-1.45-.12-3.05-.81-2.65-2.21-2.21-3 .36-.66 2.04-.22ZM367.08 193.87a13.19 13.19 0 0 1 7.36-.92c2 .33 2 7-4.43 9.13-4.19 1.37-6.71.76-8.06 4.21 0 0-2.72-.36-2.1-4.21a21.5 21.5 0 0 1 5.15-10.69c1.84-2.18 2-4 2.87-3.4s.55 2.73.55 2.73Z"
        style={{
          fill: '#b16668',
        }}
      />
      <path
        d="M107.47 198.74c4.46 5.33 12.59 15.5 16.77 20.75a15.22 15.22 0 0 1 3.39 9.69c0 2.61-.74 4.79-3.3 3.68-1.84-.81-3-1.76-5.71-4.42-2.42-2.39-11-12.7-11-12.7s-6.58-17.31-10.2-18.41a16.78 16.78 0 0 0-6.13-.82c4.37-1.51 10.52-4.51 16.18 2.23Z"
        style={{
          fill: '#f28f8f',
        }}
      />
      <path
        d="M89.13 193.05c4.47-3.36 12-2.49 16.22 2.57 3.07 3.71 10.36 12.76 10.36 12.76a15.54 15.54 0 0 1-9.39 8.42Z"
        style={{
          fill: '#e0e0e0',
        }}
      />
      <path
        d="M174.68 312.45s-12.56-45.53-30.27-88.45c-17.57-42.51-44.66-85.64-49.19-92.15l-.06-.08-.07-.1a3.43 3.43 0 0 0-6.17 2.06 3.33 3.33 0 0 0 .09.78s12 58.88 29.33 100.69 41 83.65 41 83.65a4.39 4.39 0 0 0 3.39 1.87 13.12 13.12 0 0 0 10.94-4.53 4.4 4.4 0 0 0 1.01-3.74Z"
        style={{
          fill: '#00ab55',
        }}
      />
      <path
        d="M88.91 133.77a3.33 3.33 0 0 0 .09.78s12 58.88 29.33 100.69c11.63 28.13 26.14 56.26 34.4 71.67l9.06-5.74S89.66 131.63 92.35 130.34a3.43 3.43 0 0 0-3.44 3.43Z"
        style={{
          opacity: 0.1,
        }}
      />
      <path
        d="M159.29 318.89c-1.46 7.35-4 16.79.62 29.05 5.21 13.92 21 17.76 28.58 17.77 10.57 0 17.26-3.92 17.26-3.92s-5.47-2.1-7.73-10a26.53 26.53 0 0 1-.82-5.26c-.49-6.4-.7-13.37-6.29-21.1a37 37 0 0 0-16.22-13Z"
        style={{
          fill: '#ebebeb',
        }}
      />
      <path
        d="M198 351.79a26.53 26.53 0 0 1-.82-5.26c0 .05-1.91 8.34-6.58 7.85s-6-5.46-12.21-5.46c-7.55 0-10.15 6.34-16.62 2.92 6.48 10.89 19.83 13.86 26.7 13.87 10.57 0 17.26-3.92 17.26-3.92s-5.45-2.1-7.73-10Z"
        style={{
          fill: '#00ab55',
        }}
      />
      <path
        d="M178.28 354.46c-12.22-8.47-9.92-31-9.56-34.67l-9.43-.9c-1.46 7.35-4 16.79.62 29.05 5.21 13.92 21 17.76 28.58 17.77 10.57 0 17.26-3.92 17.26-3.92s-13.16 2.6-27.47-7.33Z"
        style={{
          opacity: 0.1,
        }}
      />
      <path
        d="M159.29 318.89a4.39 4.39 0 0 0 3.39 1.87 13.12 13.12 0 0 0 10.94-4.53 4.41 4.41 0 0 0 1.07-3.78c-1.78-6.32-3.83-13.17-6.06-20.37.44 1.4 0 3.12-1.31 4.88a16.51 16.51 0 0 1-13.81 5.72c-2.26-.29-3.78-1.27-4.43-2.61 3.92 7.33 7.58 14.18 10.21 18.82Z"
        style={{
          fill: '#455a64',
        }}
      />
      <path
        d="M161.79 301.17a15.64 15.64 0 0 1-8.28 1.51c-2.26-.29-3.78-1.27-4.43-2.61 3.9 7.33 7.58 14.18 10.21 18.82a4.39 4.39 0 0 0 3.39 1.87 12.19 12.19 0 0 0 6-1l.11-.19-7-18.43Z"
        style={{
          fill: '#37474f',
        }}
      />
      <circle
        cx={95.45}
        cy={166.61}
        r={10.85}
        style={{
          fill: '#263238',
        }}
        transform="rotate(-13.28 95.483 166.66)"
      />
      <path
        d="M69.47 350.13h7.64v13.08h-7.64z"
        style={{
          fill: '#ffa8a7',
        }}
        transform="rotate(180 73.295 356.675)"
      />
      <path
        d="m95.96 349.44 7.64.26.38-13.84-7.63-.26-.39 13.84z"
        style={{
          fill: '#ffa8a7',
        }}
      />
      <path
        d="M70 246.46c0 7.3-1 51.51-1 51.51-.18 2.1-2.14 6-1.72 14 .58 10.95 1.53 39.43 1.53 39.43s3.58 3.55 8.64 0c0 0 6.92-39.39 8.28-50.25C87 291.71 89 266 89 266l7.08 30.29s-1.25 7.12-1.52 12.14 1.29 31.34 1.29 31.34a8.36 8.36 0 0 0 8.32.31s6.13-39.69 6.92-44.23-5.37-49.37-5.37-49.37Z"
        style={{
          fill: '#37474f',
        }}
      />
      <path
        d="M77.32 193.69a8.36 8.36 0 0 0-6.72 6.41 27.05 27.05 0 0 0-.62 5.8v40.56c5.35 5.83 24 8.54 35.73 0 0 0-.23-35.82-1.11-41.71-1.52-10.23-6.41-13.87-11.33-13.49L81.48 193Z"
        style={{
          fill: '#f0f0f0',
        }}
      />
      <path
        d="M76.7 174.32s1.76 8.26 2.46 9.62a3.78 3.78 0 0 0 2.16 1.74l-.12-5.81Z"
        style={{
          fill: '#263238',
        }}
      />
      <path
        d="M78.91 162.49a4.08 4.08 0 0 0-3.37 1.19c-1.45 1.48-.64 6.11 1.16 10.64l3.31.38Z"
        style={{
          fill: '#263238',
        }}
      />
      <path
        d="M81 174.71c-.66.39-1.61-.88-2.43-1.74s-3.49-2-4.82.78 1.18 6.65 3.28 7.31c3.63 1.14 4.17-1.19 4.17-1.19l.28 13.13a11.66 11.66 0 0 0 8.8 6.68c6.43.87 5.11-4.43 3-7v-4a23.24 23.24 0 0 0 4.89.26c2.67-.42 4.35-2.52 5.15-5.41 1.3-4.63 1.8-8.38.69-17.49-1.2-10-12.83-10.1-19.1-6.15S81 174.71 81 174.71Z"
        style={{
          fill: '#ffa8a7',
        }}
      />
      <path
        d="M81 175.82c-.33 0-1.6-2.1-2.43-2.85-1.14-1 .34-10.48.34-10.48a4.45 4.45 0 0 1 1.1-4.77c1.57-1.72 4.19-1.92 8.67-2.62 2.34-.37 5.9-1 8.25-1.46 2.8-.52 5.12-1.07 7.09.65a5 5 0 0 1 1.38 5.56 4.78 4.78 0 0 1-.8 1.19 4.63 4.63 0 0 1-2 1.38s-1.22 1.09-5.47 1.6a62.93 62.93 0 0 1-11.77-.22c-1.23-.17-1.49 1-2.16 4.29-.59 2.91-1.08 7.71-2.2 7.73Z"
        style={{
          fill: '#263238',
        }}
      />
      <path
        d="m78.91 163.23-4-2.16a2.21 2.21 0 0 1 3-1 2.4 2.4 0 0 1 1 3.16Z"
        style={{
          fill: '#263238',
        }}
      />
      <path
        d="M93.26 188.62s-6-1.19-8-2.3a6.87 6.87 0 0 1-2.88-2.85 9.33 9.33 0 0 0 1.62 3.36c1.52 1.95 9.27 3.38 9.27 3.38Z"
        style={{
          fill: '#f28f8f',
        }}
      />
      <path
        d="M91.83 173.22a1.19 1.19 0 1 1-1.19-1.22 1.2 1.2 0 0 1 1.19 1.22ZM90.57 168.72l-2.57 1.4a1.53 1.53 0 0 1 .59-2 1.42 1.42 0 0 1 1.98.6Z"
        style={{
          fill: '#263238',
        }}
      />
      <path
        d="m92.72 181.46 2.73 1a1.43 1.43 0 0 1-1.85.9 1.52 1.52 0 0 1-.88-1.9Z"
        style={{
          fill: '#f28f8f',
        }}
      />
      <path
        d="m102.47 169.71-2.33-1.79a1.41 1.41 0 0 1 2-.31 1.54 1.54 0 0 1 .33 2.1ZM101.75 172.88a1.19 1.19 0 1 1-1.19-1.24 1.2 1.2 0 0 1 1.19 1.24Z"
        style={{
          fill: '#263238',
        }}
      />
      <path
        d="m95.68 171.75.39 7.17 3.74-1.27-4.13-5.9z"
        style={{
          fill: '#f28f8f',
        }}
      />
      <path
        d="M77.11 359.44v-1.09c.74.32 2.43 6.8 5.47 9.56 2.41 2.2 5.69 4.74 6.53 7.19s-4.58 4-6.83 3.56c-2.62-.47-7.15-2.31-8.29-4s-1.67-4.22-2.59-5.47-3.06-2.65-3.56-4.1c-.28-.8 0-2.54.44-4s.73-2.84 1.19-2.71v.83a6 6 0 0 0 3.82 1.29c1.12.02 3.83-.21 3.82-1.06ZM103.68 347.34c.4 0 .72.58 1.59 1.52a16.74 16.74 0 0 0 4.5 3.24c2.86 1.43 8.42 3.63 10.94 4.77 1.57.7 1.5 2.6-.13 3.69s-5.55 1.85-10.26.8c-2.57-.57-5.91-2.94-8-2.82s-6.08.1-7.64-1-.94-3.38-.49-5.35c.48-2.16 1.17-5.46 1.88-5.31v.68c.92 1.39 5.59 2 7.61.93ZM89 266a28.35 28.35 0 0 0 10.64-6.1s-2.06 3.84-8.16 7.42l2.29 19.53Z"
        style={{
          fill: '#263238',
        }}
      />
      <path
        d="M131.75 237.48c-1.68.43-3.3.29-5.62 1.09a10.31 10.31 0 0 1-4.91.83c-2.17-.13-7.87-2.83-12.92-5.89a57.85 57.85 0 0 1-14.39-13.38c-4.57-6.47-9.63-16.67-12-20.57s-4.71-4.32-7.4-4.2c-3.16.13-5.18 3-2.4 9a122.25 122.25 0 0 0 14.52 23 61.46 61.46 0 0 0 15.34 13c5.34 3.27 10 4.88 16 6.87 1.62.54 2.69 1.34 5.65 2.74a13.86 13.86 0 0 0 3.79 1.4 17.17 17.17 0 0 0 5 0 11.81 11.81 0 0 0 5.76-2.46 7.47 7.47 0 0 0 2.14-2.72 5 5 0 0 0 .25-3.41 1.4 1.4 0 0 0-.33-.61 1.68 1.68 0 0 0-1.21-.28 71.5 71.5 0 0 1-7.3.36c-2-.15-3.38-.52-3.1-.8s1.46-.12 3.06-.81 2.64-2.22 2.21-3-.46-.59-2.14-.16Z"
        style={{
          fill: '#ffa8a7',
        }}
      />
      <path
        d="M75.49 194.24c-2.43.12-5.57 1.6-5.51 5.75s6.43 16.2 11.34 23.08c0 0 7.67-.49 11.69-6.27 0 0-7.16-11.39-9.1-15-1.75-3.29-4.91-7.74-8.42-7.56Z"
        style={{
          fill: '#e0e0e0',
        }}
      />
      <path
        d="M139 211.48a12.38 12.38 0 0 0-7-.78c-2 .33-2 7 4.43 9.13 4.19 1.37 6.71.77 8.06 4.21 0 0 2.71-.36 2.1-4.21a21.58 21.58 0 0 0-5.11-10.69c-1.85-2.18-2-4-2.88-3.39s-.87 2.85-.87 2.85Z"
        style={{
          fill: '#ffa8a7',
        }}
      />
      <path
        d="m314.6 239.18-36.7-.5-.32 30c0 2.71 1.73 5.44 5.29 7.55 7.13 4.22 18.75 4.34 26 .28 3.64-2.05 5.46-4.77 5.45-7.51Z"
        style={{
          fill: '#37474f',
        }}
      />
      <path
        d="M283.36 231.25c-7.21 4.07-7.28 10.78-.16 15s18.74 4.34 25.95.28 7.29-10.77.17-15-18.75-4.34-25.96-.28Z"
        style={{
          fill: '#455a64',
        }}
      />
      <path
        d="M313 239.06a4.56 4.56 0 0 1-.26 1.44c-.55 1.72-2.05 3.31-4.39 4.63a27.11 27.11 0 0 1-24.36-.26c-2.26-1.34-3.74-3-4.26-4.63a4.53 4.53 0 0 1-.26-1.54c0-2.23 1.68-4.39 4.66-6a27.2 27.2 0 0 1 24.36.26c2.96 1.69 4.51 3.86 4.51 6.1Z"
        style={{
          fill: '#263238',
        }}
      />
      <path
        d="M312.76 240.5c-.55 1.72-2.05 3.31-4.39 4.63a27.11 27.11 0 0 1-24.36-.26c-2.26-1.34-3.74-3-4.26-4.63a8.75 8.75 0 0 1 4.4-4.61 26.95 26.95 0 0 1 24.36.26 8.87 8.87 0 0 1 4.25 4.61Z"
        style={{
          fill: '#00ab55',
        }}
      />
      <path
        d="M304.07 250.41c.81-.45 1.45-.07 1.44.85a3.22 3.22 0 0 1-1.47 2.5c-.81.45-1.45.07-1.44-.85a3.22 3.22 0 0 1 1.47-2.5Z"
        style={{
          fill: '#263238',
        }}
      />
      <path
        d="M277.84 244c-2 4-6 13.44-.82 17.3.22.16.42.3.64.44v1.91c-.52-.31-1.06-.67-1.58-1.05-7.79-5.78 1-20.59 1.38-21.21a.74.74 0 0 1 .44-.34Z"
        style={{
          fill: '#e0e0e0',
        }}
      />
      <path
        d="M304.76 252.46c-.22.46-5.89 11.3-15.22 13.48a13.62 13.62 0 0 1-3.22.38 16.18 16.18 0 0 1-8.68-2.72v-1.91c3.85 2.67 7.73 3.59 11.52 2.71 8.61-2 14.12-12.58 14.16-12.68a.8.8 0 0 1 1.08-.34.78.78 0 0 1 .36 1.08Z"
        style={{
          fill: '#e0e0e0',
        }}
      />
      <path
        d="M257.27 179.52h-84l9.39 77.28c.43 4.48 3.56 8.88 9.48 12.3 12.77 7.37 33.46 7.37 46.22 0 5.92-3.42 9.06-7.82 9.49-12.3Z"
        style={{
          fill: '#37474f',
        }}
      />
      <path
        d="M185.61 162.36c16.41-9.47 43-9.47 59.42 0s16.41 24.84 0 34.31-43 9.47-59.42 0-16.41-24.83 0-34.31Z"
        style={{
          fill: '#455a64',
        }}
      />
      <path
        d="M215.32 198.9c-10.38 0-20.07-2.29-27.27-6.45-6.37-3.68-9.87-8.27-9.87-12.94s3.5-9.25 9.87-12.93c7.2-4.16 16.89-6.45 27.27-6.45s20.07 2.29 27.27 6.45c6.37 3.68 9.87 8.27 9.87 12.93s-3.5 9.26-9.87 12.93c-7.2 4.17-16.89 6.46-27.27 6.46Z"
        style={{
          fill: '#263238',
        }}
      />
      <g
        style={{
          clipPath: 'url(#a)',
        }}
      >
        <path
          d="m238.58 243-15.5 29.5a1 1 0 0 1-1.89-.3l-5.68-33Z"
          style={{
            fill: '#ffa8a7',
          }}
        />
        <path
          d="m238.58 243-15.5 29.5a1 1 0 0 1-1.89-.3l-5.68-33Z"
          style={{
            fill: '#ffa8a7',
          }}
        />
        <path
          d="M221.19 272.17a1 1 0 0 0 1.89.3l3-5.76a5.62 5.62 0 0 1-5.72-1.21l-.42-.4Z"
          style={{
            fill: '#263238',
          }}
        />
        <path
          d="m237.4 100.27-.47 3-.6 3.67-20.83 132.19a14.86 14.86 0 0 0 9 6.09c5.5 1.45 11.51.5 14-2.26l20.74-131.16 1.11-7c-.97 4.48-23.86.01-22.95-4.53Z"
          style={{
            fill: '#00ab55',
          }}
        />
        <path
          d="m235.54 111.86-1.06 6.81c-.37 2 3.63 3.89 8.42 5.09 6.34 1.6 14.06 1.93 14.56-.67l.79-5 .25-1.8c-.5 2.6-8.22 2.28-14.56.68-4.59-1.16-8.47-3-8.42-4.87a.35.35 0 0 1 .02-.24Z"
          style={{
            fill: '#ebebeb',
          }}
        />
        <path
          d="M235.29 113.57c-.88 4.55 22.09 9 23 4.41l1.08-6.81c-.87 4.55-23.84.14-23-4.41Z"
          style={{
            fill: '#00ab55',
          }}
        />
        <path
          d="m237.4 100.27-1.07 6.73c-.89 4.47 21.24 9 22.91 4.81a.92.92 0 0 0 .08-.25l1-6.78c-.94 4.5-23.83.03-22.92-4.51Z"
          style={{
            fill: '#ebebeb',
          }}
        />
        <path
          d="m259.24 111.8 1.11-7c-.44 2-5.36 2.24-10.53 1.43l-20.95 139.64-5.79 26.6 15.5-29.5Z"
          style={{
            opacity: 0.1,
          }}
        />
        <path
          d="m241.93 71.74-4.53 28.53c-.39 1.94 3.57 3.87 8.31 5.08 6.34 1.63 14.09 2 14.64-.58l4.35-27.44c1.54-6.32-10.13-11-17.4-9.69-2.71.44-4.79 1.74-5.37 4.1Z"
          style={{
            fill: '#ffa8a7',
          }}
        />
        <path
          d="m188.54 236.5-.42-3.06-.53-3.68-18.54-132.49c1.72 1.77 6.17 2.71 11 2.19 5.65-.61 10.86-2.93 12.1-5.62l18.4 131.51 1 7.05c-.88-4.53-23.84-.46-23.01 4.1Z"
          style={{
            fill: '#00ab55',
          }}
        />
        <path
          d="m186.88 224.87-.94-6.82c-.34-2 3.7-3.83 8.5-4.94 6.38-1.5 14.1-1.69 14.55.92l.71 5 .22 1.8c-.46-2.61-8.18-2.42-14.56-.93-4.6 1.08-8.51 2.85-8.49 4.72a.41.41 0 0 0 .01.25Z"
          style={{
            fill: '#ebebeb',
          }}
        />
        <path
          d="M186.66 223.17c-.8-4.58 22.24-8.59 23-4l1 6.83c-.8-4.57-23.84-.56-23 4Z"
          style={{
            fill: '#00ab55',
          }}
        />
        <path
          d="m188.54 236.5-.95-6.74c-.82-4.49 21.39-8.58 23-4.41a1.49 1.49 0 0 1 .07.25l.91 6.8c-.9-4.53-23.86-.46-23.03 4.1Z"
          style={{
            fill: '#ebebeb',
          }}
        />
        <path
          d="M178.52 71.21c-3.36.87-4.55.17-4.55.17l-4.91 25.89c2.68 3.85 9.21 4.65 14.74 3l-.17-1.27Z"
          style={{
            fill: '#ffa8a7',
          }}
        />
        <path
          d="m192.19 93.84-12.05-24Z"
          style={{
            fill: '#fafafa',
          }}
        />
        <path
          d="m180.13 69.88-2.92-5.81 2.92 5.81z"
          style={{
            fill: '#e0e0e0',
          }}
        />
        <path
          d="m175.31 64.34-1.34 7s1.14.81 4.55-.17l-1.31-7.14a1 1 0 0 0-1.34-.46 1 1 0 0 0-.56.77Z"
          style={{
            fill: '#455a64',
          }}
        />
        <path
          d="m210.58 225.35-18.4-131.48c-1.3 3.13-4.61 5.35-8.38 6.44l17.26 130.47c5.19-.72 10.1-.43 10.5 1.62Z"
          style={{
            opacity: 0.1,
          }}
        />
        <path
          d="m192.18 93.87-12.06-24a3.06 3.06 0 0 1-1.61 1.33l5.12 27.8.17 1.29c3.77-1.07 7.08-3.29 8.38-6.42Z"
          style={{
            fill: '#f28f8f',
          }}
        />
        <path
          d="M178.52 71.21a2.39 2.39 0 0 0 1.61-1.33l-2.92-5.81Z"
          style={{
            fill: '#37474f',
          }}
        />
        <path
          d="m192.57 265.1-4-28.6c-.35-1.95 3.63-3.81 8.39-4.94 6.38-1.52 14.13-1.76 14.63.84l3.88 27.52c1.43 6.34-10.32 10.81-17.56 9.38-2.74-.5-4.8-1.83-5.34-4.2Z"
          style={{
            fill: '#f28f8f',
          }}
        />
        <path
          d="M207.86 85.31s-4.47 35.86-4.51 71.37c0 35.2 6.47 73.63 7.77 79.56v.07a.36.36 0 0 1 0 .1 2.63 2.63 0 0 0 5 .35 2.38 2.38 0 0 0 .16-.58S225 191 225.07 156.4s-4.44-71.13-4.44-71.13a3.39 3.39 0 0 0-1.85-2.27 10 10 0 0 0-9.06 0 3.32 3.32 0 0 0-1.86 2.31Z"
          style={{
            fill: '#00ab55',
          }}
        />
        <path
          d="M216.13 236.76a2.38 2.38 0 0 0 .16-.58S225 191 225.07 156.4c0-23.29-2-47.43-3.32-60.74l-8.08 1.4s1.3 141-1 141.12a2.63 2.63 0 0 0 3.46-1.42Z"
          style={{
            opacity: 0.1,
          }}
        />
        <path
          d="M214.23 81.87c1.12-2.1 7.23-13.44 5.26-22.68a10.11 10.11 0 0 1-3.58-.76c-4.39-1.82-3.87-5.72-7-7.44s-6.92 3.58-6.95 3.63c-1.53 4.66-3.43 9.65-1.74 16.76a28.3 28.3 0 0 0 7.67 13.92h12.77c.8-1.19 1.68-2.46 2.55-3.84-.87 1.38-1.75 2.65-2.55 3.84Z"
          style={{
            fill: '#f0f0f0',
          }}
        />
        <path
          d="M228.52 61.23c-2.72-3.27-5.51-1.92-9-2 2 9.24-4.14 20.58-5.26 22.68l6.4 3.39c.8-1.19 1.68-2.46 2.55-3.84.22-.34.44-.7.66-1.06.44-.72.87-1.48 1.28-2.27a32.58 32.58 0 0 0 1.72-3.85c.33-.92.64-1.88.9-2.9a30.78 30.78 0 0 0 1-6.79 19 19 0 0 0-.25-3.36Z"
          style={{
            opacity: 0.1,
          }}
        />
        <path
          d="M228.51 61.18c-.09-.57-.2-1.12-.33-1.67v-.16c-.11-.41-.23-.82-.36-1.21l-.15-.46c0-.12-.1-.24-.14-.36l-.25-.63c-.11-.26-.22-.51-.34-.75a16 16 0 0 0-.94-1.64c-.31-.53-.64-1-1-1.54s-.71-1-1.09-1.43a28.56 28.56 0 0 0-8.51-6.9l-.71-.36-.2-.1-.82-.36a31.76 31.76 0 0 0-5.24-1.68c-.8-.18-1.56-.31-2.27-.42s-1.37-.17-2-.21l-.86-.06h-1.98c-.67 0-1 .08-1 .08s3.26 3.09 2.54 9.33a20.45 20.45 0 0 1-1 4s3.8-5.34 6.95-3.63 2.64 5.62 7 7.44a10.11 10.11 0 0 0 3.58.76c3.52.12 6.31-1.23 9 2Z"
          style={{
            fill: '#00ab55',
          }}
        />
        <path
          d="M200.35 41.32s10.07 2 17.28 13.23a13.79 13.79 0 0 1 1.86 4.64c3.52.12 6.31-1.23 9 2-.09-.57-.2-1.12-.33-1.67v-.16c-.11-.41-.23-.82-.36-1.21l-.15-.46c0-.12-.1-.24-.14-.36l-.25-.63c-.11-.26-.22-.51-.34-.75a18.27 18.27 0 0 0-.92-1.65c-.31-.53-.64-1-1-1.54s-.71-1-1.09-1.43a28.56 28.56 0 0 0-8.51-6.9l-.71-.36-.2-.1-.82-.36a31.76 31.76 0 0 0-5.24-1.68c-.8-.18-1.56-.31-2.27-.42s-1.37-.17-2-.21l-.86-.06h-1.98c-.67 0-1 .08-1 .08Z"
          style={{
            opacity: 0.1,
          }}
        />
        <path
          d="M220.63 85.26a3.4 3.4 0 0 0-1.85-2.26 10 10 0 0 0-9.06 0 3.3 3.3 0 0 0-1.86 2.35c-.6 5-1.16 10.44-1.7 16.18a4.18 4.18 0 0 1 2.36-3.07 12.64 12.64 0 0 1 11.44 0 4.12 4.12 0 0 1 2.37 3.14c-.61-6.36-1.2-12.28-1.7-16.34Z"
          style={{
            fill: '#455a64',
          }}
        />
        <path
          d="M213.67 97.06a11.93 11.93 0 0 1 6.33 1.36 4.12 4.12 0 0 1 2.37 3.14c-.61-6.32-1.2-12.24-1.7-16.3a3.4 3.4 0 0 0-1.89-2.26 9.28 9.28 0 0 0-4.55-1.08l-.14.1-.42 15.09Z"
          style={{
            fill: '#37474f',
          }}
        />
      </g>
      <path
        d="m260.35 104.77 4.35-27.44a4.24 4.24 0 0 0 .12-.85C263 81.17 254 78.77 254 78.77l-4.21 27.42c5.21.81 10.12.62 10.56-1.42Z"
        style={{
          fill: '#f28f8f',
        }}
      />
    </svg>
  );
}
export default React.memo(CreateIllustration);
