// @mui
import { Stack, Typography } from '@mui/material';
// layouts
import LoginLayout from '../../layouts/login';
//
import AuthLoginForm from './AuthLoginForm';
//
import Infraweb from '../../assets/logo/infraweb_logo.png';
import Image from '../../components/image';

// ----------------------------------------------------------------------

export default function Login() {
  return (
    <LoginLayout>
      <Stack
        spacing={2}
        sx={{ mb: 5 }}
        direction="row"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Stack direction="row" spacing={1} alignItems="center">
          <Typography variant="h4" sx={{ color: '#782a90', fontWeight: 'bold' }}>
            Autentificare
          </Typography>
          <Typography variant="h4" sx={{ color: '#3ab76b', fontWeight: 'bold' }}>
            Infraweb
          </Typography>
        </Stack>
        <Image disabledEffect visibleByDefault alt="auth" src={Infraweb} sx={{ maxWidth: 50 }} />
      </Stack>

      <AuthLoginForm />
    </LoginLayout>
  );
}
