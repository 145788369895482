import React, { useEffect, useState, useRef } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import PhoneEnabledIcon from '@mui/icons-material/PhoneEnabled';
import { Box, IconButton, Typography } from '@mui/material';
import PhonePausedIcon from '@mui/icons-material/PhonePaused';
import CallEndIcon from '@mui/icons-material/CallEnd';

import { changeCallState } from '../../../../../redux/slices/virtualPhone';
import WebSocketService from '../../../../../api/WebSocketService';

const InboundCallView = () => {
  const { session, queueName } = useSelector((state) => state.virtualPhone);
  const dispatch = useDispatch();

  const endCall = () => {
    session.terminate();
    dispatch(changeCallState('formatting'));
  };

  const acceptCall = () => {
    session.answer();
    dispatch(changeCallState('incall'));
  };

  return (
    <div>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <Typography sx={{ fontSize: '20px' }}>
          Intrare apel - <span style={{ color: 'royalBlue', fontWeight: 'bold' }}>{queueName}</span>
        </Typography>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            mt: 10,
          }}
        >
          <IconButton
            size="large"
            sx={{ color: 'black', backgroundColor: 'lightgreen', mr: 5 }}
            onClick={acceptCall}
          >
            <PhoneEnabledIcon fontSize="large" />
          </IconButton>
          <IconButton
            size="large"
            sx={{ color: 'black', backgroundColor: 'coral' }}
            onClick={endCall}
          >
            <CallEndIcon fontSize="large" />
          </IconButton>
        </Box>
      </Box>
    </div>
  );
};

InboundCallView.propTypes = {};

export default InboundCallView;
