import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

const { TextField } = require('@mui/material');

const NumberFormatTextField = ({ number, handleFormatNumberByInput }) => (
  <TextField
    id="numberFormat-input"
    value={number}
    variant="standard"
    onChange={(e) => handleFormatNumberByInput(e.target.value)}
    InputProps={{
      sx: {
        color: 'black',
        fontSize: '25px',
        letterSpacing: 3,
        textAlign: 'center',
      },
    }}
  />
);
NumberFormatTextField.propTypes = {
  handleFormatNumberByInput: PropTypes.func.isRequired,
  number: PropTypes.string,
};
export default NumberFormatTextField;
