import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

function ErrorBoundary({ children }) {
  const [hasError, setHasError] = useState(false);
  const navigate = useNavigate();

  const handleCatch = (error, info) => {
    console.error('ErrorBoundary caught an error:', error, info);
    setHasError(true);
  };

  const handleUpdate = (prevProps, props) => {
    const { location: prevLocation } = prevProps;
    const { location } = props;

    if (hasError && prevLocation !== location) {
      setHasError(false);
    }
  };

  useEffect(() => {
    if (hasError) {
      navigate('/500');
    }
  }, [hasError, navigate]);

  return (
    <>
      {hasError ? null : children}
      <ErrorBoundaryWrapper componentDidCatch={handleCatch} componentDidUpdate={handleUpdate} />
    </>
  );
}

ErrorBoundary.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.object.isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

function ErrorBoundaryWrapper({ componentDidCatch, componentDidUpdate }) {
  useEffect(() => {
    window.addEventListener('error', componentDidCatch);
    return () => {
      window.removeEventListener('error', componentDidCatch);
    };
  }, [componentDidCatch]);

  useEffect(() => window.removeEventListener('popstate', componentDidUpdate), [componentDidUpdate]);

  useEffect(() => {
    window.addEventListener('popstate', componentDidUpdate);
    return () => {
      window.removeEventListener('popstate', componentDidUpdate);
    };
  }, [componentDidUpdate]);

  return null;
}

ErrorBoundaryWrapper.propTypes = {
  componentDidCatch: PropTypes.func.isRequired,
  componentDidUpdate: PropTypes.func.isRequired,
};

export default ErrorBoundary;
