import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled, alpha } from '@mui/material/styles';
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Link,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
// auth
import { useAuthContext } from '../../../auth/useAuthContext';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import { CustomAvatar } from '../../../components/custom-avatar';

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: alpha(theme.palette.grey[500], 0.12),
}));

// ----------------------------------------------------------------------

const roleColor = {
  ROLE_AGENT_CALL_CENTER: 'royalblue',
  ROLE_ADMIN: '#BE3144',
  ROLE_MANAGER_FISIERE: '#AE445A',
  ROLE_CARTE_TEHNICA: '#557C55',
  ROLE_GESTIONAR_DEPOZIT: '#219C90',
};

export default function NavAccount() {
  const { user } = useAuthContext();
  const [openDialog, setOpenDialog] = useState(false);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const formatRole = (role) => {
    const formattedRole = role
      .toLowerCase()
      .replace('role_', '')
      .split('_')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
    return formattedRole;
  };

  return (
    <>
      <Link component={RouterLink} underline="none" color="inherit" sx={{ cursor: 'context-menu' }}>
        <StyledRoot>
          <CustomAvatar src={user?.photoURL} alt={user?.fullName} name={user?.fullName} />

          <Box
            sx={{
              ml: 2,
              minWidth: 0,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <Typography variant="subtitle2" noWrap>
              {user?.fullName.split(' ')[0]}
            </Typography>

            <IconButton onClick={handleOpenDialog}>
              <ManageAccountsIcon />
            </IconButton>
          </Box>
        </StyledRoot>
      </Link>

      <Dialog fullWidth open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Profil</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
              <CustomAvatar
                src={user?.photoURL}
                alt={user?.fullName.split(' ')[0]}
                name={user?.fullName.split(' ')[0]}
              />
              <Typography variant="subtitle" sx={{ ml: 2, color: 'black', fontWeight: 'bold' }}>
                {user?.fullName.split(' ')[0]}
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', gap: 2 }}>
              {user.roles.map((role, index) => (
                <Chip
                  key={index}
                  label={formatRole(role)}
                  sx={{ bgcolor: roleColor[role], fontWeight: 'bold', color: 'white' }}
                />
              ))}
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, mt: 3 }}>
              <Typography variant="subtitle" sx={{ color: 'black' }}>
                <span style={{ fontWeight: 'bold' }}>Email:</span> {user?.email}
              </Typography>
              <Typography variant="subtitle" sx={{ color: 'black' }}>
                <span style={{ fontWeight: 'bold' }}>Telefon:</span> {user?.phoneNumber || 'N/A'}
              </Typography>
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Inchide
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
