// array gol inseamna access indiferent de rol
// accessul este verificat pentru fiecare submeniu,
// de exemplu la tickets poate sa fie access indiferent de rol ca sectiune, dar lista de tickete poate avea rol admin necesar

export const availableRoles = ['admin', 'user', 'moderator'];

const routePermissions = {
  // TICKETS
  tickets: {
    roles: ['admin', 'agent_call_center'],
    list: {
      roles: [],
    },
    departmentName: {
      roles: [],
      id: {
        roles: [],
      },
    },
    id: {
      roles: [],
      edit: {
        roles: [],
      },
    },
    new: {
      roles: [],
    },
  },
  // VOICE
  voice: {
    roles: ['admin', 'agent_call_center'],
    general: {
      roles: [],
    },
    queues: {
      roles: [],
    },
    callReports: {
      roles: [],
    },
  },
  // ADMIN
  admin: {
    roles: ['admin'],
    general: {
      roles: [],
    },
    companies: {
      roles: [],
      list: {
        roles: [],
      },
      new: {
        roles: [],
      },
    },
    projects: {
      roles: [],
      list: {
        roles: [],
      },
      new: {
        roles: [],
      },
      edit: { roles: [] },
    },
    accounts: {
      roles: [],
      list: {
        roles: [],
      },
      new: {
        roles: [],
      },
    },
    custom: {
      roles: [],
      modules: {
        roles: [],
      },
    },
    predefined: {
      roles: [],
      values: {
        roles: [],
      },
      selects: {
        roles: [],
      },
    },
  },
  // BOOK
  book: {
    roles: ['admin', 'carte_tehnica'],
    list: {
      roles: [],
    },
    new: {
      roles: [],
    },
    id: {
      roles: [],
      edit: {
        roles: [],
      },
    },
  },
  // INVENTORY
  inventory: {
    roles: ['admin', 'gestionar_depozit'],
    list: {
      roles: [],
    },
    teamOrders: {
      roles: [],
    },
  },
  // CLIENTS
  clients: {
    roles: ['admin'],
    list: {
      roles: [],
    },
    add: {
      roles: [],
    },
    id: {
      roles: [],
      edit: {
        roles: {},
      },
    },
  },
  // FILES MANAGER
  filesManager: {
    roles: ['admin', 'manager_fisiere'],
  },
};

export default routePermissions;
