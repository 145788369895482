import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer, useCallback, useMemo } from 'react';
// Cookies
import Cookies from 'js-cookie';
// utils
import axios from 'axios';
// Mails reducer
import { resetState } from '../redux/slices/mail';
//
import { isValidToken } from './utils';
import { BASE_URL } from '../config-global';
import { getAuthenticatedUserFromCookies } from '../utils/cookies';

//

// ----------------------------------------------------------------------

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

// ----------------------------------------------------------------------

const initialState = {
  isInitialized: false,
  isAuthenticated: false,
  user: null,
};

const reducer = (state, action) => {
  if (action.type === 'INITIAL') {
    return {
      isInitialized: true,
      isAuthenticated: action.payload.isAuthenticated,
      user: action.payload.user,
    };
  }
  if (action.type === 'LOGIN') {
    return {
      ...state,
      isAuthenticated: true,
      user: action.payload.user,
    };
  }
  if (action.type === 'REGISTER') {
    return {
      ...state,
      isAuthenticated: true,
      user: action.payload.user,
    };
  }
  if (action.type === 'LOGOUT') {
    return {
      ...state,
      isAuthenticated: false,
      user: null,
    };
  }

  return state;
};

// ----------------------------------------------------------------------

export const AuthContext = createContext(null);

// ----------------------------------------------------------------------

const url = `${BASE_URL}/auth`;

AuthProvider.propTypes = {
  children: PropTypes.node,
};

export function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const initialize = useCallback(async () => {
    try {
      if (Cookies.get('authenticatedUser')) {
        const user = getAuthenticatedUserFromCookies();
        dispatch({
          type: 'INITIAL',
          payload: {
            isAuthenticated: true,
            user,
          },
        });
      } else {
        dispatch({
          type: 'INITIAL',
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    } catch (error) {
      dispatch({
        type: 'INITIAL',
        payload: {
          isAuthenticated: false,
          user: null,
        },
      });
    }
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN
  const login = useCallback(async (email, password) => {
    const response = await axios.post(`${url}/login`, {
      email,
      password,
    });

    if (response.data.message !== 'Account is not verified') {
      const user = response.data;
      Cookies.set('authenticatedUser', JSON.stringify(response.data), {
        expires: 1,
        secure: true,
      });
      dispatch({
        type: 'LOGIN',
        payload: {
          user,
        },
      });
    }
    return response;
  }, []);

  // REGISTER
  const register = useCallback(
    async (email, password, firstName, lastName, passwordConfirmation, phoneNumber) => {
      const response = await axios.post(`${url}/register`, {
        email,
        password,
        firstName,
        lastName,
        passwordConfirmation,
        phoneNumber,
      });

      return response;
    },
    []
  );

  // LOGOUT
  const logout = useCallback(() => {
    Cookies.remove('authenticatedUser');
    dispatch({ type: 'LOGOUT' });
    dispatch(resetState());
    // window.location.href = '/auth/login';
  }, []);

  // OpenAPI for company
  const checkCUI = useCallback(async (cui) => {
    const response = await axios.get(`https://api.openapi.ro/api/companies/${cui}`, {
      headers: {
        'x-api-key': 'qCp-Jg6sf1iHpDredLka-BYh6Ltyr5zKz8vS-twRF9vu1KW9gw',
      },
    });
    return response;
  }, []);

  // verify the code after register
  const verifyCode = useCallback(async (code) => {
    const response = await axios.post(`${url}/verifyCode`, {
      code,
    });
    return response;
  }, []);

  // resendVerifyCode
  const resendCode = useCallback(async (email, phoneNumber) => {
    const response = await axios.post(`${url}/resendCode`, {
      email,
      phoneNumber,
    });

    return response;
  }, []);

  const memoizedValue = useMemo(
    () => ({
      isInitialized: state.isInitialized,
      isAuthenticated: state.isAuthenticated,
      user: state.user,
      method: 'jwt',
      login,
      register,
      logout,
      checkCUI,
      verifyCode,
      resendCode,
    }),
    [
      state.isAuthenticated,
      state.isInitialized,
      state.user,
      login,
      logout,
      register,
      checkCUI,
      verifyCode,
      resendCode,
    ]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}
