import React, { useCallback, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import PhoneEnabledIcon from '@mui/icons-material/PhoneEnabled';
import { Box, IconButton, Typography } from '@mui/material';
import DialpadIcon from '@mui/icons-material/Dialpad';
import BackspaceIcon from '@mui/icons-material/Backspace';

import NumberFormatTextField from './NumberFormatTextField';
import Keypad from './Keypad';

import {
  changeNumber,
  changeCallState,
  changePhoneState,
} from '../../../../../redux/slices/virtualPhone';
import InCallView from '../InCallView/InCallView';
import WebSocketService from '../../../../../api/WebSocketService';

FormatNumberView.propTypes = {
  makeCall: PropTypes.func.isRequired,
};

export default function FormatNumberView({ makeCall }) {
  const { callState, phoneState } = useSelector((state) => state.virtualPhone);
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState('');

  const handleCall = () => {
    dispatch(changeNumber(inputValue));
    dispatch(changeCallState('calling'));
    makeCall(inputValue);
  };

  const handleFormatNumberByKeypad = (key) => {
    if (inputValue.length < 11) {
      setInputValue(inputValue + key);
    }
  };

  const handleFormatNumberByInput = (key) => {
    if (key.length < 11 && inputValue.length < 11) {
      setInputValue(key);
    }
  };

  const removeNumber = () => {
    if (inputValue.length > 0) {
      setInputValue((state) => state.slice(0, -1));
    }
  };



  console.log(callState, phoneState);
  return (
    <div>
      {callState === 'formatting' ? (
        <>
          <div
            style={{
              padding: '10px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '15%',
              borderRadius: '5px',
              marginBottom: '10px',
              // border: '1px solid white',
              overflow: 'hidden',
              color: 'white',
            }}
          >
            <NumberFormatTextField
              number={inputValue}
              handleFormatNumberByInput={handleFormatNumberByInput}
            />
            <IconButton onClick={removeNumber}>
              <BackspaceIcon />
            </IconButton>
          </div>
          <Box
            sx={{
              width: '100%',
              marginTop: 3,
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Keypad handleFormatNumberByKeypad={handleFormatNumberByKeypad} />
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <IconButton
                // disabled={inputValue.length < 4}
                size="large"
                sx={{ bgcolor: '#7aeee2', color: 'black', mt: 2 }}
                onClick={handleCall}
              >
                <PhoneEnabledIcon fontSize="large" />
              </IconButton>
            </Box>
          </Box>
        </>
      ) : (
        <InCallView  />
      )}
    </div>
  );
}
