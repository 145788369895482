import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
  changeCallMinutes,
  changeCallSeconds,
} from '../../../../../redux/slices/virtualPhoneCallTimer';

const InCallTimer = () => {
  const { callMinutes, callSeconds } = useSelector((state) => state.virtualPhoneCallTimer);
  const dispatch = useDispatch();
  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     if (callSeconds === 59) {
  //       dispatch(changeCallSeconds(0));
  //       dispatch(changeCallMinutes(callMinutes + 1));
  //     } else {
  //       dispatch(changeCallSeconds(callSeconds + 1));
  //     }
  //   }, 1000);

  //   return () => {
  //     clearInterval(intervalId);
  //   };
  // }, [callSeconds, dispatch, callMinutes]);

  return (
    <Typography sx={{ color: 'black', fontSize: '20px' }}>
      {callMinutes.toString().padStart(2, '0')}:{callSeconds.toString().padStart(2, '0')}
    </Typography>
  );
};

InCallTimer.propTypes = {};

export default InCallTimer;
