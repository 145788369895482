import React, { useState } from 'react';
import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse,
  Divider,
  IconButton,
  Typography,
  Box,
} from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import CallIcon from '@mui/icons-material/Call';
import PhoneForwardedIcon from '@mui/icons-material/PhoneForwarded';
import PropTypes from 'prop-types';
import PhoneCallbackIcon from '@mui/icons-material/PhoneCallback';
import PhoneMissedIcon from '@mui/icons-material/PhoneMissed';

import { useDispatch } from 'react-redux';
import {
  changeCallState,
  changeNumber,
  changePhoneState,
} from '../../../../../redux/slices/virtualPhone';

const RecentCall = ({ data, makeCall }) => {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const handleOpen = () => {
    setOpen(!open);
  };

  const reCall = () => {
    dispatch(changeCallState('calling'));
    dispatch(changePhoneState('formatNumber'));
    dispatch(changeNumber(data.clientNumber));
    makeCall(data.clientNumber);
  };

  const isCallMadeByMe = false;
  const isCallMissed = true;
  const callTypeIcon =
    data.type.toLowerCase() === 'failed' ? (
      <PhoneMissedIcon sx={{ color: 'red' }} />
    ) : data.type.toLowerCase() === 'outbound' ? (
      <PhoneForwardedIcon fontSize="medium" sx={{ color: '#d601ff' }} />
    ) : (
      <PhoneCallbackIcon fontSize="medium" sx={{ color: 'green' }} />
    );

  return (
    <>
      <ListItemButton onClick={handleOpen}>
        <ListItemText
          primaryTypographyProps={{
            color: data.type.toLowerCase() === 'failed' ? 'red' : 'black',
          }}
          primary={data.clientName || data.clientNumber}
          secondary={data.date}
        />
        <Box sx={{ mr: 3, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Box sx={{ mr: data.type.toLowerCase() === 'failed' || !data.duration > 0 ? 0 : 3 }}>
            {callTypeIcon}
          </Box>
          <Typography>{data.duration}</Typography>
        </Box>
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-around',
            width: '30%',
            margin: '10px auto',
          }}
        >
          <IconButton onClick={reCall} size="medium" sx={{ border: '1px solid green' }}>
            <CallIcon fontSize="medium" sx={{ color: 'green' }} />
          </IconButton>

          {/* <IconButton size="medium" sx={{ border: '1px solid royalblue' }}>
            <MessageIcon fontSize="medium" sx={{ color: 'royalblue' }} />
          </IconButton> */}
        </div>
      </Collapse>
      <Divider />
    </>
  );
};

RecentCall.propTypes = {
  data: PropTypes.object.isRequired,
  makeCall: PropTypes.func.isRequired,
};

export default RecentCall;
