import Cookies from 'js-cookie';

export const getAuthenticatedUserFromCookies = () => {
  try {
    const cookies = Cookies.get('authenticatedUser');
    if (cookies) {
      return JSON.parse(cookies);
    }

    window.location.href = '/auth/login'; // Replace '/login' with your actual login page URL.
    return null;
  } catch (error) {
    // If there's an error parsing JSON, redirect the user to the login page.
    window.location.href = '/auth/login'; // Replace '/login' with your actual login page URL.
    return null;
  }
};

export const getTokenFromCookies = () => {
  try {
    const cookies = Cookies.get('authenticatedUser');
    if (cookies) {
      const cook = JSON.parse(cookies);
      const token = cook.accessToken;
      return token;
    }
    window.location.href = '/auth/login'; // Replace '/login' with your actual login page URL.
    return null;
  } catch (error) {
    // If there's an error parsing JSON, redirect the user to the login page.
    window.location.href = '/auth/login'; // Replace '/login' with your actual login page URL.
    return null;
  }
};
