import PropTypes from 'prop-types';
import React, { useRef, useCallback } from 'react';
import '../../utils/highlight';
import ReactQuill from 'react-quill';
//
import { StyledEditor } from './styles';
import EditorToolbar, { formats } from './EditorToolbar';

// ----------------------------------------------------------------------

Editor.propTypes = {
  id: PropTypes.string,
  sx: PropTypes.object,
  error: PropTypes.bool,
  simple: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func,
  helperText: PropTypes.object,
  editorKey: PropTypes.number,
  placeholder: PropTypes.string,
  editorValue: PropTypes.string,
};

export default function Editor({
  id = 'minimal-quill',
  error,
  value,
  onChange,
  simple = false,
  helperText,
  sx,
  editorKey,
  placeholder,
  ...other
}) {
  const modules = {
    toolbar: {
      container: `#${id}`,
    },
    history: {
      delay: 500,
      maxStack: 100,
      userOnly: true,
    },
    syntax: true,
    clipboard: {
      matchVisual: false,
    },
  };

  const editorRef = useRef(null);
  const containerRef = React.useRef(null);

  const handleAddPredefinedHtml = useCallback(() => {
    const quillEditor = editorRef.current.getEditor();
    quillEditor.clipboard.dangerouslyPasteHTML(other.editorValue);
  }, [other.editorValue]);
  React.useEffect(() => {
    if (other.editorValue && other.editorValue.length > 0) {
      handleAddPredefinedHtml();
    }
  }, [other.editorValue, handleAddPredefinedHtml]);
  const [hasOverflow, setHasOverflow] = React.useState(false);

  React.useEffect(() => {
    const editor = editorRef.current;
    const container = containerRef.current;

    if (editor && container) {
      setHasOverflow(editor.scrollHeight > container.clientHeight);
    }
  }, [value, containerRef, editorRef]);

  return (
    <>
      <StyledEditor
        ref={containerRef}
        style={{ overflowY: hasOverflow ? 'scroll' : 'auto' }}
        sx={{
          ...(error && {
            border: (theme) => `solid 1px ${theme.palette.error.main}`,
          }),
          ...sx,
        }}
      >
        <EditorToolbar id={id} isSimple={simple} />

        <div style={{ whiteSpace: 'pre-wrap' }}>
          <ReactQuill
            key={editorKey}
            ref={editorRef}
            onChange={onChange}
            onPaste={(event) => {
              event.preventDefault();
            }}
            modules={modules}
            formats={formats}
            style={{ wordBreak: 'break-word' }}
            placeholder={placeholder || 'Scrie..'}
            {...other}
          />
        </div>
      </StyledEditor>

      {helperText && helperText}
    </>
  );
}
