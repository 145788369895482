// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import SvgColor from '../../../components/svg-color';
import Iconify from '../../../components/iconify/Iconify';
import routePermissions from '../../../routes/permissions';

// ----------------------------------------------------------------------

const icon = (name, color) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1, color }} />
);

const ICONS = {
  blog: icon('ic_blog'),
  cart: icon('ic_cart'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock', '#BE3144'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder', '#AE445A'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
  tickets: icon('ic_tickets', '#D864A9'),
  technicalBook: icon('ic_technicalBook', '#557C55'),
  inventory: icon('ic_inventory', '#219C90'),
  voice: icon('ic_chat', 'royalblue'),
};

const navConfig = [
  // ----------------------------------------------------------------------
  {
    subheader: 'Secțiuni',
    items: [
      // USER
      // {
      //   title: 'user',
      //   path: PATH_DASHBOARD.user.root,
      //   icon: ICONS.user,
      //   children: [
      //     { title: 'profile', path: PATH_DASHBOARD.user.profile },
      //     { title: 'cards', path: PATH_DASHBOARD.user.cards },
      //     { title: 'list', path: PATH_DASHBOARD.user.list },
      //     { title: 'create', path: PATH_DASHBOARD.user.new },
      //     { title: 'edit', path: PATH_DASHBOARD.user.demoEdit },
      //     { title: 'account', path: PATH_DASHBOARD.user.account },
      //   ],
      // },

      // TICKETS
      {
        title: 'Solicitari',
        path: PATH_DASHBOARD.tickets.root,
        icon: ICONS.tickets,
        children: [
          {
            title: 'listă',
            path: PATH_DASHBOARD.tickets.list,
            roles: routePermissions.tickets.list.roles,
          },
          // { title: 'detalii', path: PATH_DASHBOARD.tickets.demoView },
          {
            title: 'creare',
            path: PATH_DASHBOARD.tickets.new,
            roles: routePermissions.tickets.new.roles,
          },
          // { title: 'editare', path: PATH_DASHBOARD.tickets.demoEdit },
        ],
        roles: routePermissions.tickets.roles,
      },

      {
        title: 'Call Center',
        path: PATH_DASHBOARD.voice.root,
        icon: ICONS.voice,
        children: [
          {
            title: 'status general',
            path: PATH_DASHBOARD.voice.general,
            roles: routePermissions.voice.general.roles,
          },
          {
            title: 'date cozi',
            path: PATH_DASHBOARD.voice.queues,
            roles: routePermissions.voice.queues.roles,
          },
          {
            title: 'Rapoarte Apeluri',
            path: PATH_DASHBOARD.voice.callReports,
            roles: routePermissions.voice.callReports.roles,
          },
          {
            title: 'Trafic Apeluri',
            path: PATH_DASHBOARD.voice.callTraffic,
            roles: routePermissions.voice.callReports.roles,
          },
        ],
        roles: routePermissions.voice.roles,
      },

      // CARTE TEHNICA
      // {
      //   title: 'carte tehnica',
      //   path: PATH_DASHBOARD.book.root,
      //   icon: ICONS.technicalBook,
      //   children: [
      //     {
      //       title: 'Creare',
      //       path: PATH_DASHBOARD.book.new,
      //       roles: routePermissions.book.new.roles,
      //     },
      //     {
      //       title: 'Lista',
      //       path: PATH_DASHBOARD.book.list,
      //       roles: routePermissions.book.list.roles,
      //     },
      //   ],
      //   roles: routePermissions.book.roles,
      // },
      {
        title: 'Panou Admin',
        path: PATH_DASHBOARD.admin.root,
        icon: ICONS.lock,
        children: [
          // {
          //   title: 'Date generale',
          //   path: PATH_DASHBOARD.admin.root,
          //   roles: routePermissions.admin.general.roles,
          // },
          {
            title: 'Utilizatori',
            path: PATH_DASHBOARD.admin.accounts.root,
            children: [
              {
                title: 'Adauga User',
                path: PATH_DASHBOARD.admin.accounts.new,
                roles: routePermissions.admin.accounts.new.roles,
              },
              {
                title: 'Statistici Useri',
                path: PATH_DASHBOARD.admin.accounts.list,
                roles: routePermissions.admin.accounts.list.roles,
              },
            ],
            roles: routePermissions.admin.accounts.roles,
          },
          {
            title: 'companii',
            path: PATH_DASHBOARD.admin.companies.root,
            children: [
              {
                title: 'adauga',
                path: PATH_DASHBOARD.admin.companies.new,
                roles: routePermissions.admin.companies.new.roles,
              },
              {
                title: 'companii inregistrate',
                path: PATH_DASHBOARD.admin.companies.list,
                roles: routePermissions.admin.companies.list.roles,
              },
            ],
            roles: routePermissions.admin.companies.roles,
          },
          {
            title: 'proiecte',
            path: PATH_DASHBOARD.admin.projects.root,
            children: [
              {
                title: 'adauga',
                path: PATH_DASHBOARD.admin.projects.new,
                roles: routePermissions.admin.projects.new.roles,
              },
              {
                title: 'Configurare Email',
                path: PATH_DASHBOARD.admin.projects.list,
                roles: routePermissions.admin.projects.list.roles,
              },
            ],
            roles: routePermissions.admin.projects.roles,
          },
          // {
          //   title: 'Predefinite',
          //   path: PATH_DASHBOARD.admin.predefined.root,
          //   children: [
          //     // { title: 'Depozit', path: PATH_DASHBOARD.admin.predefined.values },
          //     {
          //       title: 'Tabele Selecturi',
          //       path: PATH_DASHBOARD.admin.predefined.selects,
          //       roles: routePermissions.admin.predefined.selects.roles,
          //     },
          //   ],
          //   roles: routePermissions.admin.predefined.roles,
          // },
        ],
        roles: routePermissions.admin.roles,
      },

      // INVENTORY
      // {
      //   title: 'Depozit',
      //   icon: ICONS.inventory,
      //   path: PATH_DASHBOARD.inventory.root,
      //   children: [
      //     {
      //       title: 'Lista',
      //       path: PATH_DASHBOARD.inventory.list,
      //       roles: routePermissions.inventory.list.roles,
      //     },
      //     {
      //       title: 'Cereri materiale',
      //       path: PATH_DASHBOARD.inventory.teamOrders,
      //       roles: routePermissions.inventory.teamOrders.roles,
      //     },
      //   ],
      //   roles: routePermissions.inventory.roles,
      // },

      // MANAGER FISIERE
      {
        title: 'Manager fisiere',
        path: PATH_DASHBOARD.fileManager,
        icon: ICONS.folder,
        roles: routePermissions.filesManager.roles,
      },
    ],
  },
];

export default navConfig;
