export const companii = [
  {
    id: 1,
    name: 'S.C. MASTER GAZ S.R.L.',
    address: 'Bragadiru, str. Alunului, nr.19, jud. IF',
    cui: 'RO24787807',
    comert: 'J23/4891/2020',
    iban: 'RO24 RZBR 0000 0600 1727 9250',
    bank: 'RAIFFEISEN BANK',
    iban2: 'RO28 BRDE 410S V935 5672 4100',
    bank2: 'BRD',
    iban3: 'RO98 TREZ 4215 069X XX01 6184',
    bank3: 'TREZORERIA STATULUI',
    authorization: '21263/21261',
    capital: '200 RON',
    website: 'www.mastergaz.ro',
    email: 'office@mastergaz.ro',
    phone: '021 9176',
    logo: 'https://infraspaces.ams3.cdn.digitaloceanspaces.com/masterGaz.png',
    administrator: 'Iancu Marian Emil',
  },
  {
    id: 2,
    name: 'S.C. MASTER CAPITAL S.R.L.',
    address: 'Bragadiru, str. Alunului, nr.19, jud. IF',
    cui: 'RO43193797',
    comert: 'J23/4835/2020',
    iban: 'RO66 RZBR 0000 0600 2212 7815',
    bank: 'RAIFFEISEN BANK',
    phone: '021 9176',
    logo: 'https://infraspaces.ams3.cdn.digitaloceanspaces.com/masteriCapital-removebg-preview.png',
    administrator: 'Iancu Marian Emil',
  },
];
