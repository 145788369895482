import React, { useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import PhoneEnabledIcon from '@mui/icons-material/PhoneEnabled';

import { useNavigate } from 'react-router';

AgentConnectedDialog.propTypes = {
  newCallData: PropTypes.object,
  onClose: PropTypes.func,
  isOpen: PropTypes.bool,
};

export default function AgentConnectedDialog({ newCallData, onClose, isOpen }) {
  const navigate = useNavigate();
  const redirectToCreateTicket = () => {
    onClose();
    navigate('/dashboard/tickets/new', {
      state: { client: newCallData?.client, callId: newCallData?.callId },
    });
  };

  return (
    <Dialog fullWidth open={isOpen} onClose={onClose}>
      <DialogTitle sx={{ display: 'flex', alignItems: 'center', fontStyle: 'italic' }}>
        Informatii apel curent <PhoneEnabledIcon sx={{ ml: 1 }} />
      </DialogTitle>
      <DialogContent>
        <Typography variant="h6" sx={{ mb: 2 }}>
          {newCallData?.client ? 'Detalii client:' : 'Clientul nu a fost gasit'}
        </Typography>
        {newCallData?.client && (
          <Box sx={{ mb: 2 }}>
            <Typography>Nume: {newCallData?.client.firstName}</Typography>
            <Typography>Prenume: {newCallData?.client.lastName}</Typography>
            <Typography>Email: {newCallData?.client.email}</Typography>
            <Typography>Telefon: {newCallData?.client.phoneNumber}</Typography>
          </Box>
        )}

        <Typography variant="h6" sx={{ mb: 2 }}>
          Compania apelata:
        </Typography>
        <Box sx={{ mb: 2 }}>
          <Typography>Nume: {newCallData?.company.companyName}</Typography>
        </Box>

        {Object.entries(newCallData.openedTickets).length > 0 && (
          <Box>
            <Typography variant="h6" sx={{ mb: 2 }}>
              Tickete deja deschise pentru acest client:
            </Typography>
            <Table sx={{ border: 0 }}>
              <TableBody>
                {newCallData?.openedTickets &&
                  Object.entries(newCallData.openedTickets).map(([department, tickets], index) => (
                    <React.Fragment key={index}>
                      <TableRow>
                        <TableCell sx={{ border: 0 }} colSpan={2}>
                          <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center' }}>
                            <ArrowRightIcon />
                            {department}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      {tickets.map((ticket, ticketIndex) => (
                        <TableRow
                          key={ticketIndex}
                          sx={{
                            borderBottom: '1px solid grey',
                            ':hover': {
                              backgroundColor: 'lightgrey',
                              cursor: 'pointer',
                            },
                          }}
                          onClick={() =>
                            navigate(`/dashboard/tickets/${department}/${ticket.number}`)
                          }
                        >
                          <TableCell sx={{ border: 0 }}>
                            <Typography>{ticket.title}</Typography>
                          </TableCell>
                        </TableRow>
                      ))}
                    </React.Fragment>
                  ))}
              </TableBody>
            </Table>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={() => redirectToCreateTicket()} color="primary">
          Catre solicitare noua
        </Button>
        <Button variant="contained" onClick={() => onClose()} color="warning">
          Inchide
        </Button>
      </DialogActions>
    </Dialog>
  );
}
