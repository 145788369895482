import React from 'react';
import PropTypes from 'prop-types';
import { getAuthenticatedUserFromCookies } from '../utils/cookies';

RoleBasedGuard.propTypes = {
  children: PropTypes.node,
  roles: PropTypes.arrayOf(PropTypes.string),
};

export default function RoleBasedGuard({ roles, children }) {
  const user = getAuthenticatedUserFromCookies();
  const userRoles = user.roles.map((role) => role.replace('ROLE_', '').toLowerCase());
  roles = roles.map((role) => role.toLowerCase());

  const checkChildRoles = (childElements) =>
    React.Children.map(childElements, (child) => {
      if (!React.isValidElement(child)) return child;

      const { roles: childRoles } = child.props;

      if (Array.isArray(childRoles) && childRoles.length === 0) return child;

      if (childRoles && !childRoles.some((role) => userRoles.includes(role) || role === 'all'))
        return null;

      if (child.props.children) {
        child = React.cloneElement(child, {
          children: checkChildRoles(child.props.children),
        });
      }

      return child;
    });

  if (
    (roles && roles.some((role) => userRoles.includes(role) || role === 'all')) ||
    (Array.isArray(roles) && roles.length === 0)
  ) {
    return checkChildRoles(children);
  }

  return null;
}
