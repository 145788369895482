// routes
import { PATH_DASHBOARD } from './routes/paths';

// API
// export const BASE_URL = 'http://localhost:5500';
// export const MAIL_READER_URL = 'http://localhost:3010';
export const BASE_URL = 'https://backend.infragroup.ro:5500';
export const MAIL_READER_URL = 'https://backend.infragroup.ro:3010';
// ----------------------------------------------------------------------
export const HOST_API_KEY = process.env.REACT_APP_HOST_API_KEY || '';
export const TVA = 19;

export const infraspacesPath =
  'https://infraspaces.ams3.cdn.digitaloceanspaces.com/Mastergaz/Documente';

export const folderNames = [
  '1. Acte client',
  '2. Contract mandat',
  '3. Oferte(Calculatii pret)',
  '4. Contract Executie',
  '5. Facturi - Note de plata',
  '6. Dovada Plata',
  '7. Documente OSD',
  '8. Proiectare',
  '9. Avize si autorizatii',
  '10. Documente executie',
  '11. Documente materiale',
  '12. Carte Tehnica',
  '13. Receptie - Dovada plata finala',
  '14. Poze/Video Lucrare',
];

export const PATH_TO_GENERATE = 'Mastergaz/Documente';

export const comercialVanzariDocuments = [
  'Contract de Mandat.docx',
  'Draft Contract de Racordare.docx',
  'Draft Contract Instalatie de Utilizare.docx',
];

export const proiectantInstalatieDocuments = [
  'Memoriu conducta.docx',
  'Proces Verbal de lucrari ascunse.docx',
  'Receptie.docx',
];

export const tehnicDocuments = ['Ordin de serviciu.docx'];

export const fileManagerPath = 'Infraweb_crm/Fisiere';

export const FIREBASE_API = {
  apiKey: 'AIzaSyDFagxA4ZHgFkCCr4OuDWBIw5sbK7uIPvw',
  authDomain: 'lilly-auth.firebaseapp.com',
  projectId: 'lilly-auth',
  storageBucket: 'lilly-auth.appspot.com',
  messagingSenderId: '542631656345',
  appId: '1:542631656345:web:7ad26a6c7e9e21cba9b108',
  measurementId: 'G-MGG6L75MR4',
};

export const COGNITO_API = {
  userPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
  clientId: process.env.REACT_APP_AWS_COGNITO_CLIENT_ID,
};

export const AUTH0_API = {
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
};

export const MAP_API = process.env.REACT_APP_MAPBOX_API;

// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN = PATH_DASHBOARD.tickets.list; // as '/dashboard/app'

// LAYOUT
// ----------------------------------------------------------------------

export const HEADER = {
  H_MOBILE: 64,
  H_MAIN_DESKTOP: 88,
  H_DASHBOARD_DESKTOP: 92,
  H_DASHBOARD_DESKTOP_OFFSET: 92 - 32,
};

export const NAV = {
  W_BASE: 260,
  W_DASHBOARD: 280,
  W_DASHBOARD_MINI: 88,
  //
  H_DASHBOARD_ITEM: 48,
  H_DASHBOARD_ITEM_SUB: 36,
  //
  H_DASHBOARD_ITEM_HORIZONTAL: 32,
};

export const ICON = {
  NAV_ITEM: 24,
  NAV_ITEM_HORIZONTAL: 22,
  NAV_ITEM_MINI: 22,
};
