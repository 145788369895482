import { arSA, enUS, frFR, roRO, viVN, zhCN } from '@mui/material/locale';

export const allLangs = [
  {
    label: 'English',
    value: 'en',
    systemValue: enUS,
    icon: '/assets/icons/flags/ic_flag_en.svg',
  },
  {
    label: 'Romanian',
    value: 'ro',
    systemValue: roRO,
    icon: '/assets/icons/flags/ic_flag_ro.svg',
  },
];
export const defaultLang = allLangs[1]; // English
