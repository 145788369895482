import React, { useEffect, useCallback, useMemo, useRef } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import JsSIP from 'jssip';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import { Box, Typography } from '@mui/material';
import { getAuthenticatedUserFromCookies } from '../../utils/cookies';

import { BASE_URL } from '../../config-global';

import VirtualPhone from './VirtualPhone/VirtualPhone';
import {
  changeCallState,
  changeClientName,
  changeDisabledTabs,
  changeNumber,
  changeOnHold,
  changeOnMute,
  changePhoneState,
  changeSession,
  changeUnusable,
} from '../../redux/slices/virtualPhone';

const CallComponent = () => {
  const { outboundAudio, inboundAudio, callState, isConnected, session } = useSelector(
    (state) => state.virtualPhone
  );
  const user = getAuthenticatedUserFromCookies();
  const userAgentRef = useRef(null);

  const remoteAudioRef = useRef(new Audio());

  const dispatch = useDispatch();

  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     dispatch(changeCallSeconds(0));
  //     dispatch(changeCallMinutes(1 + 1));

  //     dispatch(changeCallSeconds(1 + 1));
  //   }, 1000);

  //   return () => {
  //     clearInterval(intervalId);
  //   };
  // }, [dispatch]);

  const cleanupSession = useCallback(() => {
    dispatch(changeSession({}));
    dispatch(changeCallState('formatting'));
    dispatch(changeOnHold(false));
    dispatch(changeOnMute(false));
    dispatch(changeSession({}));
    dispatch(changeClientName(''));
    // dispatch(changeCallSeconds(0));
    // dispatch(changeCallMinutes(0));
    dispatch(changeDisabledTabs(false));
  }, [dispatch]);

  // JsSIP.debug.enable('JsSIP:*');

  const handleFailedCall = useCallback(async (callNumber) => {
    const cookies = getAuthenticatedUserFromCookies();
    const token = cookies.accessToken;
    axios
      .post(
        `${BASE_URL}/callcenter/call/failed`,
        {
          callNumber,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((response) => {})
      .catch((e) => console.log(e));
  }, []);

  function formatDuration(durationMillis) {
    const totalSeconds = Math.floor(durationMillis / 1000);

    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;

    const formatted = `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;

    return formatted;
  }
  const handleCallHistory = useCallback(async (callNumber, callType, callDuration) => {
    const cookies = getAuthenticatedUserFromCookies();
    const token = cookies.accessToken;
    axios
      .post(
        `${BASE_URL}/callcenter/call/history`,
        {
          callNumber,
          callType,
          callDuration: callDuration !== null ? formatDuration(callDuration) : '',
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((response) => {})
      .catch((e) => console.log(e));
  }, []);

  const extractHostWithoutPort = (webSocketURL) => {
    try {
      const url = new URL(webSocketURL);
      return url.hostname;
    } catch (e) {
      console.error('Invalid URL:', e);
      return null;
    }
  };

  const isValidWebSocketURL = (url) => {
    const regex = /^wss:\/\/([a-zA-Z0-9.-]+)(:\d+)?(\/\S*)?$/;

    return regex.test(url);
  };

  const isValidSIPURL = (url) => {
    const regex = /^sip:\S+@([a-zA-Z0-9.-]+)$/;
    return regex.test(url);
  };

  const isValidURL = useCallback((url) => {
    if (url && url.startsWith('wss://')) {
      return isValidWebSocketURL(url);
    }
    if (url && url.startsWith('sip:')) {
      return isValidSIPURL(url);
    }
    return false;
  }, []);

  useEffect(() => {
    if (callState === 'calling') {
      outboundAudio.play();
    } else if (callState === 'inbound') {
      inboundAudio.volume = 0.3;
      inboundAudio.play();
    } else {
      outboundAudio.pause();
      outboundAudio.currentTime = 0;
      inboundAudio.pause();
      inboundAudio.currentTime = 0;
    }
  }, [callState, outboundAudio, inboundAudio]);

  // const userAgent = useCallback(() => {
  //   const config = {
  //     sockets: [new JsSIP.WebSocketInterface(`${user.callcenterHost}`)],
  //     uri: `sip:${user.agentExtension}@${extractHostWithoutPort(user.callcenterHost)}`,
  //     authorization_user: `${user.agentExtension}`,
  //     password: `${user.agentExtensionPassword}`,
  //   };

  //   return new JsSIP.UA(config);
  // }, [user.agentExtension, user.agentExtensionPassword, user.callcenterHost]);

  const handleCall = useCallback(
    (event) => {
      const incomingSession = event.session;

      const { direction } = incomingSession;
      if (direction === 'incoming') {
        dispatch(changeDisabledTabs(true));
        dispatch(changeCallState('inbound'));
      }
      const number = incomingSession.remote_identity.uri.user;
      dispatch(changeNumber(number));

      dispatch(changePhoneState('formatNumber'));

      let callStartTimestamp;
      incomingSession.on('accepted', () => {
        callStartTimestamp = Date.now();
        dispatch(changeDisabledTabs(false));
        dispatch(changeCallState('incall'));
        const remoteStream = new MediaStream(
          incomingSession.connection.getReceivers().map((r) => r.track)
        );
        remoteAudioRef.current.srcObject = remoteStream;
        remoteAudioRef.current.play();
      });
      incomingSession.on('ended', () => {
        const callEndTimestamp = Date.now();
        const callDuration = callEndTimestamp - callStartTimestamp; // in milliseconds
        if (direction === 'incoming') {
          handleCallHistory(incomingSession.remote_identity.uri.user, 'inbound', callDuration);
        } else {
          handleCallHistory(number, 'outbound', callDuration);
        }
        cleanupSession();
      });
      incomingSession.on('failed', () => {
        if (direction === 'incoming') {
          handleFailedCall(incomingSession.remote_identity.uri.user);
        } else {
          handleCallHistory(number, 'outbound', null);
        }

        cleanupSession();
      });
      dispatch(changeNumber(incomingSession.remote_identity.uri.user));
      dispatch(changeSession(incomingSession));
    },
    [dispatch, cleanupSession, handleFailedCall, handleCallHistory]
  );

  const makeCall = (target) => {
    // Get local media stream (audio and optional video)
    navigator.mediaDevices
      .getUserMedia({ audio: true, video: false })
      .then((localStream) => {
        // Options for the call
        const options = {
          mediaStream: localStream,
          eventHandlers: {
            // Handle remote media streams when they become available
            // accepted: (e) => {
            //   dispatch(changeCallState('incall'));
            // },
            // failed: (e) => {
            //   console.error('Call failed', e);
            //   cleanupSession();
            // },
            // ended: (e) => {
            //   console.log('Call ended', e);
            //   cleanupSession();
            // },
          },
        };

        // Make the call
        const outgoingSession = userAgentRef.current.call(
          `sip:${target}@${extractHostWithoutPort(user.agentData.callcenterHost)}`,
          options
        );
        dispatch(changeSession(outgoingSession));
      })
      .catch((error) => {
        console.error('Failed to access local media', error);
      });
  };

  const sendMessage = (target, content) => {
    if (!userAgentRef.current || typeof userAgentRef.current.sendMessage !== 'function') {
      console.error('User Agent is not initialized or does not support sending messages.');
      return;
    }

    const messageSession = userAgentRef.current.sendMessage(
      `sip:${target}@${extractHostWithoutPort(user.callcenterHost)}`,
      content
    );

    messageSession.on('succeeded', () => {
      console.log('Message sent successfully.');
    });

    messageSession.on('failed', (response) => {
      console.error(
        'Failed to send the message:',
        response ? response.reason_phrase : 'Unknown Error'
      );
    });
  };

  const handleMessage = (data) => {
    const { originator, message } = data;

    if (originator === 'remote') {
      console.log(`Received message from: ${message.remote_identity.uri.toString()}`);
      console.log(`Message content: ${message.body}`);
    }
  };

  // Handle incoming calls
  useEffect(() => {
    const currentAudio = remoteAudioRef.current;
    let setupIsCorrect = false;
    // if (user.agentData !== null) {
    setupIsCorrect =
      isValidURL(user.agentData?.callcenterHost) &&
      isValidURL(
        `sip:${user.agentData.extension}@${extractHostWithoutPort(user.agentData?.callcenterHost)}`
      );
    if (isConnected && setupIsCorrect) {
      const config = {
        sockets: [new JsSIP.WebSocketInterface(`${user.agentData.callcenterHost}`)],
        uri: `sip:${user.agentData.extension}@${extractHostWithoutPort(
          user.agentData?.callcenterHost
        )}`,
        authorization_user: `${user.agentData.extension}`,
        password: `${user.agentData.agentExtensionPassword}`,
      };

      userAgentRef.current = new JsSIP.UA(config);

      userAgentRef.current.on('newRTCSession', handleCall);
      userAgentRef.current.on('newMessage', handleMessage);

      userAgentRef.current.start();
    } else if (isConnected && !setupIsCorrect) dispatch(changeUnusable(true));
    // } else {
    //   dispatch(changeUnusable(true));
    // }

    return () => {
      if (isConnected && setupIsCorrect) {
        if (currentAudio) {
          currentAudio.pause();
          currentAudio.src = '';
          currentAudio.srcObject = null;
        }
        userAgentRef.current.off('newRTCSession', handleCall);
        userAgentRef.current.off('newMessage', handleMessage);
        userAgentRef.current.stop();
      }
    };
  }, [
    userAgentRef,
    handleCall,
    dispatch,
    isValidURL,
    isConnected,
    user?.agentData?.callcenterHost,
    user?.agentData?.extension,
    user?.agentData?.agentExtensionPassword,
  ]);

  return (
    <Box>
      <VirtualPhone makeCall={makeCall} callState={callState} sendMessage={sendMessage} />
    </Box>
  );
};

export default CallComponent;
