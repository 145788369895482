import PropTypes from 'prop-types';
import { noCase } from 'change-case';
import { useCallback, useEffect, useState, forwardRef } from 'react';
// @mui
import {
  Box,
  Stack,
  List,
  Badge,
  Button,
  Avatar,
  Tooltip,
  Divider,
  IconButton,
  Typography,
  ListItemText,
  ListSubheader,
  ListItemAvatar,
  ListItemButton,
  SnackbarContent,
  AlertTitle,
  Alert,
  Slide,
  Dialog,
  AppBar,
  Toolbar,
  ListItem,
} from '@mui/material';
import axios from 'axios';

import Cookies from 'js-cookie';
import { useNavigate } from 'react-router';

import { useSnackbar } from 'notistack';
import { CloseIcon } from '../../../theme/overrides/CustomIcons';

import { BASE_URL } from '../../../config-global';

// utils
import { fToNow } from '../../../utils/formatTime';
// _mock_
import { _notifications } from '../../../_mock/arrays';
// components
import Iconify from '../../../components/iconify';
import Scrollbar from '../../../components/scrollbar';
import MenuPopover from '../../../components/menu-popover';
import { IconButtonAnimate } from '../../../components/animate';
import WebSocketService from '../../../api/WebSocketService';

// ----------------------------------------------------------------------
const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

export default function NotificationsPopover() {
  const [openPopover, setOpenPopover] = useState(null);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [permissionNotification, setPermission] = useState(Notification.permission);
  const [open, setOpen] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const navigate = useNavigate();
  const totalUnRead = notifications.filter((item) => item.isUnRead === true).length;

  const authenticatedUser = Cookies.get('authenticatedUser');
  const cookies = authenticatedUser ? JSON.parse(authenticatedUser) : null;
  const token = cookies?.accessToken;
  const username = cookies?.email;
  const userId = cookies?.id;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const getNotifications = useCallback(async () => {
    try {
      const response = await axios.get(`${BASE_URL}/user/notifications`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const transform = response.data.map((n) => ({
        title: n.title,
        description: n.description,
        type: n.type,
        createdAt: new Date(n.sentDate),
        category: n.category,
      }));
      setNotifications(transform);
    } catch (e) {
      console.log(e);
    }
  }, [token]);

  const handleOpenPopover = (event) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  const handleMarkAllAsRead = () => {
    setNotifications(
      notifications.map((notification) => ({
        ...notification,
        isUnRead: false,
      }))
    );
  };

  const handlePermission = () => {
    Notification.requestPermission().then((permission) => {
      setPermission(permission);
    });
  };

  useEffect(() => {
    const handleNotification = (notification) => {
      setNotifications([
        {
          id: (notifications.length + 1).toString(),
          title: notification.title,
          ticketNumber: notification.ticketNumber,
          description: notification.description,
          category: notification.category,
          avatar: null,
          department: notification.department,
          type: 'chat_message',
          createdAt: new Date(notification.createdAt),
          isUnRead: true,
        },
        ...notifications,
      ]);
      if (permissionNotification === 'granted') {
        const notificationn = new Notification(notification.title, {
          body: notification.description,
        });
      } else {
        console.log('No permission for desktop notifications.');
      }
      if (!openPopover) {
        enqueueSnackbar(notification.title, {
          content: (key, message) => (
            <Alert
              severity="info"
              onClose={() => {
                closeSnackbar(key);
              }}
            >
              {notification.category === 'ticket' ? (
                <AlertTitle>
                  A fost adaugata o noua solicitare - {notification.department}
                </AlertTitle>
              ) : (
                <AlertTitle>Aveti o notificare noua!</AlertTitle>
              )}
              {notification.category === 'ticket' ? (
                ''
              ) : (
                <>
                  <strong>{message}</strong>: {notification.description}
                </>
              )}
            </Alert>
          ),
          anchorOrigin: { vertical: 'right', horizontal: 'top' },
          autoHideDuration: 10000,
        });
      }
    };
    handlePermission();

    const notificationWebSocket = new WebSocketService('notifications', handleNotification);
    notificationWebSocket.connect();
    return () => {
      notificationWebSocket.disconnect();
    };
  }, [
    username,
    notifications,
    enqueueSnackbar,
    closeSnackbar,
    openPopover,
    permissionNotification,
  ]);

  useEffect(() => {
    getNotifications();
  }, [getNotifications]);

  const getDepartmentName = useCallback(
    async (ticket) =>
      axios
        .get(`${BASE_URL}/ticket/department?ticket=${ticket}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((r) => r.data)
        .catch((e) => {
          throw e;
        }),
    [token]
  );

  const getTicketLink = (ticketNumber, department) =>
    `/dashboard/tickets/${department}/${ticketNumber}`;

  return (
    <>
      <IconButtonAnimate
        color={openPopover ? 'primary' : 'default'}
        onClick={handleOpenPopover}
        sx={{ width: 40, height: 40 }}
      >
        <Badge badgeContent={totalUnRead} color="error">
          <Iconify icon="eva:bell-fill" />
        </Badge>
      </IconButtonAnimate>

      <MenuPopover open={openPopover} onClose={handleClosePopover} sx={{ width: 360, p: 0 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">Notificari</Typography>

            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {/* You have {totalUnRead} unread messages */}
              Aveti {totalUnRead} notificari noi
            </Typography>
          </Box>

          {totalUnRead > 0 && (
            <Tooltip title=" Mark all as read">
              <IconButton color="primary" onClick={handleMarkAllAsRead}>
                <Iconify icon="eva:done-all-fill" />
              </IconButton>
            </Tooltip>
          )}
        </Box>
        <Divider sx={{ borderStyle: 'dashed' }} />

        <Scrollbar sx={{ height: { xs: 340, sm: 'auto' } }}>
          {/* <Typography
            variant="subtitle1"
            sx={{ color: 'red', textAlign: 'center', paddingY: '50px' }}
          >
            Sistem inactiv momentan
          </Typography> */}

          {notifications.length > 0 && (
            <>
              <List
                disablePadding
                subheader={
                  <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
                    Noi
                  </ListSubheader>
                }
              >
                {notifications.slice(0, 2).map((notification) => (
                  <NotificationItem
                    onClick={() => {
                      if (notification.category === 'ticket') {
                        navigate(getTicketLink(notification.ticketNumber, notification.department));
                        handleClosePopover();
                      }
                    }}
                    key={Math.random()}
                    notification={notification}
                  />
                ))}
              </List>

              <List
                disablePadding
                subheader={
                  <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
                    Istoric
                  </ListSubheader>
                }
              >
                {notifications.slice(2, 5).map((notification) => (
                  <NotificationItem
                    onClick={() => {
                      if (notification.category === 'ticket') {
                        navigate(getTicketLink(notification.title));
                        handleClosePopover();
                      }
                    }}
                    key={Math.random()}
                    notification={notification}
                  />
                ))}
              </List>
            </>
          )}
        </Scrollbar>

        {notifications.length > 0 && (
          <>
            <Divider sx={{ borderStyle: 'dashed' }} />

            <Box sx={{ p: 1 }}>
              <Button fullWidth disableRipple onClick={handleClickOpen}>
                Vezi tot
              </Button>
            </Box>
          </>
        )}
      </MenuPopover>
      <div>
        <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
          <AppBar sx={{ position: 'relative' }}>
            <Toolbar>
              <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                Notificari
              </Typography>
            </Toolbar>
          </AppBar>
          <List
            disablePadding
            subheader={
              <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
                Noi
              </ListSubheader>
            }
          >
            {notifications.map((notification) => (
              <NotificationItem
                onClick={() => {
                  if (notification.category === 'ticket') {
                    navigate(getTicketLink(notification.ticketNumber));
                  }
                  handleClose();
                }}
                key={Math.random()}
                notification={notification}
              />
            ))}
          </List>
        </Dialog>
      </div>
    </>
  );
}

// ----------------------------------------------------------------------

NotificationItem.propTypes = {
  notification: PropTypes.shape({
    id: PropTypes.string,
    avatar: PropTypes.node,
    type: PropTypes.string,
    title: PropTypes.string,
    isUnRead: PropTypes.bool,
    description: PropTypes.string,
    sentDate: PropTypes.instanceOf(Date),
  }),
  onClick: PropTypes.func,
};

function NotificationItem({ notification, onClick }) {
  const { avatar, title } = renderContent(notification);

  return (
    <ListItemButton
      onClick={onClick}
      sx={{
        py: 1.5,
        px: 2.5,
        mt: '1px',
        ...(notification.isUnRead && {
          bgcolor: 'action.selected',
        }),
      }}
    >
      <ListItemAvatar>
        <Avatar sx={{ bgcolor: 'background.neutral' }}>{avatar}</Avatar>
      </ListItemAvatar>

      <ListItemText
        disableTypography
        primary={title}
        secondary={
          <Stack direction="row" sx={{ mt: 0.5, typography: 'caption', color: 'text.disabled' }}>
            <Iconify icon="eva:clock-fill" width={16} sx={{ mr: 0.5 }} />
            <Typography variant="caption">{fToNow(notification.sentDate)}</Typography>
          </Stack>
        }
      />
    </ListItemButton>
  );
}

// ----------------------------------------------------------------------

function renderContent(notification) {
  const title = (
    <Typography variant="subtitle2">
      {notification.title}
      <Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
        &nbsp; {noCase(notification.description)}
      </Typography>
    </Typography>
  );

  if (notification.type === 'order_placed') {
    return {
      avatar: <img alt={notification.title} src="/assets/icons/notification/ic_package.svg" />,
      title,
    };
  }
  if (notification.type === 'order_shipped') {
    return {
      avatar: <img alt={notification.title} src="/assets/icons/notification/ic_shipping.svg" />,
      title,
    };
  }
  if (notification.type === 'mail') {
    return {
      avatar: <img alt={notification.title} src="/assets/icons/notification/ic_mail.svg" />,
      title,
    };
  }
  if (notification.type === 'chat_message') {
    return {
      avatar: <img alt={notification.title} src="/assets/icons/notification/ic_chat.svg" />,
      title,
    };
  }
  return {
    avatar: notification.avatar ? <img alt={notification.title} src={notification.avatar} /> : null,
    title,
  };
}
