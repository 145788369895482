import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from 'axios';
import Cookies from 'js-cookie';
import { BASE_URL } from '../../config-global';
import { getAuthenticatedUserFromCookies } from '../../utils/cookies';
// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  tables: [],
};

const slice = createSlice({
  name: 'selectTables',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET TABLES
    setTables(state, action) {
      state.tables = action.payload;
    },

    // ADD TABLE
    addTable(state, action) {
      state.isLoading = false;
      state.tables.push(action.payload);
    },

    // ADD TABLE ROW
    addTableRow(state, action) {
      const { tableId, row } = action.payload;
      const table = state.tables.find((item) => item.id === tableId);
      table.tableRows.push({ ...row });
    },

    // DELETE TABLE ROW
    deleteTableRow(state, action) {
      const { tableId, rowIdentifier } = action.payload;

      // Find the table
      const tableIndex = state.tables.findIndex((item) => item.id === tableId);
      if (tableIndex === -1) return;

      // Find the row within the table
      const table = state.tables[tableIndex];
      const rowIndex = table.tableRows.findIndex((item) => item.rowIdentifier === rowIdentifier);
      if (rowIndex === -1) return;

      // Create a new table rows array without the deleted row
      const newTableRows = [
        ...table.tableRows.slice(0, rowIndex),
        ...table.tableRows.slice(rowIndex + 1),
      ];

      // Update the table with the new table rows array
      state.tables[tableIndex] = {
        ...table,
        tableRows: newTableRows,
      };
    },

    // EDIT TABLE ROW
    editTableRow(state, action) {
      const { tableId, rowIdentifier, newRowValue, secondRowValue } = action.payload;

      // Find the table index
      const tableIndex = state.tables.findIndex((item) => item.id === tableId);
      if (tableIndex === -1) return;

      // Find the row index within the table
      const table = state.tables[tableIndex];
      const rowIndex = table.tableRows.findIndex((item) => item.rowIdentifier === rowIdentifier);
      if (rowIndex === -1) return;

      // Update the row value
      table.tableRows[rowIndex].rowValue = newRowValue;
      table.tableRows[rowIndex].rowSecondValue = secondRowValue;
    },

    // DELETE TABLE
    deleteTable(state, action) {
      state.isLoading = false;
      const { tableId } = action.payload;
      const tableIndex = state.tables.findIndex((item) => item.id === tableId);
      if (tableIndex === -1) return;

      // Create a new tables array without the deleted table
      const newTables = [
        ...state.tables.slice(0, tableIndex),
        ...state.tables.slice(tableIndex + 1),
      ];

      // Update the state with the new tables array
      state.tables = newTables;
    },

    // IMPORT DATA FROM EXCEL
    importData(state, action) {
      const { tableId, data } = action.payload;

      // Find the table index
      const tableIndex = state.tables.findIndex((item) => item.id === tableId);
      if (tableIndex === -1) return;

      // Find the row index within the table
      const table = state.tables[tableIndex];
      table.tableRows = data;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getReduxTables() {
  return async (dispatch) => {
    const cookies = getAuthenticatedUserFromCookies();
    const token = cookies.accessToken;
    const request = await axios
      .get(`${BASE_URL}/custom/table`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        dispatch(slice.actions.setTables(response.data));
        return response;
      })
      .catch((error) => error);

    return request;
  };
}

// ----------------------------------------------------------------------

export function addReduxTable(newTableName) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    const cookies = getAuthenticatedUserFromCookies();
    const token = cookies.accessToken;
    const request = await axios
      .post(
        `${BASE_URL}/custom/table`,
        { tableName: newTableName },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        dispatch(slice.actions.addTable(response.data));
        return response;
      })
      .catch((error) => error);

    return request;
  };
}

// ----------------------------------------------------------------------

export function addTableRow(tableId, newRowValue, newRowSecondValue) {
  return async (dispatch) => {
    const cookies = getAuthenticatedUserFromCookies();
    const token = cookies.accessToken;
    const request = await axios
      .post(
        `${BASE_URL}/custom/table/row`,
        {
          tableId,
          rowValue: newRowValue,
          rowSecondValue: newRowSecondValue,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        dispatch(slice.actions.addTableRow({ tableId, row: response.data }));
        return response;
      })
      .catch((error) => error);

    return request;
  };
}

// ----------------------------------------------------------------------

export function deleteTableRow(tableId, rowIdentifier) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading(true));
    const cookies = getAuthenticatedUserFromCookies();
    const token = cookies.accessToken;
    const request = await axios
      .delete(`${BASE_URL}/custom/table/row`, {
        data: {
          tableId,
          rowIdentifier,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        dispatch(slice.actions.deleteTableRow({ tableId, rowIdentifier }));
        return response;
      })
      .catch((error) => error);

    return request;
  };
}

// ----------------------------------------------------------------------

export function editTableRow(tableId, rowIdentifier, newRowValue, secondRowValue) {
  return async (dispatch) => {
    const cookies = getAuthenticatedUserFromCookies();
    const token = cookies.accessToken;
    const request = await axios
      .put(
        `${BASE_URL}/custom/table/row`,
        {
          tableId,
          rowIdentifier,
          rowValue: newRowValue,
          rowSecondValue: secondRowValue,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        dispatch(
          slice.actions.editTableRow({ tableId, rowIdentifier, newRowValue, secondRowValue })
        );
        return response;
      })
      .catch((error) => error);

    return request;
  };
}

// ----------------------------------------------------------------------

export function deleteReduxTable(tableId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading(true));
    const cookies = getAuthenticatedUserFromCookies();
    const token = cookies.accessToken;
    const request = await axios
      .delete(`${BASE_URL}/custom/table/${tableId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        dispatch(slice.actions.deleteTable({ tableId }));
        return response;
      })
      .catch((error) => error);

    return request;
  };
}

// ----------------------------------------------------------------------

export function importExcelRedux(tableId, array) {
  return async (dispatch) => {
    const cookies = getAuthenticatedUserFromCookies();
    const { token } = cookies;

    const object = {
      tableId,
      data: array,
    };

    const res = axios
      .post(`${BASE_URL}/custom/table/import`, object, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        dispatch(slice.actions.importData({ tableId, data: response.data }));
        return response;
      })
      .catch((error) => error);

    return res;
  };
}
