import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import PhoneIcon from '@mui/icons-material/Phone';
import HomeIcon from '@mui/icons-material/Home';
import HistoryIcon from '@mui/icons-material/History';
import AppSettingsAltIcon from '@mui/icons-material/AppSettingsAlt';
import DialpadIcon from '@mui/icons-material/Dialpad';
import CommentIcon from '@mui/icons-material/Comment';
import './VirtualPhoneMenuCSSOverwrites.css';
import { useDispatch, useSelector } from 'react-redux';
import { changePhoneState, changeSelectedTab } from '../../../redux/slices/virtualPhone';

export default function VirtualPhoneMenu() {
  const { selectedTab, phoneState, disabledTabs } = useSelector((state) => state.virtualPhone);
  const dispatch = useDispatch();

  const handleChange = (event, newValue) => {
    if (!disabledTabs) {
      dispatch(changeSelectedTab(newValue));
    }
  };

  const tabs = useMemo(
    () => [
      { label: 'Acasă', icon: <HomeIcon sx={{ color: 'black' }} />, view: 'home' },
      { label: 'Recente', icon: <HistoryIcon sx={{ color: 'black' }} />, view: 'history' },
      { label: 'Sună', icon: <DialpadIcon sx={{ color: 'black' }} />, view: 'formatNumber' },
      // { label: 'Mesaje', icon: <CommentIcon />, view: 'messages' },
      { label: 'Setări', icon: <AppSettingsAltIcon sx={{ color: 'black' }} />, view: 'settings' },
    ],
    []
  );

  useEffect(() => {
    for (let i = 0; i < tabs.length; i += 1) {
      if (tabs[i].view === phoneState) {
        dispatch(changeSelectedTab(i));
      }
    }
  }, [phoneState, dispatch, tabs]);

  return (
    <Box
      sx={{
        zIndex: 1000,
        width: '100%',
        height: '70px',
        display: 'flex',
        backgroundColor: '#96a8ae',
        '@media (min-width: 600px)': {
          margin: 0,
        },
      }}
    >
      {/* <Box sx={{ height: '7px', backgroundColor: '#3366ff' }} /> */}
      <Tabs
        scrollButtons={false}
        value={selectedTab}
        onChange={handleChange}
        aria-label="phone-tabs"
        sx={{
          flexGrow: 1,
          '& .MuiTabs-flexContainer': {
            gap: 0,
            justifyContent: 'space-between',
          },
          '& .MuiTabs-indicator': {
            display: 'none',
          },
        }}
      >
        {tabs.map((tab, index) => (
          <Tab
            onClick={() => !disabledTabs && dispatch(changePhoneState(tab.view))}
            key={index}
            sx={{
              flexGrow: 1, //#7ac9bf
              backgroundColor: selectedTab === index ? '#7aeee2' : '#96a8ae',
              margin: 0,
              padding: 0,
              minWidth: 0,
              fontSize: '15px',
              '@media (min-width: 600px)': {
                margin: 0,
              },
            }}
            icon={tab.icon}
            iconPosition="top"
            label={<p style={{ color: 'black', padding: 0, margin: 0 }}>{tab.label}</p>}
          />
        ))}
      </Tabs>
    </Box>
  );
}
