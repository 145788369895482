import { Navigate, useRoutes } from 'react-router-dom';
// auth
import AuthGuard from '../auth/AuthGuard';
import GuestGuard from '../auth/GuestGuard';
// layouts
import MainLayout from '../layouts/main';
import CompactLayout from '../layouts/compact';
import DashboardLayout from '../layouts/dashboard';
// config
//
import {
  FileManagerPage,
  // KanbanPage,
  LoginPage,
  RegisterPage,
  ResetPasswordPage,
  NewPasswordPage,
  VerifyCodePage,
  TicketsCreatePage,
  TicketsDetailsPage,
  TicketsListPage,
  TicketsEditPage,
  // custom modules
  CallCenterPage,
  CallCenterDetailedQueuesStatsPage,
  CallReportsPage,
  CallTrafficPage,
  GeneralAppPage,
  CustomModulesPage,
  PredefinedPage,
  CustomSelectValuesPage,
  CompanyListPage,
  AddCompanyPage,
  AddAccountPage,
  AddedAccountsListPage,
  ProjectsPage,
  ProjectsEditPage,
} from './elements';
import ProtectedChildrenRoute from './ProtectedChildrenRoute';
import ProtectedParentRoute from './ProtectedParentRoute';
import routePermissions from './permissions';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    // Auth
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <LoginPage />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <RegisterPage />
            </GuestGuard>
          ),
        },
        { path: 'login-unprotected', element: <LoginPage /> },
        { path: 'register-unprotected', element: <RegisterPage /> },
        {
          element: <CompactLayout />,
          children: [
            { path: 'reset-password', element: <ResetPasswordPage /> },
            { path: 'new-password', element: <NewPasswordPage /> },
            { path: 'verify', element: <VerifyCodePage /> },
          ],
        },
      ],
    },

    // Dashboard
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: 'tickets',
          element: <ProtectedParentRoute roles={routePermissions.tickets.roles} />,
          children: [
            { element: <Navigate to="/dashboard/tickets/list" replace />, index: true },
            {
              path: 'list',
              element: (
                <ProtectedChildrenRoute roles={routePermissions.tickets.list.roles}>
                  <TicketsListPage />
                </ProtectedChildrenRoute>
              ),
            },
            {
              path: ':departmentName/:id',
              element: (
                <ProtectedChildrenRoute roles={routePermissions.tickets.departmentName.id.roles}>
                  <TicketsDetailsPage />
                </ProtectedChildrenRoute>
              ),
            },
            {
              path: ':id/edit',
              element: (
                <ProtectedChildrenRoute roles={routePermissions.tickets.id.edit.roles}>
                  <TicketsEditPage />
                </ProtectedChildrenRoute>
              ),
            },
            {
              path: 'new',
              element: (
                <ProtectedChildrenRoute roles={routePermissions.tickets.new.roles}>
                  <TicketsCreatePage />
                </ProtectedChildrenRoute>
              ),
            },
          ],
        },
        {
          path: 'voice',
          element: <ProtectedParentRoute roles={routePermissions.voice.roles} />,
          children: [
            {
              element: <Navigate to="/dashboard/voice" replace />,
              index: true,
            },
            {
              path: 'general',
              element: (
                <ProtectedChildrenRoute roles={routePermissions.voice.general.roles}>
                  <CallCenterPage />
                </ProtectedChildrenRoute>
              ),
            },
            {
              path: 'queues',
              element: (
                <ProtectedChildrenRoute roles={routePermissions.voice.queues.roles}>
                  <CallCenterDetailedQueuesStatsPage />
                </ProtectedChildrenRoute>
              ),
            },
            {
              path: 'call-reports',
              element: (
                <ProtectedChildrenRoute roles={routePermissions.voice.callReports.roles}>
                  <CallReportsPage />
                </ProtectedChildrenRoute>
              ),
            },
            {
              path: 'call-traffic',
              element: (
                <ProtectedChildrenRoute roles={routePermissions.voice.callReports.roles}>
                  <CallTrafficPage />
                </ProtectedChildrenRoute>
              ),
            },
          ],
        },

        {
          path: 'admin',
          element: <ProtectedParentRoute roles={routePermissions.admin.roles} />,
          children: [
            {
              path: '',
              element: (
                <ProtectedChildrenRoute roles={routePermissions.admin.general.roles}>
                  <GeneralAppPage />
                </ProtectedChildrenRoute>
              ),
            },
            {
              path: 'companies',
              element: <ProtectedParentRoute roles={routePermissions.admin.companies.roles} />,
              children: [
                { element: <Navigate to="/dashboard/admin/companies" replace />, index: true },
                {
                  path: 'list',
                  element: (
                    <ProtectedChildrenRoute roles={routePermissions.admin.companies.list.roles}>
                      <CompanyListPage />
                    </ProtectedChildrenRoute>
                  ),
                },
                {
                  path: 'new',
                  element: (
                    <ProtectedChildrenRoute roles={routePermissions.admin.companies.new.roles}>
                      <AddCompanyPage />
                    </ProtectedChildrenRoute>
                  ),
                },
              ],
            },
            {
              path: 'projects',
              element: <ProtectedParentRoute roles={routePermissions.admin.projects.roles} />,
              children: [
                { element: <Navigate to="/dashboard/admin/projects" replace />, index: true },
                {
                  path: 'list',
                  element: (
                    <ProtectedChildrenRoute roles={routePermissions.admin.projects.list.roles}>
                      <ProjectsPage />
                    </ProtectedChildrenRoute>
                  ),
                },
                {
                  path: ':name/edit',
                  element: (
                    <ProtectedChildrenRoute roles={routePermissions.admin.projects.edit.roles}>
                      <ProjectsEditPage />
                    </ProtectedChildrenRoute>
                  ),
                },
                {
                  path: 'new',
                  element: (
                    <ProtectedChildrenRoute roles={routePermissions.admin.projects.new.roles}>
                      <h2>In Lucru</h2>
                    </ProtectedChildrenRoute>
                  ),
                },
              ],
            },
            {
              path: 'accounts',
              element: <ProtectedParentRoute roles={routePermissions.admin.accounts.roles} />,

              children: [
                { element: <Navigate to="/dashboard/admin/accounts" replace />, index: true },
                {
                  path: 'new',
                  element: (
                    <ProtectedChildrenRoute roles={routePermissions.admin.accounts.new.roles}>
                      <AddAccountPage />
                    </ProtectedChildrenRoute>
                  ),
                },
                {
                  path: 'list',
                  element: (
                    <ProtectedChildrenRoute roles={routePermissions.admin.accounts.list.roles}>
                      <AddedAccountsListPage />
                    </ProtectedChildrenRoute>
                  ),
                },
              ],
            },
            {
              path: 'custom',
              element: <ProtectedParentRoute roles={routePermissions.admin.custom.roles} />,

              children: [
                { element: <Navigate to="/dashboard/admin/custom" replace />, index: true },
                {
                  path: 'modules',
                  element: (
                    <ProtectedChildrenRoute roles={routePermissions.admin.accounts.list.roles}>
                      <CustomModulesPage />
                    </ProtectedChildrenRoute>
                  ),
                },
                // { path: 'added', element: <AddedAccountsListPage /> },
              ],
            },
            {
              path: 'predefined',
              element: <ProtectedParentRoute roles={routePermissions.admin.predefined.roles} />,

              children: [
                { element: <Navigate to="/dashboard/admin/predefined" replace />, index: true },
                {
                  path: 'values',
                  element: (
                    <ProtectedChildrenRoute roles={routePermissions.admin.predefined.values.roles}>
                      <PredefinedPage />
                    </ProtectedChildrenRoute>
                  ),
                },
                {
                  path: 'selects',
                  element: (
                    <ProtectedChildrenRoute roles={routePermissions.admin.predefined.selects.roles}>
                      <CustomSelectValuesPage />
                    </ProtectedChildrenRoute>
                  ),
                },
              ],
            },
          ],
        },
        // {
        //   path: 'book',
        //   element: <ProtectedParentRoute roles={routePermissions.book.roles} />,
        //   children: [
        //     { element: <Navigate to="/dashboard/book" replace />, index: true },
        //     {
        //       path: 'list',
        //       element: (
        //         <ProtectedChildrenRoute roles={routePermissions.book.list.roles}>
        //           <TechnicalBooksListPage />
        //         </ProtectedChildrenRoute>
        //       ),
        //     },
        //     {
        //       path: 'new',
        //       element: (
        //         <ProtectedChildrenRoute roles={routePermissions.book.new.roles}>
        //           <NewTechnicalBook />
        //         </ProtectedChildrenRoute>
        //       ),
        //     },
        //     // { path: 'list', element: <AddedAccountsListPage /> },
        //     {
        //       path: ':id/edit',
        //       element: (
        //         <ProtectedChildrenRoute roles={routePermissions.book.id.edit.roles}>
        //           <EditTechnicalBook />
        //         </ProtectedChildrenRoute>
        //       ),
        //     },
        //   ],
        // },
        // {
        //   path: 'inventory',
        //   element: <ProtectedParentRoute roles={routePermissions.inventory.roles} />,
        //   children: [
        //     { element: <Navigate to="/dashboard/inventory/list" replace />, index: true },
        //     {
        //       path: 'list',
        //       element: (
        //         <ProtectedChildrenRoute roles={routePermissions.inventory.list.roles}>
        //           <InventoryListPage />
        //         </ProtectedChildrenRoute>
        //       ),
        //     },
        //     {
        //       path: 'team-orders',
        //       element: (
        //         <ProtectedChildrenRoute roles={routePermissions.inventory.teamOrders.roles}>
        //           <TeamOrdersPage />
        //         </ProtectedChildrenRoute>
        //       ),
        //     },
        //   ],
        // },
        // {
        //   path: 'clients',
        //   element: <ProtectedParentRoute roles={routePermissions.clients.roles} />,

        //   children: [
        //     { element: <Navigate to="/dashboard/clients/list" replace />, index: true },
        //     {
        //       path: 'list',
        //       element: (
        //         <ProtectedChildrenRoute roles={routePermissions.clients.list.roles}>
        //           <ClientsListPage />
        //         </ProtectedChildrenRoute>
        //       ),
        //     },
        //     {
        //       path: 'add',
        //       element: (
        //         <ProtectedChildrenRoute roles={routePermissions.clients.add.roles}>
        //           <AddClientPage />
        //         </ProtectedChildrenRoute>
        //       ),
        //     },
        //     {
        //       path: ':id/edit',
        //       element: (
        //         <ProtectedChildrenRoute roles={routePermissions.clients.id.edit.roles}>
        //           <ClientEditPage />
        //         </ProtectedChildrenRoute>
        //       ),
        //     },
        //   ],
        // },
        {
          path: 'files-manager',
          element: (
            <ProtectedParentRoute
              route={<FileManagerPage />}
              roles={routePermissions.filesManager.roles}
            />
          ),
        },
        // { path: 'app', element: <GeneralAppPage /> },
        // { path: 'ecommerce', element: <GeneralEcommercePage /> },
        // { path: 'analytics', element: <GeneralAnalyticsPage /> },
        // { path: 'banking', element: <GeneralBankingPage /> },
        // { path: 'booking', element: <GeneralBookingPage /> },
        // { path: 'file', element: <GeneralFilePage /> },
        // {
        //   path: 'e-commerce',
        //   children: [
        //     { element: <Navigate to="/dashboard/e-commerce/shop" replace />, index: true },
        //     { path: 'shop', element: <EcommerceShopPage /> },
        //     { path: 'product/:name', element: <EcommerceProductDetailsPage /> },
        //     { path: 'list', element: <EcommerceProductListPage /> },
        //     { path: 'product/new', element: <EcommerceProductCreatePage /> },
        //     { path: 'product/:name/edit', element: <EcommerceProductEditPage /> },
        //     { path: 'checkout', element: <EcommerceCheckoutPage /> },
        //   ],
        // },
        // {
        //   path: 'user',
        //   children: [
        //     { element: <Navigate to="/dashboard/user/profile" replace />, index: true },
        //     { path: 'profile', element: <UserProfilePage /> },
        //     { path: 'cards', element: <UserCardsPage /> },
        //     { path: 'list', element: <UserListPage /> },
        //     { path: 'new', element: <UserCreatePage /> },
        //     { path: ':name/edit', element: <UserEditPage /> },
        //     { path: 'account', element: <UserAccountPage /> },
        //   ],
        // },
        // {
        //   path: 'invoice',
        //   children: [
        //     { element: <Navigate to="/dashboard/invoice/list" replace />, index: true },
        //     { path: 'list', element: <InvoiceListPage /> },
        //     { path: ':id', element: <InvoiceDetailsPage /> },
        //     { path: ':id/edit', element: <InvoiceEditPage /> },
        //     { path: 'new', element: <InvoiceCreatePage /> },
        //   ],
        // },
        // {
        //   path: 'blog',
        //   children: [
        //     { element: <Navigate to="/dashboard/blog/posts" replace />, index: true },
        //     { path: 'posts', element: <BlogPostsPage /> },
        //     { path: 'post/:title', element: <BlogPostPage /> },
        //     { path: 'new', element: <BlogNewPostPage /> },
        //   ],
        // },
        // {
        //   path: 'mail',
        //   children: [
        //     { element: <Navigate to="/dashboard/mail/inbox" replace />, index: true },
        //     { path: 'label/:customLabel', element: <MailPage /> },
        //     { path: 'label/:customLabel/:mailId', element: <MailPage /> },
        //     { path: ':systemLabel', element: <MailPage /> },
        //     { path: ':systemLabel/:mailId', element: <MailPage /> },
        //   ],
        // },
        // {
        //   path: 'chat',
        //   children: [
        //     { element: <ChatPage />, index: true },
        //     { path: 'new', element: <ChatPage /> },
        //     { path: ':conversationKey', element: <ChatPage /> },
        //   ],
        // },
        // { path: 'calendar', element: <CalendarPage /> },
        // { path: 'kanban', element: <KanbanPage /> },
        // { path: 'blank', element: <BlankPage /> },
      ],
    },

    // Main Routes
    {
      element: <MainLayout />,
      children: [
        // { element: <HomePage />, index: true },
        {
          element: (
            <GuestGuard>
              <LoginPage />
            </GuestGuard>
          ),
          index: true,
        },
        // { path: 'about-us', element: <AboutPage /> },
        // { path: 'contact-us', element: <Contact /> },
        // { path: 'faqs', element: <FaqsPage /> },
        // Demo Components
        // {
        //   path: 'components',
        //   children: [
        //     { element: <ComponentsOverviewPage />, index: true },
        //     {
        //       path: 'foundation',
        //       children: [
        //         { element: <Navigate to="/components/foundation/colors" replace />, index: true },
        //         { path: 'colors', element: <FoundationColorsPage /> },
        //         { path: 'typography', element: <FoundationTypographyPage /> },
        //         { path: 'shadows', element: <FoundationShadowsPage /> },
        //         { path: 'grid', element: <FoundationGridPage /> },
        //         { path: 'icons', element: <FoundationIconsPage /> },
        //       ],
        //     },
        //     {
        //       path: 'mui',
        //       children: [
        //         { element: <Navigate to="/components/mui/accordion" replace />, index: true },
        //         { path: 'accordion', element: <MUIAccordionPage /> },
        //         { path: 'alert', element: <MUIAlertPage /> },
        //         { path: 'autocomplete', element: <MUIAutocompletePage /> },
        //         { path: 'avatar', element: <MUIAvatarPage /> },
        //         { path: 'badge', element: <MUIBadgePage /> },
        //         { path: 'breadcrumbs', element: <MUIBreadcrumbsPage /> },
        //         { path: 'buttons', element: <MUIButtonsPage /> },
        //         { path: 'checkbox', element: <MUICheckboxPage /> },
        //         { path: 'chip', element: <MUIChipPage /> },
        //         { path: 'data-grid', element: <MUIDataGridPage /> },
        //         { path: 'dialog', element: <MUIDialogPage /> },
        //         { path: 'list', element: <MUIListPage /> },
        //         { path: 'menu', element: <MUIMenuPage /> },
        //         { path: 'pagination', element: <MUIPaginationPage /> },
        //         { path: 'pickers', element: <MUIPickersPage /> },
        //         { path: 'popover', element: <MUIPopoverPage /> },
        //         { path: 'progress', element: <MUIProgressPage /> },
        //         { path: 'radio-button', element: <MUIRadioButtonsPage /> },
        //         { path: 'rating', element: <MUIRatingPage /> },
        //         { path: 'slider', element: <MUISliderPage /> },
        //         { path: 'stepper', element: <MUIStepperPage /> },
        //         { path: 'switch', element: <MUISwitchPage /> },
        //         { path: 'table', element: <MUITablePage /> },
        //         { path: 'tabs', element: <MUITabsPage /> },
        //         { path: 'textfield', element: <MUITextFieldPage /> },
        //         { path: 'timeline', element: <MUITimelinePage /> },
        //         { path: 'tooltip', element: <MUITooltipPage /> },
        //         { path: 'transfer-list', element: <MUITransferListPage /> },
        //         { path: 'tree-view', element: <MUITreesViewPage /> },
        //       ],
        //     },
        //     {
        //       path: 'extra',
        //       children: [
        //         { element: <Navigate to="/components/extra/animate" replace />, index: true },
        //         { path: 'animate', element: <DemoAnimatePage /> },
        //         { path: 'carousel', element: <DemoCarouselsPage /> },
        //         { path: 'chart', element: <DemoChartsPage /> },
        //         { path: 'copy-to-clipboard', element: <DemoCopyToClipboardPage /> },
        //         { path: 'editor', element: <DemoEditorPage /> },
        //         { path: 'form-validation', element: <DemoFormValidationPage /> },
        //         { path: 'image', element: <DemoImagePage /> },
        //         { path: 'label', element: <DemoLabelPage /> },
        //         { path: 'lightbox', element: <DemoLightboxPage /> },
        //         { path: 'map', element: <DemoMapPage /> },
        //         { path: 'mega-menu', element: <DemoMegaMenuPage /> },
        //         { path: 'multi-language', element: <DemoMultiLanguagePage /> },
        //         { path: 'navigation-bar', element: <DemoNavigationBarPage /> },
        //         { path: 'organization-chart', element: <DemoOrganizationalChartPage /> },
        //         { path: 'scroll', element: <DemoScrollbarPage /> },
        //         { path: 'snackbar', element: <DemoSnackbarPage /> },
        //         { path: 'text-max-line', element: <DemoTextMaxLinePage /> },
        //         { path: 'upload', element: <DemoUploadPage /> },
        //         { path: 'markdown', element: <DemoMarkdownPage /> },
        //       ],
        //     },
        //   ],
        // },
      ],
    },
    // {
    //   element: <SimpleLayout />,
    //   children: [
    //     { path: 'pricing', element: <PricingPage /> },
    //     { path: 'payment', element: <PaymentPage /> },
    //   ],
    // },
    // {
    //   element: <CompactLayout />,
    //   children: [
    //     { path: 'successfull-payment', element: <SuccessfullPaymentPage /> },
    //     { path: 'coming-soon', element: <ComingSoonPage /> },
    //     { path: 'maintenance', element: <MaintenancePage /> },
    //     { path: '500', element: <Page500 /> },
    //     { path: '404', element: <Page404 /> },
    //     { path: '403', element: <Page403 /> },
    //   ],
    // },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
