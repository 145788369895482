import { createSlice } from '@reduxjs/toolkit';

const callReportsSlice = createSlice({
  name: 'callReports',
  initialState: {
    pageData: [],
    selectedCategory: '',
    selectedInterval: '',
    isFetching: false,
    selectedDateRange: [],
    playRecording: false,
    recordingUrl: {},
  },
  reducers: {
    setSelectedCategory(state, action) {
      state.selectedCategory = action.payload;
    },
    setPageData(state, action) {
      state.pageData = action.payload;
    },
    setIsFetching(state, action) {
      state.isFetching = action.payload;
    },
    setDateRange(state, action) {
      state.selectedDateRange = action.payload;
    },
    setPlayRecording(state, action) {
      state.playRecording = action.payload;
    },
    setRecordingUrl(state, action) {
      state.recordingUrl = action.payload;
    },
    setSelectedInterval(state, action) {
      state.selectedInterval = action.payload;
    },
  },
});

export const {
  setSelectedCategory,
  setPageData,
  setIsFetching,
  setDateRange,
  setPlayRecording,
  setRecordingUrl,
  setSelectedInterval,
} = callReportsSlice.actions;

export default callReportsSlice.reducer;
