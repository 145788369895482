import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { getAuthenticatedUserFromCookies } from '../utils/cookies';

ProtectedChildrenRoute.propTypes = {
  roles: PropTypes.any,
  props: PropTypes.array,
  children: PropTypes.any,
};

function ProtectedChildrenRoute({ roles, ...props }) {
  console.log(roles);
  const user = getAuthenticatedUserFromCookies();
  const userRoles = user.roles.map((role) => role.replace('ROLE_', '').toLowerCase());
  roles = roles.map((role) => role.toLowerCase());

  if (
    (roles && roles.some((role) => userRoles.includes(role) || role === 'all')) ||
    (Array.isArray(roles) && roles.length === 0)
  ) {
    return props.children;
  }

  return <Navigate to="/403" />;
}

export default ProtectedChildrenRoute;
