import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Box, IconButton, Typography, keyframes } from '@mui/material';

import MicOffIcon from '@mui/icons-material/MicOff';
import PhonePausedIcon from '@mui/icons-material/PhonePaused';
import CallEndIcon from '@mui/icons-material/CallEnd';
import {
  changeCallState,
  changeOnHold,
  changeOnMute,
} from '../../../../../redux/slices/virtualPhone';

import InCallTimer from './InCallTimer';

const pulseAnimation = keyframes`
  0% {
    background-color: lightgreen;

  }
  50% {
    background-color: #e4e5e7;
  }
  100% {
    background-color: lightgreen;

  }
`;

const InCallHeader = () => {
  const { session, clientName, number, onHold, onMute, phoneState } = useSelector(
    (state) => state.virtualPhone
  );
  const dispatch = useDispatch();

  const endCall = () => {
    session.terminate();
    dispatch(changeCallState('formatting'));
  };

  const isClosed = phoneState === 'closed';

  const holdCall = () => {
    if (!onHold) {
      dispatch(changeOnHold(true));
      if (onMute) {
        muteCall();
      }
      session.hold();
    } else {
      dispatch(changeOnHold(false));
      session.unhold();
    }
  };

  const muteCall = () => {
    if (!onMute && !onHold) {
      dispatch(changeOnMute(true));
      session.mute();
    } else {
      dispatch(changeOnMute(false));
      session.unmute();
    }
  };

  return (
    <Box
      sx={{
        height: isClosed ? '50px' : '60px',
        p: '10px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: 'lightgreen',
        animation: `${pulseAnimation} 2s infinite`,
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
        borderRadius: isClosed ? '50px' : '0',
        width: isClosed ? '300px' : 'auto',
      }}
    >
      <Box>
        <Typography sx={{ color: 'black', fontWeight: 'bold' }}>{clientName || number}</Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          height: '100%',
          justifyContent: 'space-around',
          alignItems: 'center',
          width: isClosed ? '50%' : '40%',
        }}
      >
        {/* <InCallTimer /> */}
        <IconButton sx={{ color: 'black', backgroundColor: 'coral' }} onClick={endCall}>
          <CallEndIcon />
        </IconButton>
        <IconButton
          sx={{
            color: 'black',
            backgroundColor: onHold ? 'orange' : 'darkgrey',
          }}
          onClick={holdCall}
        >
          <PhonePausedIcon />
        </IconButton>
        <IconButton
          sx={{ color: 'black', backgroundColor: onMute ? 'orange' : 'darkgrey' }}
          onClick={muteCall}
        >
          <MicOffIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

InCallHeader.propTypes = {};

export default InCallHeader;
