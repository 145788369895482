import axios from 'axios';
// cookies
import Cookies from 'js-cookie';
//
import { isValidToken } from '../auth/utils';
// config
import { HOST_API_KEY, BASE_URL } from '../config-global';
import { getAuthenticatedUserFromCookies } from './cookies';

// ----------------------------------------------------------------------

const cookies = Cookies.get('authenticatedUser')
  ? JSON.parse(Cookies.get('authenticatedUser'))
  : '';
const token = cookies !== '' ? cookies.accessToken : '';
const valid = isValidToken(token);

const emailAxiosInstance = axios.create({
  baseURL: `${BASE_URL}/mail`,
  headers: {
    Authorization: valid ? `Bearer ${token}` : undefined,
  },
});

export default emailAxiosInstance;

// ----------------------------------------------------------------------

export const axiosInstance = (tkn, port, endPoint, method) => {
  const call = axios.create({
    baseURL: `${BASE_URL}:${port}/${endPoint}`,
    headers: {
      Authorization: `Bearer ${tkn}`,
    },
    method,
  });

  return call;
};

// ----------------------------------------------------------------------

// Define your custom fetcher function with Axios and authorization headers
export const fetcher = async (url) => {
  const cook = getAuthenticatedUserFromCookies();
  const tkn = cook.accessToken;
  const response = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${tkn}`, // Replace 'token' with your actual authorization token
    },
  });
  return response.data;
};
