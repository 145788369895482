import { Client } from '@stomp/stompjs';
import SockJS from 'sockjs-client';
import { getAuthenticatedUserFromCookies } from '../utils/cookies';
import { BASE_URL } from '../config-global';

class WebSocketService {
  constructor(route, onMessageReceivedCallback) {
    const cookies = getAuthenticatedUserFromCookies();
    const token = cookies.accessToken;
    this.username = cookies.username;
    this.route = route;
    this.onMessageReceivedCallback = onMessageReceivedCallback;
    this.client = new Client({
      webSocketFactory: () => new SockJS(`${BASE_URL}/ws?token=${token}`),
      onConnect: () => this.onConnect(),
      onDisconnect: () => this.onDisconnect(),
    });
  }

  onConnect() {
    console.log(`Connected to WebSocket on route: ${this.route}`);
    if (this.connectionType === 'topic') {
      this.subscribeToTopic();
    } else {
      this.subscribeToPrivate();
    }
  }

  onDisconnect() {
    console.log(`Disconnected from WebSocket on route: ${this.route}`);
  }

  subscribeToTopic() {
    this.client.subscribe(`/topic/${this.route}`, (message) => {
      if (this.onMessageReceivedCallback) {
        this.onMessageReceivedCallback(JSON.parse(message.body));
      }
    });
    console.log(`Subscribed to topic on route: ${this.route}`);
  }

  subscribeToPrivate() {
    this.client.subscribe(`/user/${this.username}/${this.route}`, (message) => {
      if (this.onMessageReceivedCallback) {
        this.onMessageReceivedCallback(JSON.parse(message.body));
      }
    });
    console.log(`Subscribed to private channel on route: ${this.route}`);
  }

  connect(connectionType = 'private') {
    this.connectionType = connectionType;
    this.client.activate();
  }

  disconnect() {
    this.client.deactivate();
  }
}

export default WebSocketService;
