import * as React from "react"

const SearchIllustration = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
    <defs>
      <clipPath id="a">
        <path
          d="M134.33 262.14s4 9.38 4.84 11.21 1.64 2.33 1.45 2.91-2.23 1.25-2.23 1.25a16.11 16.11 0 0 1-1.45 8.42c-2 4-5.9 6.09-6.28 8.6a46.18 46.18 0 0 0-.1 7.26l-5.9-4.84s1.64-4.74.19-8.22a13.23 13.23 0 0 0-6.19-6.57 16.18 16.18 0 0 1-6.86-9.77c-.87-4.65 1.83-9.58 5.41-13.06s10.64-2.8 11.42-2.51-1.94-2 .48-2.52 3.87 3 4 3.58.87-2.51 1.65-.48-.43 4.74-.43 4.74Z"
          style={{
            fill: "#fff",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: ".75px",
          }}
        />
      </clipPath>
      <clipPath id="b">
        <path
          d="M122.53 294.05s6.87 4.16 10.06 9.38 5.9 13 7.45 19.83 2.8 24.66 3.77 28.34a23.69 23.69 0 0 0 2.13 5.61 46.66 46.66 0 0 1-17.51 2.9c-9.48-.39-10.93-2.71-10.93-2.71s-3.19-21.08-2.9-37.82 7.93-25.53 7.93-25.53Z"
          style={{
            fill: "#fff",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: ".75px",
          }}
        />
      </clipPath>
      <clipPath id="c">
        <path
          d="M120.5 308.66s-2.51.67-1.64 7.73 7.93 30.85 10.44 33.76 27-13.35 27-13.35l-3-9.29s-16.54 6.48-17.89 6.77-5.23-20.79-6.58-23.4"
          style={{
            fill: "#fff",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: ".75px",
          }}
        />
      </clipPath>
    </defs>
    <path
      d="M97 158.13c-15.77 21.2-25.91 52.35-18.22 78.44 13.14 44.59 68.9 47.95 107.09 53.11 26.32 3.56 52.49 24.25 75 37.09 30 17.12 78.26 44.13 112.86 26.59 28.68-14.53 44.1-49.68 52.9-78.84 13.79-45.66 15.12-100.74-14.9-140.52C370.87 80 299.39 89 240 98.24c-39.75 6.19-78.39 12.22-113.73 33.29A101.28 101.28 0 0 0 97 158.13Z"
      style={{
        fill: "#92e3a9",
      }}
    />
    <path
      d="M97 158.13c-15.77 21.2-25.91 52.35-18.22 78.44 13.14 44.59 68.9 47.95 107.09 53.11 26.32 3.56 52.49 24.25 75 37.09 30 17.12 78.26 44.13 112.86 26.59 28.68-14.53 44.1-49.68 52.9-78.84 13.79-45.66 15.12-100.74-14.9-140.52C370.87 80 299.39 89 240 98.24c-39.75 6.19-78.39 12.22-113.73 33.29A101.28 101.28 0 0 0 97 158.13Z"
      style={{
        fill: "#fff",
        opacity: 0.4,
      }}
    />
    <path
      d="M50.94 412.89c0 3.56 91.31 6.45 204 6.45s204-2.89 204-6.45-91.31-6.45-203.95-6.45-204.05 2.89-204.05 6.45Z"
      style={{
        fill: "#92e3a9",
      }}
    />
    <path
      d="M50.94 412.89c0 3.56 91.31 6.45 204 6.45s204-2.89 204-6.45-91.31-6.45-203.95-6.45-204.05 2.89-204.05 6.45Z"
      style={{
        fill: "#fff",
        opacity: 0.6000000000000001,
      }}
    />
    <path
      d="M96.81 93.4h181.15v229.78H96.81z"
      style={{
        fill: "#fff",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeWidth: ".75px",
      }}
    />
    <path
      d="M96.81 93.4h181.15v13.71H96.81z"
      style={{
        fill: "#263238",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeWidth: ".75px",
      }}
    />
    <path
      d="M101.97 253.66h30.62v64.99h-30.62z"
      style={{
        fill: "#92e3a9",
      }}
    />
    <path
      d="M101.97 253.66h30.62v64.99h-30.62z"
      style={{
        fill: "#fff",
        opacity: 0.6000000000000001,
      }}
    />
    <path
      d="M135.36 253.66h30.62v64.99h-30.62z"
      style={{
        fill: "#92e3a9",
      }}
    />
    <path
      d="M135.36 253.66h30.62v64.99h-30.62z"
      style={{
        fill: "#fff",
        opacity: 0.6000000000000001,
      }}
    />
    <path
      d="M170.84 253.66h30.62v64.99h-30.62z"
      style={{
        fill: "#92e3a9",
      }}
    />
    <path
      d="M170.84 253.66h30.62v64.99h-30.62z"
      style={{
        fill: "#fff",
        opacity: 0.6000000000000001,
      }}
    />
    <path
      d="M206.31 253.66h30.62v64.99h-30.62z"
      style={{
        fill: "#92e3a9",
      }}
    />
    <path
      d="M206.31 253.66h30.62v64.99h-30.62z"
      style={{
        fill: "#fff",
        opacity: 0.6000000000000001,
      }}
    />
    <path
      d="M242.65 253.66h30.62v64.99h-30.62z"
      style={{
        fill: "#fff",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeWidth: ".75px",
      }}
    />
    <path
      d="M101.98 98.82h170.8v15.75h-170.8z"
      style={{
        fill: "#92e3a9",
      }}
    />
    <path
      d="M101.98 98.82h170.8v15.75h-170.8z"
      style={{
        fill: "#fff",
        opacity: 0.6000000000000001,
      }}
    />
    <path
      d="M105.05 101.88h165.33v6.97H105.05z"
      style={{
        fill: "#fff",
      }}
    />
    <path
      d="M251.65 101.88h18.72v6.97h-18.72z"
      style={{
        fill: "#263238",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeWidth: ".75px",
      }}
    />
    <path
      d="M178 128.69h-35a1.35 1.35 0 0 1 0-2.7h35a1.35 1.35 0 0 1 0 2.7ZM178 138.1h-35a1.35 1.35 0 1 1 0-2.69h35a1.35 1.35 0 0 1 0 2.69ZM178 147.52h-35a1.35 1.35 0 1 1 0-2.7h35a1.35 1.35 0 0 1 0 2.7ZM178 156.93h-72.64a1.35 1.35 0 0 1 0-2.69H178a1.35 1.35 0 0 1 0 2.69ZM178 166.35h-72.64a1.35 1.35 0 0 1 0-2.7H178a1.35 1.35 0 0 1 0 2.7ZM178 175.77h-72.64a1.35 1.35 0 0 1 0-2.7H178a1.35 1.35 0 0 1 0 2.7ZM178 185.18h-72.64a1.35 1.35 0 0 1 0-2.69H178a1.35 1.35 0 0 1 0 2.69ZM178 193.55h-72.64a1.35 1.35 0 0 1 0-2.69H178a1.35 1.35 0 0 1 0 2.69ZM178 203h-72.64a1.35 1.35 0 0 1 0-2.7H178a1.35 1.35 0 0 1 0 2.7ZM178 212.38h-72.64a1.35 1.35 0 0 1 0-2.69H178a1.35 1.35 0 0 1 0 2.69ZM178 221.8h-72.64a1.35 1.35 0 0 1 0-2.7H178a1.35 1.35 0 0 1 0 2.7ZM178 231.21h-72.64a1.35 1.35 0 0 1 0-2.69H178a1.35 1.35 0 0 1 0 2.69ZM178 240.63h-72.64a1.35 1.35 0 0 1 0-2.69H178a1.35 1.35 0 0 1 0 2.69ZM271.5 128.69h-72.61a1.35 1.35 0 0 1 0-2.7h72.61a1.35 1.35 0 1 1 0 2.7ZM271.5 138.1h-72.61a1.35 1.35 0 1 1 0-2.69h72.61a1.35 1.35 0 1 1 0 2.69ZM271.5 147.52h-72.61a1.35 1.35 0 1 1 0-2.7h72.61a1.35 1.35 0 1 1 0 2.7ZM271.5 156.93h-72.61a1.35 1.35 0 1 1 0-2.69h72.61a1.35 1.35 0 1 1 0 2.69ZM271.5 166.35h-72.61a1.35 1.35 0 0 1 0-2.7h72.61a1.35 1.35 0 1 1 0 2.7ZM271.5 175.77h-72.61a1.35 1.35 0 1 1 0-2.7h72.61a1.35 1.35 0 1 1 0 2.7ZM271.5 185.18h-72.61a1.35 1.35 0 1 1 0-2.69h72.61a1.35 1.35 0 1 1 0 2.69Z"
      style={{
        fill: "#263238",
      }}
    />
    <path
      d="M198.81 191.51h74v51.61h-74z"
      style={{
        fill: "#92e3a9",
      }}
    />
    <path
      d="M198.81 191.51h74v51.61h-74z"
      style={{
        fill: "#fff",
        opacity: 0.6000000000000001,
      }}
    />
    <path
      d="M130.68 131.36a6.45 6.45 0 0 0 .32-1.94c0-2.41-2.44-2.55-4.85-2.55h-.6v-.57H136v.57c-2.72 0-3.68 1.73-4.56 4.32l-5.56 16.66c-.42 1.23-.77 2.15-2.08 2.15h-.11l-4.91-15.91-4.6 13.76c-.43 1.23-.78 2.15-2.09 2.15H112l-7.14-23.13h-2.51v-.57h9.19v.57h-2.61l5.3 18.25h.21l4-12.17-1.87-6.08h-2.51v-.57h9.19v.57h-2.62l5.31 18.25h.21Z"
      style={{
        fill: "#263238",
      }}
    />
    <path
      d="M223.25 120.4h75.42v202.31h-75.42z"
      style={{
        fill: "#92e3a9",
      }}
    />
    <path
      d="M223.25 120.4h75.42v202.31h-75.42z"
      style={{
        fill: "#fff",
        opacity: 0.4,
      }}
    />
    <path
      d="M228.89 112.83h181.15v229.78H228.89z"
      style={{
        fill: "#fff",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeWidth: ".75px",
      }}
    />
    <path
      d="M228.89 112.83h181.15v13.71H228.89z"
      style={{
        fill: "#263238",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeWidth: ".75px",
      }}
    />
    <path
      d="M289.91 145.37h63.61v118.91h-63.61z"
      style={{
        fill: "#92e3a9",
      }}
    />
    <path
      d="M289.91 145.37h63.61v118.91h-63.61z"
      style={{
        fill: "#fff",
        opacity: 0.6000000000000001,
      }}
    />
    <path
      d="M234.07 273.08h170.8v64.99h-170.8z"
      style={{
        fill: "#92e3a9",
      }}
    />
    <path
      d="M234.07 273.08h170.8v64.99h-170.8z"
      style={{
        fill: "#fff",
        opacity: 0.6000000000000001,
      }}
    />
    <path
      d="M234.07 118.25h170.8V134h-170.8z"
      style={{
        fill: "#92e3a9",
      }}
    />
    <path
      d="M234.07 118.25h170.8V134h-170.8z"
      style={{
        fill: "#fff",
        opacity: 0.6000000000000001,
      }}
    />
    <path
      d="M237.14 121.31h165.33v6.97H237.14z"
      style={{
        fill: "#fff",
      }}
    />
    <path
      d="M383.74 121.31h18.72v6.97h-18.72z"
      style={{
        fill: "#263238",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeWidth: ".75px",
      }}
    />
    <path
      d="M279.22 148.11h-43.59a1.35 1.35 0 1 1 0-2.69h43.59a1.35 1.35 0 1 1 0 2.69ZM279.22 157.53h-43.59a1.35 1.35 0 0 1 0-2.7h43.59a1.35 1.35 0 0 1 0 2.7ZM279.22 166.94h-43.59a1.35 1.35 0 1 1 0-2.69h43.59a1.35 1.35 0 1 1 0 2.69ZM279.22 176.36h-43.59a1.35 1.35 0 1 1 0-2.7h43.59a1.35 1.35 0 0 1 0 2.7ZM279.22 185.77h-43.59a1.35 1.35 0 1 1 0-2.69h43.59a1.35 1.35 0 1 1 0 2.69ZM279.22 195.19h-43.59a1.35 1.35 0 1 1 0-2.69h43.59a1.35 1.35 0 1 1 0 2.69ZM279.22 204.61h-43.59a1.35 1.35 0 1 1 0-2.7h43.59a1.35 1.35 0 0 1 0 2.7ZM279.22 213h-43.59a1.35 1.35 0 0 1 0-2.7h43.59a1.35 1.35 0 0 1 0 2.7ZM279.22 222.39h-43.59a1.35 1.35 0 1 1 0-2.69h43.59a1.35 1.35 0 1 1 0 2.69ZM279.22 231.81h-43.59a1.35 1.35 0 1 1 0-2.7h43.59a1.35 1.35 0 0 1 0 2.7ZM279.22 241.22h-43.59a1.35 1.35 0 1 1 0-2.69h43.59a1.35 1.35 0 1 1 0 2.69ZM279.22 250.64h-43.59a1.35 1.35 0 1 1 0-2.7h43.59a1.35 1.35 0 0 1 0 2.7ZM279.22 260.05h-43.59a1.35 1.35 0 1 1 0-2.69h43.59a1.35 1.35 0 1 1 0 2.69ZM279.22 269.47h-43.59a1.35 1.35 0 1 1 0-2.69h43.59a1.35 1.35 0 1 1 0 2.69ZM404.54 148.11H361a1.35 1.35 0 1 1 0-2.69h43.59a1.35 1.35 0 1 1 0 2.69ZM404.54 157.53H361a1.35 1.35 0 1 1 0-2.7h43.59a1.35 1.35 0 0 1 0 2.7ZM404.54 166.94H361a1.35 1.35 0 1 1 0-2.69h43.59a1.35 1.35 0 1 1 0 2.69ZM404.54 176.36H361a1.35 1.35 0 1 1 0-2.7h43.59a1.35 1.35 0 0 1 0 2.7ZM404.54 185.77H361a1.35 1.35 0 1 1 0-2.69h43.59a1.35 1.35 0 1 1 0 2.69ZM404.54 195.19H361a1.35 1.35 0 1 1 0-2.69h43.59a1.35 1.35 0 1 1 0 2.69ZM404.54 204.61H361a1.35 1.35 0 1 1 0-2.7h43.59a1.35 1.35 0 0 1 0 2.7Z"
      style={{
        fill: "#263238",
      }}
    />
    <path
      d="M359.91 210.93h44.99v51.61h-44.99z"
      style={{
        fill: "#92e3a9",
      }}
    />
    <path
      d="M359.91 210.93h44.99v51.61h-44.99z"
      style={{
        fill: "#fff",
        opacity: 0.6000000000000001,
      }}
    />
    <path
      d="M427.33 304.39c-.27-10.16.52-27.5-8.4-34.64-7.83-6.27-13.92 2.24-16.11 9.1-5.33 16.65-4.76 79.31-3.38 91.93 0 0-1.36-12.64-11.29-7.22s5 33.87 5 33.87-11.74-8.58-17.16-1.36 6.78 15.36 6.78 15.36-11.29-3.16-11.29 0 3.61 5.87 14 7.23h56s9-5 7.23-10.39-11.33-2.27-11.33-2.27 14.45-17.61 13.54-25.29-19.42 1.35-19.42 1.35 14.46-36.58 11.75-52.84c-2.14-12.81-12.08-4-17.49.88a147.51 147.51 0 0 0 1.57-25.71Z"
      style={{
        fill: "#92e3a9",
      }}
    />
    <path
      d="M427.33 304.39c-.27-10.16.52-27.5-8.4-34.64-7.83-6.27-13.92 2.24-16.11 9.1-5.33 16.65-4.76 79.31-3.38 91.93 0 0-1.36-12.64-11.29-7.22s5 33.87 5 33.87-11.74-8.58-17.16-1.36 6.78 15.36 6.78 15.36-11.29-3.16-11.29 0 3.61 5.87 14 7.23h56s9-5 7.23-10.39-11.33-2.27-11.33-2.27 14.45-17.61 13.54-25.29-19.42 1.35-19.42 1.35 14.46-36.58 11.75-52.84c-2.14-12.81-12.08-4-17.49.88a147.51 147.51 0 0 0 1.57-25.71Z"
      style={{
        opacity: 0.1,
      }}
    />
    <path
      d="M409.32 417.45 413.44 294M411.63 366.27l21.23-19.88M421.57 357.23l4.51-12.64M394.02 381.62l15.95 17.01M437.38 391.56l-27.1 14M423.72 398.46l9.14.78"
      style={{
        fill: "none",
        stroke: "#92e3a9",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeWidth: "1.7269500000000002px",
      }}
    />
    <path
      d="M223.59 419.14c0 3.72-35.45 6.74-79.17 6.74s-79.18-3-79.18-6.74 35.45-6.74 79.18-6.74 79.17 3.02 79.17 6.74Z"
      style={{
        fill: "#263238",
      }}
    />
    <path
      d="M105 295.35s6.12-3.1 8.78-.44 1.59 31.12 2.39 40.16a85.7 85.7 0 0 0 2.97 15.93s-8.78-1.59-15.43-5c0 0-5.85-28.4 1.29-50.65Z"
      style={{
        fill: "#263238",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeWidth: ".75px",
      }}
    />
    <path
      d="M226.85 359.8a3.19 3.19 0 0 1-1.41-6.05l28.91-13 22.44-25.81a3.19 3.19 0 1 1 4.88 4.11l-22.87 26.32a3.14 3.14 0 0 1-1 .81l-29.52 13.29a3.06 3.06 0 0 1-1.43.33Z"
      style={{
        fill: "#fff",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeWidth: ".75px",
      }}
    />
    <path
      d="m246.62 348.53 3.17-1.44M232 355.18l12.53-5.7M274.85 323.37l-8.35 9.44M265.15 334.16l-1.89 2.15"
      style={{
        fill: "none",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeWidth: ".75px",
      }}
    />
    <circle
      cx={204.5}
      cy={362.99}
      r={27.84}
      style={{
        fill: "#92e3a9",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeWidth: ".75px",
      }}
      transform="matrix(.23 -.97 .97 .23 -195.44 479.74)"
    />
    <path
      d="M134.33 262.14s4 9.38 4.84 11.21 1.64 2.33 1.45 2.91-2.23 1.25-2.23 1.25a16.11 16.11 0 0 1-1.45 8.42c-2 4-5.9 6.09-6.28 8.6a46.18 46.18 0 0 0-.1 7.26l-5.9-4.84s1.64-4.74.19-8.22a13.23 13.23 0 0 0-6.19-6.57 16.18 16.18 0 0 1-6.86-9.77c-.87-4.65 1.83-9.58 5.41-13.06s10.64-2.8 11.42-2.51-1.94-2 .48-2.52 3.87 3 4 3.58.87-2.51 1.65-.48-.43 4.74-.43 4.74Z"
      style={{
        fill: "#fff",
      }}
    />
    <g
      style={{
        clipPath: "url(#a)",
      }}
    >
      <path
        d="M127.12 299a16.12 16.12 0 0 0-.23-2c-.39-1.76 2-7.24 2.74-13.69s-4.31-15.46-4.31-15.46l-3.7-10.73a9.55 9.55 0 0 0-4.41 2.26c-3.58 3.48-6.28 8.41-5.41 13.06a16.18 16.18 0 0 0 6.86 9.77 13.23 13.23 0 0 1 6.19 6.57c1.45 3.48-.19 8.22-.19 8.22Z"
        style={{
          fill: "#92e3a9",
          opacity: 0.5,
          mixBlendMode: "multiply",
        }}
      />
    </g>
    <path
      d="M134.33 262.14s4 9.38 4.84 11.21 1.64 2.33 1.45 2.91-2.23 1.25-2.23 1.25a16.11 16.11 0 0 1-1.45 8.42c-2 4-5.9 6.09-6.28 8.6a46.18 46.18 0 0 0-.1 7.26l-5.9-4.84s1.64-4.74.19-8.22a13.23 13.23 0 0 0-6.19-6.57 16.18 16.18 0 0 1-6.86-9.77c-.87-4.65 1.83-9.58 5.41-13.06s10.64-2.8 11.42-2.51-1.94-2 .48-2.52 3.87 3 4 3.58.87-2.51 1.65-.48-.43 4.74-.43 4.74Z"
      style={{
        fill: "none",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeWidth: ".75px",
      }}
    />
    <path
      d="M134.71 267.13s-1.75-.48-2.33 1a2.54 2.54 0 0 0-.09 2.22M136.75 281.87s-1.74.87-2.81-.29M133.34 273.76l-8.06 3.49M121.91 281.49a8.5 8.5 0 0 0-1.44 5.46c.24 3.3.16 7.8-1.53 7.4s.8-5.47.8-7.56a14.34 14.34 0 0 0-1.08-4.63M139.22 271.22l-1.9.82"
      style={{
        fill: "none",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeWidth: ".75px",
      }}
    />
    <path
      d="M136.11 273.1c.25.85.25 1.6 0 1.67s-.65-.56-.9-1.41-.24-1.6 0-1.68.66.56.9 1.42Z"
      style={{
        fill: "#263238",
      }}
    />
    <path
      d="M137.12 273.1c.67 2 .5 3.85-.38 4.15s-2.14-1.09-2.81-3.08-.5-3.86.38-4.16 2.14 1.09 2.81 3.09Z"
      style={{
        fill: "none",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeWidth: ".75px",
      }}
    />
    <path
      d="M123.76 280.85s-1.59-1.92-.16-1.76a2.54 2.54 0 0 1 1.92 1.76"
      style={{
        fill: "none",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeWidth: ".5px",
      }}
    />
    <path
      d="m168.47 313.68-5.61 3.97.87 4.64 6.39-2.51-1.65-6.1z"
      style={{
        fill: "#fff",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeWidth: ".75px",
      }}
    />
    <path
      d="m163.44 316.49 2.52 9.38S149 333.61 145.65 336s-6 1.74-6 1.74l-2.22-10.84s2.41 1.16 7.54-1.06 18.47-9.35 18.47-9.35Z"
      style={{
        fill: "#fff",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeWidth: ".75px",
      }}
    />
    <path
      d="M171.77 340.9a1.43 1.43 0 0 1-1.28-1.36l-2.24-37.11a1.44 1.44 0 1 1 2.87-.13l2.24 37.1a1.44 1.44 0 0 1-1.37 1.51Z"
      style={{
        fill: "#263238",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeWidth: ".75px",
      }}
    />
    <path
      d="M167.5 312.8a1.44 1.44 0 0 1-.17-2.86l5.61-.68a1.44 1.44 0 1 1 .35 2.85l-5.61.68ZM167.79 315.7a1.44 1.44 0 0 1-.17-2.86l5.61-.68a1.45 1.45 0 0 1 1.6 1.26 1.43 1.43 0 0 1-1.25 1.59l-5.61.68Z"
      style={{
        fill: "#fff",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeWidth: ".75px",
      }}
    />
    <path
      d="M168.1 318.34a1.38 1.38 0 0 1-.16-2.75l5.37-.65a1.38 1.38 0 1 1 .33 2.74l-5.37.65Z"
      style={{
        fill: "#fff",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeWidth: ".75px",
      }}
    />
    <path
      d="M169 320.87a1.11 1.11 0 0 1-1-1.12 1.25 1.25 0 0 1 .85-1.42l3.8-.61c.54-.07 1 .42 1.08 1.12a1.23 1.23 0 0 1-.85 1.42l-3.79.6ZM166.94 316.3a1.12 1.12 0 0 1-1.05 1.05 1.25 1.25 0 0 1-1.49-.74l-.88-3.74c-.12-.53.33-1.05 1-1.17a1.26 1.26 0 0 1 1.48.74l.89 3.75s.04.07.05.11Z"
      style={{
        fill: "#fff",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeWidth: ".75px",
      }}
    />
    <path
      d="M122.53 294.05s6.87 4.16 10.06 9.38 5.9 13 7.45 19.83 2.8 24.66 3.77 28.34a23.69 23.69 0 0 0 2.13 5.61 46.66 46.66 0 0 1-17.51 2.9c-9.48-.39-10.93-2.71-10.93-2.71s-3.19-21.08-2.9-37.82 7.93-25.53 7.93-25.53Z"
      style={{
        fill: "#fff",
      }}
    />
    <g
      style={{
        clipPath: "url(#b)",
      }}
    >
      <path
        d="M118.48 313s-1 1.57-.2-2.54a31 31 0 0 1 3.13-8.22l4.69 5.28 2.94.2-.78 1.37 5.67 3.52-2.69-3.17.19-4.84-4.54 1.4s-2.94-5.28-3.72-7c-.52-1.18 1.22-2.18 2.44-2.71a36 36 0 0 0-3.08-2.15s-7.64 8.8-7.93 25.53c-.23 13.27 1.73 29.26 2.55 35.32l15-.83Z"
        style={{
          fill: "#92e3a9",
          opacity: 0.5,
          mixBlendMode: "multiply",
        }}
      />
    </g>
    <path
      d="M122.53 294.05s6.87 4.16 10.06 9.38 5.9 13 7.45 19.83 2.8 24.66 3.77 28.34a23.69 23.69 0 0 0 2.13 5.61 46.66 46.66 0 0 1-17.51 2.9c-9.48-.39-10.93-2.71-10.93-2.71s-3.19-21.08-2.9-37.82 7.93-25.53 7.93-25.53Z"
      style={{
        fill: "none",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeWidth: ".75px",
      }}
    />
    <path
      d="m120.5 297.44 7.16 8.6 3.77-1.45-.19 4.84 5.7 6.87"
      style={{
        fill: "none",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeWidth: ".75px",
      }}
    />
    <path
      d="M116.1 315.67s.8 8 3.19 9.9M116.58 324.3s1 3 3.51 4M137.34 319.98l-4.15 1.6.64 1.76 3.83-1.44"
      style={{
        fill: "none",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeWidth: ".5px",
      }}
    />
    <path
      d="M165.33 350.11a1.44 1.44 0 0 1-1.42-1.22l-5.18-33.64a1.44 1.44 0 0 1 2.85-.43l5.18 33.64a1.45 1.45 0 0 1-1.21 1.64ZM134.33 262.14s1.16-2.71.39-4.74-1.55 1.06-1.65.48-1.54-4.06-4-3.58.29 2.81-.48 2.52-7.84-1-11.42 2.51-6.28 8.41-5.41 13.06a16.18 16.18 0 0 0 6.86 9.77 13.6 13.6 0 0 1 4.62 3.87 19 19 0 0 0 2.83-2.81 3.37 3.37 0 0 1-3-.48c-1.55-1.07-2.32-4.16-.38-5.71s3.81 1.97 3.81 1.97c2 1.07 1.25-1.74.48-3s.29-2.62.58-4.45-2.22-2.52-1.93-4.36 8.7-5.05 8.7-5.05Z"
      style={{
        fill: "#263238",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeWidth: ".75px",
      }}
    />
    <path
      d="M129.67 263.44s-3.51-1.76-5.75 1-1.75 7.35-3.51 10.06a5.75 5.75 0 0 1-4 2.88"
      style={{
        fill: "none",
        stroke: "#fff",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeWidth: ".5px",
      }}
    />
    <path
      d="M114.34 272.55a4.07 4.07 0 0 0 4.47-3.52c.8-4 5.27-9.74 9.75-9.9s4.15 3 4.15 3"
      style={{
        fill: "none",
        stroke: "#fff",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeWidth: ".5px",
      }}
    />
    <path
      d="m153 328.19 2.8-1.84s2.42-4.45 2.61-4.74 6.1-3.19 6.58-2.8a2.28 2.28 0 0 1 .77 1.93c-.19.49-.87 1-.87 1.46s.78 4.15.78 5.12-4 2.61-5.61 3.1-3.77.48-4.26.87l-1.35 1.06Z"
      style={{
        fill: "#fff",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeWidth: ".75px",
      }}
    />
    <path
      d="M120.5 308.66s-2.51.67-1.64 7.73 7.93 30.85 10.44 33.76 27-13.35 27-13.35l-3-9.29s-16.54 6.48-17.89 6.77-5.23-20.79-6.58-23.4"
      style={{
        fill: "#fff",
      }}
    />
    <g
      style={{
        clipPath: "url(#c)",
      }}
    >
      <path
        d="M155.85 335.45c-5.09 1.61-16.87 5.28-18.4 5.12-2-.19-2.54-1.37-3.72-2.93s-2.93 4.69-4.5 5.28-.39-7 .2-8.61-1.37.79-1.37.79-3.91-8-5.87-13.5a78.23 78.23 0 0 1-2.93-11l-.44 1.08a16.53 16.53 0 0 0 0 4.67c.87 7.06 7.93 30.85 10.44 33.76s27-13.35 27-13.35Z"
        style={{
          fill: "#92e3a9",
          opacity: 0.5,
          mixBlendMode: "multiply",
        }}
      />
    </g>
    <path
      d="M120.5 308.66s-2.51.67-1.64 7.73 7.93 30.85 10.44 33.76 27-13.35 27-13.35l-3-9.29s-16.54 6.48-17.89 6.77-5.23-20.79-6.58-23.4"
      style={{
        fill: "none",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeWidth: ".75px",
      }}
    />
    <path
      d="M135.4 334.28a8.67 8.67 0 0 0-3.81 5.67M131.75 336.43a7.09 7.09 0 0 0-2.08 5.12"
      style={{
        fill: "none",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeWidth: ".5px",
      }}
    />
    <path
      d="M106.82 344.29s-2.66-8.87-2.31-27.31 1.42-20.21.53-21.63-17 8.07-29 21.9c-7.64 8.84-17.4 21.93-18.04 42.55-.53 16.49 7.31 29.82 12.23 33.51 8.51 6.38 20.13 5.94 21.37 5.94h118.76s7.62-2.66 9.39-9.57 7.8-26.6-4.78-50.18c-8.26-15.47-23.05-32.09-25.54-30.85s-11.79 19-24.46 30.14c-11.97 10.53-34.75 18.26-58.15 5.5Z"
      style={{
        fill: "#92e3a9",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeWidth: ".75px",
      }}
    />
    <path
      d="M215 339.5c-1.77-3.3-3.83-6.65-6-9.87-6.94 2.39-24.34 9.53-34 23.79-8.29 12.24-2.78 31.66 14.5 45.83h20.87s7.62-2.66 9.39-9.58 7.79-26.59-4.76-50.17Z"
      style={{
        fill: "#fff",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeWidth: ".75px",
      }}
    />
    <path
      d="M218.81 376.81q-.28 2.6-.83 5.33M212.86 342.51s7.19 12.39 6.27 30.46"
      style={{
        fill: "none",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeWidth: ".75px",
      }}
    />
    <path
      d="M104 396.73V379c-9.25-3-15.22-6.56-20.79-9.9-8-4.79-13.6-17.7-9.84-26.06 3.45-7.72 13-11.17 18.08-7.72a175.22 175.22 0 0 0 15.34 9s-2.66-8.87-2.31-27.31 1.42-20.21.53-21.63-17 8.07-29 21.9c-7.61 8.81-17.37 21.9-18.01 42.52-.53 16.49 7.31 29.82 12.23 33.51 8.51 6.38 20.13 5.94 21.37 5.94H104v-2.52Z"
      style={{
        fill: "#fff",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeWidth: ".75px",
      }}
    />
    <path
      d="M61.77 363.27a49.9 49.9 0 0 0 .7 6.51M90.36 308.74c-2.88 2.58-7.18 5.61-9.85 8.7-7.65 8.84-18.1 21.47-18.76 42.09"
      style={{
        fill: "none",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeWidth: ".75px",
      }}
    />
    <path
      d="M131.64 397.12c0 9.75-15 10.64-33.6 10.64s-33.6-.36-33.6-10.64c0-14.2 15-28 33.6-28s33.6 13.8 33.6 28Z"
      style={{
        fill: "#92e3a9",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeWidth: ".75px",
      }}
    />
    <path
      d="M98 369.11c-17.17 0-31.31 11.82-33.34 24.83 2 8.19 16.17 8.5 33.34 8.5s31.27-.76 33.33-8.56c-2.02-12.99-16.15-24.77-33.33-24.77Z"
      style={{
        fill: "#263238",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeWidth: ".75px",
      }}
    />
    <path
      d="M218.51 398.16c0 8.83-13.62 9.64-30.42 9.64s-30.43-.32-30.43-9.64c0-12.85 13.62-25.37 30.43-25.37s30.42 12.52 30.42 25.37Z"
      style={{
        fill: "#92e3a9",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeWidth: ".75px",
      }}
    />
    <path
      d="M188.09 372.79c-15.55 0-28.36 10.71-30.19 22.5 1.83 7.41 14.64 7.69 30.19 7.69s28.31-.69 30.18-7.75c-1.87-11.76-14.66-22.44-30.18-22.44Z"
      style={{
        fill: "#263238",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeWidth: ".75px",
      }}
    />
    <path
      d="M306.54 293a4.79 4.79 0 0 1-2.81-6.16l13.5-36.09a4.79 4.79 0 1 1 9 3.35l-13.5 36.09a4.79 4.79 0 0 1-6.19 2.81Z"
      style={{
        fill: "#fff",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeWidth: ".75px",
      }}
    />
    <path
      d="m322.08 257.25-7.58 19.92"
      style={{
        fill: "none",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeWidth: ".75px",
      }}
    />
    <path
      d="M286 348a8.51 8.51 0 0 1-5-10.95l19.27-51.53a8.51 8.51 0 0 1 15.95 6L296.91 343a8.51 8.51 0 0 1-10.91 5ZM357.53 158.18a51.86 51.86 0 1 0 30.41 66.74 51.87 51.87 0 0 0-30.41-66.74Zm-33.19 88.74a42.88 42.88 0 1 1 55.19-25.14 42.89 42.89 0 0 1-55.19 25.14Z"
      style={{
        fill: "#263238",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeWidth: ".75px",
      }}
    />
    <path
      d="M304.59 191a38.11 38.11 0 0 1 21.48-20M301.16 206.88a38 38 0 0 1 1.74-11.47"
      style={{
        fill: "none",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeWidth: ".75px",
      }}
    />
    <path
      d="M290.2 217.46a50.32 50.32 0 0 1 93.71-34.1M389.33 200.76a50.32 50.32 0 0 1-96 26.39M385.93 187.71a50.21 50.21 0 0 1 2.91 9.87"
      style={{
        fill: "none",
        stroke: "#fff",
        strokeMiterlimit: 10,
      }}
    />
    <circle
      cx={138}
      cy={369.12}
      r={14.43}
      style={{
        fill: "#263238",
      }}
    />
    <circle
      cx={138}
      cy={369.12}
      r={12.18}
      style={{
        fill: "#fff",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeWidth: ".75px",
      }}
    />
    <path
      d="M134.3 372.72a.58.58 0 0 1 0-.81l3.3-3.3a.58.58 0 0 1 .81 0 .57.57 0 0 1 0 .82l-3.29 3.29a.57.57 0 0 1-.82 0Z"
      style={{
        fill: "#263238",
      }}
    />
    <path
      d="M129.28 377.74a1 1 0 0 1 0-1.45l4.7-4.7a1 1 0 0 1 1.46 0 1 1 0 0 1 0 1.45l-4.7 4.7a1 1 0 0 1-1.46 0ZM146.66 369.32a6.27 6.27 0 1 0-8.86 0 6.27 6.27 0 0 0 8.86 0Z"
      style={{
        fill: "#263238",
      }}
    />
    <path
      d="M145.46 368.11a4.56 4.56 0 1 0-6.46 0 4.56 4.56 0 0 0 6.46 0Z"
      style={{
        fill: "#fff",
      }}
    />
    <path
      d="M317 317.66a4.79 4.79 0 0 1-4.79 4.79h-33.77a4.79 4.79 0 1 1 0-9.57h33.77a4.79 4.79 0 0 1 4.79 4.78Z"
      style={{
        fill: "#92e3a9",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeWidth: ".75px",
      }}
    />
    <circle
      cx={296.79}
      cy={318.31}
      r={2.66}
      style={{
        fill: "#fff",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeWidth: ".75px",
      }}
    />
    <circle
      cx={256.36}
      cy={343.05}
      r={6.48}
      style={{
        fill: "#92e3a9",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeWidth: ".75px",
      }}
    />
    <path
      d="M258.79 343.05a2.43 2.43 0 1 0-2.43 2.42 2.43 2.43 0 0 0 2.43-2.42Z"
      style={{
        fill: "#fff",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeWidth: ".75px",
      }}
    />
    <circle
      cx={278.44}
      cy={317.78}
      r={6.48}
      style={{
        fill: "#92e3a9",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeWidth: ".75px",
      }}
    />
    <circle
      cx={278.44}
      cy={317.78}
      r={2.5}
      style={{
        fill: "#fff",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeWidth: ".75px",
      }}
    />
  </svg>
)
export default React.memo(SearchIllustration);
