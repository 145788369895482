import React, { useEffect, useState, useRef } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import MicOffIcon from '@mui/icons-material/MicOff';
import { Box, IconButton, keyframes, TextField, Typography } from '@mui/material';
import PhoneEnabledIcon from '@mui/icons-material/PhoneEnabled';
import AppsIcon from '@mui/icons-material/Apps';
import Cookies from 'js-cookie';
import PhonePausedIcon from '@mui/icons-material/PhonePaused';
import CallEndIcon from '@mui/icons-material/CallEnd';
import { styled } from '@mui/system';

import {
  changeCallState,
  changeClientName,
  changeOnHold,
  changeOnMute,
} from '../../../../../redux/slices/virtualPhone';
import InboundCallView from './InboundCallView';
import InCallTimer from './InCallTimer';
import InCallKeypad from './InCallKeypad';

const PulsatingCircle = styled('div')`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  width: 60px;
  height: 60px;

  &:before {
    content: '';
    position: absolute;
    left: calc(50% - (200% / 2));
    top: calc(50% - (200% / 2));
    width: 200%; // Smaller than previous 300%
    height: 200%; // Smaller than previous 300%
    box-sizing: border-box;
    border-radius: 50%; // Make sure it's a circle
    background-color: #01a4e9;
    animation: pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
  }

  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    width: 100%;
    height: 100%;
    background-color: transparent;
    border-radius: 50%;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
    animation: pulse-dot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite;
  }

  .icon-button {
    animation: pulse-dot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite;
  }

  @keyframes pulse-ring {
    0% {
      transform: scale(0.33);
    }
    80%,
    100% {
      opacity: 0;
    }
  }

  @keyframes pulse-dot {
    0% {
      transform: scale(0.8);
    }
    50% {
      transform: scale(1);
    }
    100% {
      transform: scale(0.8);
    }
  }
`;

const InCallView = () => {
  const { number, callState, session, onHold, onMute, clientName } = useSelector(
    (state) => state.virtualPhone
  );
  const [showKeypad, setShowKeypad] = useState(false);
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState('');
  // const transferUser = () => {
  //   session.refer('sip:0734074170@webrtc.infragroup.ro');
  //   dispatch(changeCallState('formatting'));
  // };

  const endCall = () => {
    session.terminate();
    dispatch(changeCallState('formatting'));
  };

  // useEffect(() => {
  //   console.log(contacts);
  //   contacts.forEach((element) => {
  //     if (element.phoneNumber === number) {
  //       dispatch(changeClientName(element.contactName));
  //     }
  //   });
  // }, [callState, dispatch, number]);

  const holdCall = () => {
    if (!onHold) {
      dispatch(changeOnHold(true));
      if (onMute) {
        muteCall();
      }
      session.hold();
    } else {
      dispatch(changeOnHold(false));
      session.unhold();
    }
  };

  const muteCall = () => {
    if (!onMute && !onHold) {
      dispatch(changeOnMute(true));
      session.mute();
    } else {
      dispatch(changeOnMute(false));
      session.unmute();
    }
  };
  const sendDTMF = (key) => {
    session.sendDTMF(key);
    setInputValue((state) => state + key);
  };

  // useEffect(() => {
  //   let intervalId;

  //   if (callState === 'incall') {
  //     intervalId = setInterval(() => {
  //       if (callSeconds === 59) {
  //         setCallSeconds(0);
  //         setCallMinutes((state) => state + 1);
  //       } else {
  //         setCallSeconds((state) => state + 1);
  //       }
  //     }, 1000);
  //   } else {
  //     clearInterval(intervalId);
  //   }

  //   return () => {
  //     clearInterval(intervalId);
  //   };
  // }, [callState, callSeconds, dispatch]);

  return (
    <Box
      sx={{
        display: 'flex',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <Typography
        sx={{
          color: 'black',
          fontSize: '18px',
          textAlign: 'center',
          visibility: onHold || onMute ? 'visible' : 'hidden',
        }}
      >
        {onHold && '(În Așteptare)'}
        {onMute && '(Microfon Oprit)'}
      </Typography>
      <Box sx={{ mb: 5 }}>
        {clientName && (
          <Typography
            sx={{
              color: 'black',
              fontSize: '20px',
              textAlign: 'center',
              mt: 3,
              fontWeight: 'bold',
            }}
          >
            {clientName || number}
          </Typography>
        )}
        <Typography
          sx={{
            color: 'black',
            fontSize: '20px',
            textAlign: 'center',
          }}
        >
          ({number})
        </Typography>
      </Box>
      {callState === 'inbound' ? (
        <InboundCallView />
      ) : callState === 'calling' ? (
        <>
          <PulsatingCircle>
            <PhoneEnabledIcon
              fontSize="large"
              className="icon-button"
              sx={{
                color: 'black',
              }}
            />
          </PulsatingCircle>

          <IconButton
            size="large"
            sx={{ color: 'black', backgroundColor: 'coral', mt: 10 }}
            onClick={endCall}
          >
            <CallEndIcon fontSize="large" />
          </IconButton>
        </>
      ) : callState === 'incall' ? (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            flexDirection: 'column',
          }}
        >
          {/* <Typography sx={{ color: 'black', fontSize: '20px' }}>
              {callMinutes.toString().padStart(2, '0')}:{callSeconds.toString().padStart(2, '0')}
            </Typography> */}
          {/* <InCallTimer /> */}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <Box>
              <IconButton
                size="large"
                sx={{ color: 'black', backgroundColor: 'coral', mt: 2, mr: 5 }}
                onClick={endCall}
              >
                <CallEndIcon fontSize="large" />
              </IconButton>
              <IconButton
                size="large"
                sx={{
                  color: 'black',
                  backgroundColor: onHold ? 'orange' : 'darkgrey',
                  mt: 2,
                  mr: 5,
                }}
                onClick={holdCall}
              >
                <PhonePausedIcon fontSize="large" />
              </IconButton>
              <IconButton
                size="large"
                sx={{
                  color: 'black',
                  backgroundColor: onMute ? 'orange' : 'darkgrey',
                  mt: 2,
                  mr: 5,
                }}
                onClick={muteCall}
              >
                <MicOffIcon fontSize="large" />
              </IconButton>
              <IconButton
                size="large"
                sx={{ color: 'black', backgroundColor: showKeypad ? 'orange' : 'darkgrey', mt: 2 }}
                onClick={() => setShowKeypad((state) => !state)}
              >
                <AppsIcon fontSize="large" />
              </IconButton>
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                mt: 2,
              }}
            >
              {showKeypad && (
                <>
                  <TextField sx={{ mb: 2, width: '80%' }} disabled value={inputValue} />
                  <InCallKeypad sendDTMF={sendDTMF} />
                </>
              )}
            </Box>
          </Box>
        </Box>
      ) : (
        ''
      )}
    </Box>
  );
};

InCallView.propTypes = {};

export default InCallView;
