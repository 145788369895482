import { Navigate, Outlet } from 'react-router-dom';
import PropTypes from 'prop-types';
import { getAuthenticatedUserFromCookies } from '../utils/cookies';

ProtectedParentRoute.propTypes = {
  roles: PropTypes.array,
  route: PropTypes.any,
};

// Wrapper Component to protect child routes
function ProtectedParentRoute({ roles, route }) {
  const user = getAuthenticatedUserFromCookies();
  const userRoles = user.roles.map((role) => role.replace('ROLE_', '').toLowerCase());
  roles = roles.map((role) => role.toLowerCase());

  const hasPermission =
    roles.some((role) => userRoles.includes(role) || role === 'all') ||
    (Array.isArray(roles) && roles.length === 0);

  if (hasPermission) {
    return route || <Outlet />;
  }
  return <Navigate to="/403" />;
}

export default ProtectedParentRoute;
