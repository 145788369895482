import { useContext } from 'react';
//
import { AuthContext } from './JwtContext';
// import { AuthContext } from './Auth0Context';
import { SocialContext } from './FirebaseContext';
// import { AuthContext } from './AwsCognitoContext';

// ----------------------------------------------------------------------

export const useAuthContext = () => {
  const context = useContext(AuthContext);

  if (!context) throw new Error('useAuthContext context must be use inside AuthProvider');

  return context;
};

export const useSocialContext = () => {
  const socialContext = useContext(SocialContext);

  if (!socialContext) throw new Error('socialContext context must be use inside AuthProvider');

  return socialContext;
}
