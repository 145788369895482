import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box, Link } from '@mui/material';
import Image from '../image';
import Infraweb from '../../assets/logo/infraweb_logo.png';
import Infraweb2 from '../../assets/logo/infraweb_semnatura_100x400.png';
import { useSettingsContext } from '../settings';

// ----------------------------------------------------------------------

const Logo = forwardRef(({ mini, disabledLink = false, sx, ...other }, ref) => {
  const theme = useTheme();
  const { themeLayout } = useSettingsContext();

  const logo = (
    <Box
      ref={ref}
      component="div"
      sx={{
        height: themeLayout === 'vertical' ? 60 : 40,
        display: !mini ? 'inline-flex' : 'flex',
        margin: '0 auto',
        justifyContent: mini && 'center',
        alignItmes: mini && 'center',
        ...sx,
      }}
      {...other}
    >
      <Image
        disabledEffect
        visibleByDefault
        alt="auth"
        src={themeLayout === 'vertical' ? Infraweb2 : Infraweb}
        sx={{ maxWidth: 750 }}
      />
    </Box>
  );

  if (disabledLink) {
    return logo;
  }

  return (
    <Link component={RouterLink} to="/dashboard/tickets/list" sx={{ display: 'contents' }}>
      {logo}
    </Link>
  );
});

Logo.propTypes = {
  sx: PropTypes.object,
  disabledLink: PropTypes.bool,
  mini: PropTypes.bool,
};

export default Logo;
