import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Box, CircularProgress, List, ListItem } from '@mui/material';
import axios from 'axios';

import Cookies from 'js-cookie';
import { getAuthenticatedUserFromCookies } from '../../../../../utils/cookies';
import { BASE_URL } from '../../../../../config-global';
import RecentCall from './RecentCall';

const RecentCallsView = ({ makeCall }) => {
  const [recentCalls, setRecentCalls] = useState([]);

  const getCallHistories = useCallback(async () => {
    const cookies = getAuthenticatedUserFromCookies();
    const token = cookies.accessToken;
    axios
      .get(
        `${BASE_URL}/callcenter/call/history`,

        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((response) => {
        setRecentCalls(response.data);
      })
      .catch((e) => console.log(e));
  }, [setRecentCalls]);

  useEffect(() => {
    getCallHistories();
  }, [getCallHistories]);

  return (
    <Box>
      <List>
        {recentCalls.length > 0 ? (
          recentCalls
            .sort((a, b) => b.id - a.id)
            .map((call) => <RecentCall makeCall={makeCall} data={call} key={call.id} />)
        ) : (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <CircularProgress color="secondary" />
            <p style={{ fontWeight: 'bold' }}>Actualizez apelurile..</p>
          </Box>
        )}
      </List>
    </Box>
  );
};

RecentCallsView.propTypes = { makeCall: PropTypes.func.isRequired };

export default RecentCallsView;
