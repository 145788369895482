import React from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';

const Keypad = ({ handleFormatNumberByKeypad }) => {
  const buttons = [
    ['1', '2', '3'],
    ['4', '5', '6'],
    ['7', '8', '9'],
    ['*', '0', '#'],
  ];

  return (
    <Box
      style={{
        padding: 10,
        background: 'transparent',
        width: '70%',
      }}
    >
      <Grid container spacing={0}>
        {buttons.map((row, rowIndex) => (
          <Grid container key={rowIndex} spacing={0}>
            {row.map((button, index) => (
              <Grid item xs={4} key={index} sx={{ display: 'flex', justifyContent: 'center' }}>
                <Button
                  onClick={() => handleFormatNumberByKeypad(button)}
                  style={{
                    backgroundColor: '#e4e5e7',
                    borderRadius: '100%',
                    height: 70,
                    width: 70,
                    color: 'black',
                    marginBottom: '15px',
                    fontSize: '20px',
                  }}
                >
                  {button}
                </Button>
              </Grid>
            ))}
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};
Keypad.propTypes = {
  handleFormatNumberByKeypad: PropTypes.func.isRequired,
};
export default Keypad;
