import { useState } from 'react';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Alert, IconButton, InputAdornment, Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// routes
// auth
import { useAuthContext } from '../../auth/useAuthContext';
// components
import Iconify from '../../components/iconify';
import FormProvider, { RHFTextField } from '../../components/hook-form';
import { useSnackbar } from '../../components/snackbar';

// ----------------------------------------------------------------------

export default function AuthLoginForm() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { login, resendCode } = useAuthContext();
  const [showPassword, setShowPassword] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Introduceti adresa de email'),
    password: Yup.string().required('Introduceti parola'),
  });

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
  });

  const {
    handleSubmit,
    setError,
    formState: { errors, isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
    try {
      const response = await login(data.email, data.password);
      console.log(response.data);
      if (response.status === 200) {
        enqueueSnackbar('V-ați autentificat cu succes.', {
          anchorOrigin: { vertical: 'top', horizontal: 'center' },
        });
        getTheNavigationRoute(response.data.roles);
      }
    } catch (error) {
      // handleResendCode(error.response.data.email, error.response.data.phoneNumber);
      console.error(error);
      if (error && error?.response?.data?.message === 'Bad credentials') {
        setError('bad_credentials', {
          message: 'Email sau parola incorecta',
        });
      } else {
        enqueueSnackbar('A intervenit o problema la logare. Va rugam incercati mai tarziu!', {
          variant: 'error',
        });
      }
    }
  };

  const getTheNavigationRoute = (roles) => {
    if (roles.includes('ROLE_AGENT_CALL_CENTER') || roles.includes('ROLE_ADMIN')) {
      navigate('/dashboard/tickets/list');
    } else if (roles.includes('ROLE_CARTE_TEHNICA')) {
      navigate('/dashboard/book/new');
    } else if (roles.includes('ROLE_GESTIONAR_DEPOZIT')) {
      navigate('/dashboard/inventory/list');
    } else if (roles.includes('ROLE_MANAGER_FISIERE')) {
      navigate('/dashboard/dashboard/files-manager');
    }
  };

  const handleResendCode = async (email, phoneNumber) => {
    try {
      await resendCode(email, phoneNumber);
      navigate('/auth/verify', {
        state: {
          email: phoneNumber ? 'phone' : 'email',
          emailToSend: email,
          phoneNumber2: phoneNumber,
          value: phoneNumber || email,
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        {/* {!!errors.afterSubmit && (
          <Alert severity="error">
            {errors.afterSubmit.message}
            <Typography>
              <Link sx={{ color: 'red' }}>Click here to verify your account</Link>
            </Typography>
          </Alert>
        )} */}

        {errors.bad_credentials && <Alert severity="error">{errors.bad_credentials.message}</Alert>}

        <RHFTextField name="email" label="Email" autoFocus />

        <RHFTextField
          name="password"
          label="Parola"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack alignItems="flex-end" sx={{ my: 2 }}>
        {/* <Link
          component={RouterLink}
          to={PATH_AUTH.resetPassword}
          variant="body2"
          color="inherit"
          underline="always"
        >
          Ai uitat parola?
        </Link> */}
      </Stack>

      <LoadingButton
        fullWidth
        color="infraweb"
        size="large"
        type="submit"
        variant="contained"
        loading={isSubmitting}
      >
        Accesează platforma
      </LoadingButton>
    </FormProvider>
  );
}
