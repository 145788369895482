import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  callSeconds: 0,
  callMinutes: 0,
};

const slice = createSlice({
  name: 'virtualPhoneCallTimer',
  initialState,
  reducers: {
    setCallMinutes(state, action) {
      state.callMinutes = action.payload;
    },
    setCallSeconds(state, action) {
      state.callSeconds = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// --------------------------------------------------------------------------------

export function changeCallMinutes(minutes) {
  return (dispatch) => {
    dispatch(slice.actions.setCallMinutes(minutes));
  };
}

export function changeCallSeconds(seconds) {
  return (dispatch) => {
    dispatch(slice.actions.setCallSeconds(seconds));
  };
}
