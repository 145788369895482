import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { getAuthenticatedUserFromCookies } from '../../utils/cookies';
import { BASE_URL } from '../../config-global';
// utils

// ----------------------------------------------------------------------
const initialState = {
  isLoading: false,
  error: null,
  newBook: { startDate: {}, finalDate: {}, ac_date: {}, ol_date: {}, atr_date: {} },
  totalSuduri: '',
  suduri: [],
  osd: '',
  address: '',
  editBook: {},
};

const slice = createSlice({
  name: 'book',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // SET THE DATE
    setStartDate(state, action) {
      const { dateName, dateValue } = action.payload;
      state.newBook[dateName] = dateValue;
    },

    // SET TOTAL SUDURI
    setTotalSuduri(state, action) {
      state.totalSuduri = '';
      state.totalSuduri = action.payload;
    },

    // SET SUDURI ARRAY
    setSuduri(state, action) {
      state.suduri = [];
      state.suduri = action.payload;
    },

    // SET THE OSD FOR SHOWING OR HIDE THE INPUTS
    setOsd(state, action) {
      state.osd = action.payload;
    },

    setAddress(state, action) {
      state.address = action.payload;
    },

    // set the book to edit in the state
    setBook(state, action) {
      state.editBook = action.payload;
      state.suduri = action.payload?.suduriData?.suduri;
    },
  },
});

// Reducer
export default slice.reducer;

// --------------------------------------------------------------------------------

export function setReduxDate(values) {
  return (dispatch) => {
    dispatch(slice.actions.setStartDate(values));
  };
}

export function setTotalSuduri(value) {
  return (dispatch) => {
    dispatch(slice.actions.setTotalSuduri(value));
  };
}

export function setSuduri(value) {
  return (dispatch) => {
    dispatch(slice.actions.setSuduri(value));
  };
}

export function setOsdRedux(value) {
  return (dispatch) => {
    dispatch(slice.actions.setOsd(value));
  };
}

export function setAddress(value) {
  return (dispatch) => {
    dispatch(slice.actions.setAddress(value));
  };
}

export function getEditBookDetails(id) {
  return (dispatch) => {
    const cookies = getAuthenticatedUserFromCookies();
    const token = cookies.accessToken;

    const res = axios
      .get(`${BASE_URL}/technic/edit/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        const { technicalBook, client } = response.data;
        const { generalData, suduriData, technicalData, schita } = technicalBook;
        dispatch(slice.actions.setBook({ generalData, suduriData, technicalData, client, schita }));
      })
      .catch((e) => alert(e.response.data.message));

    return res;
  };
}
