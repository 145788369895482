import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  error: null,
  board: {
    emailOrPhone: '',
  },
};

const slice = createSlice({
  name: 'emailOrPhone',
  initialState,
  reducers: {

    // GET quality
    getEmailOrPhone(state, action) {
      const {emailOrPhone} = state;
      return emailOrPhone;
    },

    // SET quality
    setEmailOrPhone(state, action) {
      console.log(state);
      const {emailOrPhone} = action;
      state.emailOrPhone = emailOrPhone;
    },
  },
});

// Reducer
export default slice.reducer;
export const { getEmailOrPhone, setEmailOrPhone} = slice.actions;

// ----------------------------------------------------------------------



// ----------------------------------------------------------------------
