import PropTypes from 'prop-types';
// form
import { FormProvider as RHFProvider } from 'react-hook-form';

// ----------------------------------------------------------------------

FormProvider.propTypes = {
  children: PropTypes.node,
  methods: PropTypes.object,
  onSubmit: PropTypes.func,
  style: PropTypes.object,
};

export default function FormProvider({ children, onSubmit, methods, style }) {
  const { handleSubmit } = methods;
  return (
    <RHFProvider {...methods}>
      <form style={style} onSubmit={handleSubmit(onSubmit)}>
        {children}
      </form>
    </RHFProvider>
  );
}
